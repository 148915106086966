import { makeAutoObservable, runInAction } from "mobx"

interface CommunityData {
    facebook_likes: number | null;
    twitter_followers: number | null;
    reddit_average_posts_48h: number | null;
    reddit_average_comments_48h: number | null;
    reddit_subscribers: number | null;
    reddit_accounts_active_48h: number | null;
    telegram_channel_user_count: number | null
}

interface BitcoinDataValues {
    name: string;
    symbol: string;
    genesisDate: string;
    imageLarge: string;
    currentPrice: string;
    description: string;
    communityData?: CommunityData;
    developerData: any;
    updatedAt: string;
}

class BitcoinData implements BitcoinDataValues {
    private readonly apiBaseUrl = 'https://api.coingecko.com/api/v3/coins/';
    public name = '';
    public symbol = '';
    public genesisDate = '';
    public imageLarge = '';
    public currentPrice = '';
    public description = '';
    public communityData?: CommunityData | undefined;
    public developerData = {};
    public updatedAt = '';
    public links = {};
    private isError = false;

    public setIsError = (value: boolean) => this.isError = value;
    public getIsError = () => this.isError;
    
    constructor() {
        makeAutoObservable(this)
    }

    fetchData(coin: string , currency: string, setLoaded?: (flag: boolean) => void) {
        fetch(`${this.apiBaseUrl}${coin}`)
            .then(response => response.json())
            .then(data => {
                runInAction(() => {
                    if (data?.error) {
                        this.isError = true;
                    } else {
                        this.isError = false;
                    }

                    this.name = data.name;
                    this.symbol = data.symbol;
                    this.genesisDate = data.genesis_date;
                    this.imageLarge = data.image.large;
                    this.currentPrice = data.market_data.current_price[currency];
                    this.description = data.description.en;
                    this.communityData = data.community_data;
                    this.developerData = data.developer_data;
                    this.updatedAt = data.last_updated;
                    this.links = data.links;
                })
            })
            .then(() => {setLoaded && setLoaded(true);})
    }
}

export const bitcoinData = new BitcoinData();