import React, { useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useMediaQuery} from "react-responsive";
import Slider from "./Slider";

const LowestReturn = ({cryptoNames, scrollPosition} : {
    cryptoNames: Array < any >;
    scrollPosition: number;
}) => {
    const {formatMessage} = useIntl();
    const bigScreenQuery = "(orientation: landscape) AND (min-width: 1000px)";
    const middleScreenQuery = "(min-width: 800px) AND (max-width: 999px)";
    const isBigScreen = useMediaQuery({query: bigScreenQuery});
    const isMiddleScreen = useMediaQuery({query: middleScreenQuery});
    const sectionRef = useRef(null);

    const slidesVisible = isBigScreen
        ? 3
        : isMiddleScreen
            ? 2
            : 1;

    const [sliderIndex,
        setSliderIndex] = useState(0);

    const [isVisible,
        setIsVisible] = useState(false);

    useEffect(() => {
        if (sectionRef.current) {
            const formPositionFromTop = (sectionRef.current as HTMLDivElement)
                ?.offsetTop - 150;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isVisible && isInScrollContext)) {
                setIsVisible(true);
            }

        }

    }, [isVisible, sectionRef, scrollPosition]);

    const cryptoSorted = cryptoNames.filter((coin : any) => !coin.id.includes("usd") || coin.id.includes("binance"));
    const cryptoList = useMemo(() => cryptoSorted.sort((a, b) => a.priceChange - b.priceChange).slice(0, 20), [cryptoSorted]);

    return <Slider
        sliderIndex={sliderIndex}
        isVisible={isVisible}
        slidesVisible={slidesVisible}
        setSliderIndex={setSliderIndex}
        cryptoList={cryptoList}
        title={formatMessage({id: 'lowestReturn.title'})}
        label={formatMessage({id: 'lowestReturn.label'})}
        valueName="priceChangeLowest"
        postfix={"%"}
        forwardedRef={sectionRef}/>
};

export default LowestReturn;