import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CompanyName from '../components/companyName/CompanyName';
import { HeaderContainer, HeaderTitle } from '../components/Header';
import { AboutUsContainer } from './AboutUs';
import { Motto, SectionContainer, SectionTitle } from './CryptoWalletCorr';
import { Link } from 'react-router-dom';
import { Links } from '../assets/links';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

const PrivacyPolicy = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isSitemapVisible,
        setIsSitemapVisible] = useState(false);
    
    const sitemapRef = useRef(null);

    useEffect(() => {
        if (sitemapRef.current) {
            const formPositionFromTop = (sitemapRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isSitemapVisible && isInScrollContext)) {
                setIsSitemapVisible(true);
            }

        }
    }, [isSitemapVisible, sitemapRef, scrollPosition]);

    return <>
    <Helmet>
            <title>Privacy Policy - Cryptocurrency and user protection | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/privacy-policy/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Learn how we collect, use, and protect your personal information on Coin Data Shake. Our Privacy Policy outlines our commitment to transparency and security in handling user data. Find out more today."/>
            <meta property="og:title" content="Privacy Policy - Cryptocurrency and user protection | Coin Data Shake" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://coindatashake.com/privacy-policy/" />
            <meta property="og:image" content="https://coindatashake.com/images/home/coin-data-shake-home.webp" />
            <meta property="og:description" content="Learn how we collect, use, and protect your personal information on Coin Data Shake. Our Privacy Policy outlines our commitment to transparency and security in handling user data. Find out more today." />
        </Helmet>
    <AboutUsContainer  className='h-card' itemScope itemType="http://schema.org/Article">
         <HeaderContainer>
            <HeaderTitle className='p-name' itemProp="headline"><CompanyName />Privacy Policy</HeaderTitle>
            <Motto><strong>Your security, our priority - protecting your privacy with cryptocurrency.</strong></Motto>
        </HeaderContainer>
        <SectionContainer itemProp="articleBody" className="e-content"  ref={sitemapRef} isVisible={isSitemapVisible}>
        <SectionTitle>Privacy Policy</SectionTitle>
        <p>
        We at <Link to={Links.HOME}><mark itemProp="name">Coin Data Shake</mark></Link> take your privacy seriously and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your information when you use our website.
        </p>
        <SubTitle>Information We Collect</SubTitle>
        <p>
            When you use our website, we may collect certain information about you, including:
        </p>
        <ul style={{listStyle: 'initial'}}>
            <li>Your IP address</li>
            <li>The type of browser you are using</li>
            <li>The pages you visit on our website</li>
            <li>The date and time of your visit</li>
            <li>The website you visited before coming to our website</li>
        </ul>
        <p>We collect this information using Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help us analyze how users use our website. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf.</p>
        <p>We also use Google AdSense to display advertisements on our website. Google AdSense uses cookies to serve ads based on a user's prior visits to our website or other websites. Google's use of advertising cookies enables it and its partners to serve ads to our users based on their visit to our website and/or other sites on the Internet.</p>   
        <SubTitle>How We Use Your Information</SubTitle>
        <p>We use the information we collect to:</p>
        <ul style={{listStyle: 'initial'}}>
            <li>Improve our website and its content</li>
            <li>Analyze trends and gather demographic information</li>
            <li>Customize the content and/or layout of our page for each individual visitor</li>
            <li>Display advertisements based on your interests and behavior on our website and other websites</li>  
        </ul>
        <p>We do not sell, rent, or share your personal information with third parties except as described in this Privacy Policy.</p>
        <SubTitle>Your Choices</SubTitle>
        <p>You can choose to disable or selectively turn off our cookies or third-party cookies in your browser settings. However, this may affect how you are able to interact with our website as well as other websites. You may also opt out of personalized advertising by visiting the Google Ad Settings page or by using the Network Advertising Initiative opt-out page.</p>
        <SubTitle>Data Security</SubTitle>
        <p>We take appropriate measures to ensure that your personal information is secure, including the use of encryption technologies and secure servers. However, no transmission of data over the internet is guaranteed to be completely secure, and we cannot guarantee the security of any information you transmit to us.</p>
        <SubTitle>Changes to this Privacy Policy</SubTitle>
        <p>We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this Privacy Policy, we will notify you by email (if you have provided us with your email address) or by posting a notice on our website.</p>
        <SubTitle>Changes to this Privacy Policy</SubTitle>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@coindatashake.com">contact@coindatashake.com</a>.</p>
        </SectionContainer>
        
    </AboutUsContainer></>;
};

const SubTitle = styled.h3`
    font-size: 1.4rem !important;
`;

export default PrivacyPolicy;