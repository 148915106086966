import MaterialReactTable, {MRT_ColumnDef} from 'material-react-table';
import {observer} from "mobx-react";
import React, {useMemo, useRef, useEffect, useState} from "react";
import {forecastData} from "../provider/forecastData";
import {DataContainer, DataTitle} from "./HistoricalData";
import {FormattedMessage} from "react-intl";
import styled from 'styled-components';
import {colors} from '../assets/colors';
import {Links} from '../assets/links';
import { ApiLink } from './Footer';
import { useMediaQuery } from 'react-responsive';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    scales
  } from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    scales
  );

ChartJS.defaults.color = colors.white;
ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Ubuntu, sans-serif';

interface ForecastData {
    date : string | JSX.Element;
    price : number;
}

const addDays = (index : number) => {
    let d = new Date();
    d.setDate(d.getDate() + index);
    return <time dateTime={d.toISOString()}>{d.toLocaleDateString()}</time> ;
}

const WeekForecast = observer(({currentCurrency, currentCoin}: {currentCurrency: string; currentCoin: string}) => {

    const data : Array < ForecastData > = (forecastData.weekForecastArima
        ?.slice() || []).map((price, index) => ({
        price: (price as any),
        date: addDays(index + 1)
    }));

    const columns = useMemo < MRT_ColumnDef < ForecastData > [] > (() => [
        {
            header: 'Date',
            accessorKey: 'date'
        }, {
            header: `Forecast Price (${currentCurrency
                ?.toUpperCase()})`,
            accessorKey: 'price'
        }
    ], [currentCurrency]);

    const tableRef = useRef(null);


    useEffect(() => {
        if (tableRef.current) {
            Object.values((tableRef.current as any).refs.tableHeadCellRefs.current as HTMLTableElement).forEach(el => el.querySelector("div[title]")?.removeAttribute("title"));
        }
    }, [tableRef]);
    const portraitQuery = "(orientation: portrait) and (max-width: 960px)";
    const isPortrait = useMediaQuery({query: portraitQuery});

    const labels = useMemo(() => data.map(item => (item.date as any).props.children), [data]);

    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            position:  isPortrait ? 'bottom' : 'top' as const,
          },
          title: {
            display: false,
            text: 'Week forecast',
          },
        }
      }), [isPortrait]);

    const dataChart = useMemo(() => (
        {
            labels,
            datasets: [
              {
                label: `${(currentCoin as any)?.[0]?.toUpperCase() + currentCoin?.substring(1)} forecast in ${currentCurrency?.toUpperCase()}`,
                data: data.map(item => item.price),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              }
            ],
          }
    ), [data, currentCoin, currentCurrency, labels]);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 1050;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }
        }
    }, [isPostsVisible, postsRef, scrollPosition]);

    return <DataContainer id="forecast" ref={postsRef} isVisible={isPostsVisible}>
        <DataTitle>
            Cryptocurrency week forecast
        </DataTitle>
        <p style={{marginBottom: "2rem", color: colors.error}}><span style={{marginBottom: "2rem", color: colors.error}}>* Please read the below forecast description to know what this tool is and how to interpret it correctly.</span></p>   
        <ChartContainer>
            <Line options={options as any} data={dataChart} {...(isPortrait  ? {height: 300} : {})}/>
        </ChartContainer>
        <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{
            hover: false
        }}
        tableInstanceRef={tableRef}/>
        <DataDescription>
           <p>
           Cryptocurrencies have gained significant popularity in recent years, and with that popularity comes a growing interest in predicting the future prices of these digital assets. One popular approach to cryptocurrency forecasting is the use of the Auto ARIMA model, a statistical model that can automatically identify the optimal parameters for time series forecasting. In this article, we will explore the potential of the Auto ARIMA model for cryptocurrency forecasting, while discussing key market trends and developments in the cryptocurrency space. The increasing adoption of blockchain technology and the growing demand for digital assets have made cryptocurrencies a significant part of the global financial landscape. As the market continues to evolve, many investors are turning to cryptocurrency forecasts to predict the future value of these digital assets. 
           </p>
           <p>
           Before delving into the technical details of the Auto ARIMA model, it's important to understand some key market trends and developments that can impact cryptocurrency prices. One such trend is the growing institutional adoption of cryptocurrencies, with large financial institutions and corporations beginning to invest in Bitcoin and other digital assets. This adoption has helped to drive up demand for cryptocurrencies and increase their overall market capitalization.
           </p>
           <p>
           Another trend to consider is the regulatory environment for cryptocurrencies, which can have a significant impact on their prices. In recent years, several countries have enacted laws and regulations around cryptocurrencies, which can impact investor sentiment and overall market conditions. For example, China has recently cracked down on cryptocurrency mining and trading, which has had a negative impact on the prices of many cryptocurrencies.
           </p>
           <p>
           Now that we have a sense of some key market trends, let's explore how the Auto ARIMA model can be used for cryptocurrency forecasting. Auto ARIMA is a statistical model that can automatically identify the optimal parameters for time series forecasting, making it a powerful tool. The model selects the best parameters for the ARIMA model automatically, making it easier for analysts to create accurate predictions without having to manually select the model parameters. ARIMA stands for Autoregressive Integrated Moving Average, which is a statistical model that is widely used for time-series analysis and forecasting. The model involves analyzing the pattern of past data to make future predictions. The algorithm used in Auto ARIMA takes into account various factors such as trend, seasonality, and the level of integration, to identify the best fit for the model.
            </p>
            <p>
            Once the data has been collected, the auto arima model is used to analyze the patterns and trends in the data and generate a forecast for the future value of the digital asset. The model uses a range of statistical techniques, including time-series analysis, regression analysis, and machine learning algorithms to generate accurate predictions. To forecast the future of cryptocurrency, we can gather data on various cryptocurrencies such as Bitcoin, Ethereum, and others. We can also gather data on the overall cryptocurrency market, including factors such as market capitalization, trading volume, and market sentiment. We can then feed this data into the Auto ARIMA model to make predictions about the future of cryptocurrency. 
            </p> 
            <p>
            <strong>
            * However, it's important to remember that this model can only predict trends, not precise values. There are no guarantee that future values will be the same as predicted values. It only can show current trends and probable future trends and their powers, it is also possible that future trend will be different from predicted. Below we explain why.</strong>
            </p>
            <p>
            The cryptocurrency market is highly unpredictable, with many factors influencing prices, including news, regulations, and investor sentiment. Therefore, it's crucial to use other analytical methods and not rely solely on the Auto ARIMA model to make investment decisions. Pleas do't relay on specific number values presented on forecast, just consider them in terms of future market trends.  Furthermore, analysts should also consider the cryptocurrency market's overall trends and conditions when making investment decisions.
            </p>
            <p>
            The market can be affected by a variety of factors, including macroeconomic conditions, industry trends, and regulatory changes. By keeping an eye on these trends and conducting thorough analyses using different methods, analysts can gain a better understanding of the cryptocurrency market and make informed investment decisions. In conclusion, while the Auto ARIMA model is a useful tool for predicting trends in the cryptocurrency market, it's essential to use other analytical methods and keep an eye on market trends to make informed investment decisions, check out our moving average tool that can present you buy or sell potential signals, see in menu investing tools section.
            </p>
            <ApiLink href={Links.AUTO_ARIMA_DESC} rel="noreferrer" target="_blank">
                <FormattedMessage id='forecastData.link'/>
            </ApiLink>
        </DataDescription>
    </DataContainer>;
});

export const ChartContainer = styled.div`
    margin-bottom: 4rem;

`;

const DataDescription = styled.section `
    margin-top: 2rem;
    font-size: 1.3rem;

    & a {
        margin-top: 0.5rem;
        display: block;
        text-decoration: none !important;
        color: ${colors.textPrimary} !important;
        text-align: right;
        font-size: 1.1rem;
    }

    & p:nth-child(2) {
        margin-top: 1rem;
    }
`;

export default WeekForecast;