import React, {useEffect, useRef, useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../assets/colors';
import { Links } from '../assets/links';
import { blogDescription } from '../blog/blogDescription';
import { btcVsEth } from '../blog/posts/btcVsEth';
import { cryptoTaxes } from '../blog/posts/cryptoTaxes';
import { decentralizedFin } from '../blog/posts/decentralizedFin';
import { riseOfNft } from '../blog/posts/riesOfNft';
import { stakingCrypto } from '../blog/posts/stakingCrypto';
import { top5Altcoins } from '../blog/posts/top5Altcoins';
import CompanyName from '../components/companyName/CompanyName';
import { HeaderContainer, HeaderTitle } from '../components/Header';
import { Img, ImgContainer } from './BlogPost';
import { Motto, SectionContainer, SectionTitle } from './CryptoWalletCorr';  
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';


const Blog = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const posts = [btcVsEth, decentralizedFin, top5Altcoins, riseOfNft, stakingCrypto, cryptoTaxes];
    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }

        }
    }, [isPostsVisible, postsRef, scrollPosition]);

    const [isToolsVisible,
        setIsToolsVisible] = useState(false);
    
    const toolsRef = useRef(null);

    useEffect(() => {
        if (toolsRef.current) {
            const formPositionFromTop = (toolsRef.current as HTMLDivElement)
                ?.offsetTop - 275;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isToolsVisible && isInScrollContext)) {
                setIsToolsVisible(true);
            }

        }
    }, [isToolsVisible, toolsRef, scrollPosition]);

    const [isDescriptionVisible,
        setIsDescriptionVisible] = useState(false);
    
    const descriptionRef = useRef(null);

    useEffect(() => {
        if (descriptionRef.current) {
            const formPositionFromTop = (descriptionRef.current as HTMLDivElement)
                ?.offsetTop - 275;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isDescriptionVisible && isInScrollContext)) {
                setIsDescriptionVisible(true);
            }

        }
    }, [isDescriptionVisible, descriptionRef, scrollPosition]);

    return <>
         <Helmet>
            <title>Cryptocurrency Blog - News, Trends, and Analysis | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/blog/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content='Keep up-to-date with the latest news, trends, and analysis in the world of cryptocurrency. Our blog covers all aspects of cryptocurrencies, including Bitcoin, Ethereum, altcoins, DeFi, NFTs, and more. Follow us for daily updates and analysis on cryptocurrency.'/>
            <meta property="og:title" content="Cryptocurrency Blog - News, Trends, and Analysis | Coin Data Shake" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://coindatashake.com/blog/" />
            <meta property="og:image" content="https://coindatashake.com/images/blog/cryptocurrency-blog.webp" />
            <meta property="og:description" content='Keep up-to-date with the latest news, trends, and analysis in the world of cryptocurrency. Our blog covers all aspects of cryptocurrencies, including Bitcoin, Ethereum, altcoins, DeFi, NFTs, and more. Follow us for daily updates and analysis on cryptocurrency.' />
        </Helmet>
        <BlogContainer className="h-feed">
    <HeaderContainer>
        <HeaderTitle className="p-name"><CompanyName />Cryptocurrency Blog</HeaderTitle>
        <Motto><strong>Explore the ever-evolving world of cryptocurrency with us, one blog post at a time</strong></Motto>
    </HeaderContainer>
    <SectionContainer id="posts" ref={postsRef} isVisible={isPostsVisible}>
        <SectionTitle>Recent Posts</SectionTitle>
        <Container>
            {posts.map(post => 
                    <Post key={post.title} className="h-entry" itemScope itemType='https://schema.org/Article'>
                        <Link to={Links.BLOG + "/" + post.link} style={{display: "block"}} className="u-url"  itemProp="url">
                            <PostImageContainer>
                                <PostImage src={post.mainImage} alt={post.alt} loading='lazy' className="u-photo" itemProp="image" />
                            </PostImageContainer>
                            <PostTitle  className="p-name" itemProp="headline">{post.title}</PostTitle>
                    </Link>
                </Post>
            )}
        </Container>
    </SectionContainer>
    <SectionContainer id="toolsLinks" ref={toolsRef} isVisible={isToolsVisible}>
        <SectionTitle>Check our cryptocurrency unique tools</SectionTitle>
        <Container>
        <Post className="h-entry maxWidth" itemScope itemType='https://schema.org/Article'>
            <Link to={Links.CRYPTO_WALLET}  className="u-url" itemProp="url">
                <PostTitle className="p-name" itemProp="headline">Cryptocurrency wallet correlation analysis</PostTitle>
                <ImgContainer>
                    <Img src="/images/wallet-correlation/crypto-wallet-correlation.webp" alt="On the image you can see a our form to generate cryptocurrency wallet" className="u-photo" itemProp="image"/>
                </ImgContainer>
                <p className="e-content" itemProp="articleBody">
                    <FormattedMessage id="correlationTool.description" />
                </p>
            </Link>
        </Post>
        <Post className="h-entry maxWidth" itemScope itemType='https://schema.org/Article'>
            <Link to={Links.MOVING_AVERAGE} className="u-url" itemProp="url">
                <PostTitle className="p-name" itemProp="headline">Moving average cryptocurrency analysis</PostTitle>
                <ImgContainer>
                    <Img src="/images/ma/coin-moving-average.webp" alt="On the image you can see a chart with results of moving average calculation" className="u-photo" itemProp="image"/>
                </ImgContainer>
                <p  className="e-content" itemProp="articleBody">
                    <FormattedMessage id="movingAverage.description" />
                </p>
            </Link>
        </Post>
        </Container>
        <Paragraph>
        Cryptocurrency tools are software applications and online resources designed to help cryptocurrency traders, investors, and enthusiasts to analyze, track, and manage their crypto assets. These tools are increasingly important as the cryptocurrency market becomes more complex and diversified. Some common types of cryptocurrency tools include portfolio trackers, price alert systems, news aggregators, technical analysis platforms, and tax calculators. Portfolio trackers, for example, allow users to monitor the value of their cryptocurrency holdings in real-time and track their investment performance over time. Price alert systems notify users when the price of a specific cryptocurrency reaches a certain threshold, allowing them to capitalize on market fluctuations. News aggregators provide up-to-date information about the latest trends and developments in the cryptocurrency market, while technical analysis platforms enable traders to analyze market data and identify trends and patterns. Ultimately, the use of cryptocurrency tools can help traders and investors make more informed decisions and manage their investments more effectively in the fast-paced and constantly evolving world of cryptocurrency.
        </Paragraph> 
    </SectionContainer>
    <SectionContainer id="blogDescription" className="h-entry" itemScope itemType='https://schema.org/Article' ref={descriptionRef} isVisible={isDescriptionVisible}>
        <SectionTitle className="p-name" itemProp="headline">Blog description</SectionTitle>
        <SectionContent className="e-content" itemProp="articleBody">
            {blogDescription.map((element) => element)}
        </SectionContent>
    </SectionContainer>
    </BlogContainer>   
    </>  
};

export const BlogContainer = styled.div`
    &>header {
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

const Paragraph = styled.p`
color: ${colors.white};
font-size: 1.4rem;
line-height: 140%;
`;

const SectionContent = styled.div`
    & p {
        font-size: 1.4rem;
        margin-bottom: 2rem;
        text-align: left;
        line-height: 140%;
    }
    & h3 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

export const PostImageContainer = styled.div`
    height: 200px;
    overflow: hidden;
`;

const PostImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
`;

export const Post = styled.article`
    width: 280px;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 4px solid ${colors.white};
    transition: .4s;

    & .p-name {
        color: ${colors.white}!important;
    }

    &.maxWidth {
        flex-basis: 100%!important;
        &>a {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            padding: 1rem;
        }
        & h3 {
            color: ${colors.white};
            text-align: center;
            margin-bottom: 2rem;
        }
        & p {
            color: ${colors.white};
            font-size: 1.4rem;
            line-height: 140%;
        }
    }

    & img {
        transition: 1s;
    }

    @media (min-width: 960px) {
        &:hover {
            cursor: pointer;
            border-color: ${colors.textPrimary};
                & img {
                    transform: scale(1.1);
                }
          
            & h3 {
                color: ${colors.textPrimary}!important;
            }
        }
    }
    
`;

export const PostTitle = styled.h3`
    text-align: center;
    margin-top: 1rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
`;

export default Blog;