import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HeaderContainer, HeaderTitle } from '../components/Header';
import { Motto, SectionContainer, SectionTitle } from './CryptoWalletCorr';
import { AboutUsContainer } from './AboutUs';
import CompanyName from '../components/companyName/CompanyName';
import { Links } from '../assets/links';
import { btcVsEth } from '../blog/posts/btcVsEth';
import { cryptoTaxes } from '../blog/posts/cryptoTaxes';
import { decentralizedFin } from '../blog/posts/decentralizedFin';
import { riseOfNft } from '../blog/posts/riesOfNft';
import { stakingCrypto } from '../blog/posts/stakingCrypto';
import { top5Altcoins } from '../blog/posts/top5Altcoins';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

const Sitemap = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    const posts = useMemo(() => [
        btcVsEth,
        cryptoTaxes,
        decentralizedFin,
        riseOfNft,
        stakingCrypto,
        top5Altcoins
    ], []);


    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, []);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isSitemapVisible,
        setIsSitemapVisible] = useState(false);
    
    const sitemapRef = useRef(null);

    useEffect(() => {
        if (sitemapRef.current) {
            const formPositionFromTop = (sitemapRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isSitemapVisible && isInScrollContext)) {
                setIsSitemapVisible(true);
            }

        }
    }, [isSitemapVisible, sitemapRef, scrollPosition]);

    return <>
    <Helmet>
            <title>Cryptocurrency Sitemap - Explore Our List of Pages | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/coin-sitemap/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Looking for a quick and easy way to find the pages you need on our cryptocurrency website? Check out our HTML sitemap and explore our complete list of pages on Bitcoin, Ethereum, altcoins, mining, and more."/>
            <meta property="og:title" content="Cryptocurrency Sitemap - Explore Our List of Pages | Coin Data Shake" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://coindatashake.com/coin-sitemap/" />
            <meta property="og:image" content="https://coindatashake.com/images/home/coin-data-shake-home.webp" />
            <meta property="og:description" content="Looking for a quick and easy way to find the pages you need on our cryptocurrency website? Check out our HTML sitemap and explore our complete list of pages on Bitcoin, Ethereum, altcoins, mining, and more." />
        </Helmet>
        <AboutUsContainer>
           <HeaderContainer>
            <HeaderTitle><CompanyName />Cryptocurrency Sitemap</HeaderTitle>
            <Motto><strong>Navigate your way through our crypto website with ease</strong></Motto>
        </HeaderContainer>
        <SectionContainer ref={sitemapRef} isVisible={isSitemapVisible}>
        <SectionTitle>Links to our crypto tools and data</SectionTitle>
        <LinksList style={{marginTop: '-1rem'}}>
            <li><a href={Links.HOME}>Home</a></li>
            <li><a href={Links.CRYPTO_WALLET}>Crypto wallet correlation</a></li>
            <li><a href={Links.MOVING_AVERAGE}>Moving average calculator</a></li>
            <li><a href={Links.ABOUT_US}>About Us</a></li>
            <li><a href={Links.PRIVACY_POLICY}>Privacy Policy</a></li>
            <li><a href={Links.COIN_INFO}>Coin market info</a></li>
            <li><a href={Links.BLOG}>Blog</a>
                <ul>
                    {posts.map(post => <li key={post.title}><a href={`${Links.BLOG}/${post.link}` }>{post.title}</a></li>)}
                </ul>
            </li>
    </LinksList>
        </SectionContainer>
    </AboutUsContainer>
    </> 
};

const LinksList = styled.ul`
   @media (max-width: 960px) {
    margin-left: 2rem!important;
        & li {
            margin-bottom: 1rem;
            line-height: 130%!important;
            @media (orientation: landscape) {
                text-align: center;
            }
        }
        & li ul {
            margin-top: 1rem;
            margin-left: 2rem;
            @media (orientation: landscape) {
                margin-left: 0;
            }
        }
   }
`;

export default Sitemap;