import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { HeaderContainer, HeaderTitle } from '../components/Header';
import { Motto, SectionContainer, SectionTitle } from './CryptoWalletCorr';
import '@brainhubeu/react-carousel/lib/style.css';
import { Link } from 'react-router-dom';
import { Links } from '../assets/links';
import { colors } from '../assets/colors';
import AnimatedNumbers from "react-animated-numbers";
import CompanyName from '../components/companyName/CompanyName';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

const AboutUs = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, []);

    const animationVisibleQuery =  "(min-width: 960px)";
    const isAnimationVisible = useMediaQuery({query: animationVisibleQuery});

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isSitemapVisible,
        setIsSitemapVisible] = useState(false);
    
    const sitemapRef = useRef(null);

    useEffect(() => {
        if (sitemapRef.current) {
            const formPositionFromTop = (sitemapRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isSitemapVisible && isInScrollContext)) {
                setIsSitemapVisible(true);
            }

        }
    }, [isSitemapVisible, sitemapRef, scrollPosition]);

    return <>
         <Helmet>
            <title>About us - tools and blog for cryptocurrency fans | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/about-us/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Discover our team's passion for cryptocurrency and explore our collection of informative blog articles and useful tools designed to help you navigate the world of digital assets. Learn more about who we are and how we can support your journey into the exciting world of cryptocurrency."/>
            <meta property="og:title" content="About us - tools and blog for cryptocurrency fans | Coin Data Shake" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://coindatashake.com/about-us/" />
            <meta property="og:image" content="https://coindatashake.com/images/about-us/coin-data-shake-about-us.webp" />
            <meta property="og:description" content="Discover our team's passion for cryptocurrency and explore our collection of informative blog articles and useful tools designed to help you navigate the world of digital assets. Learn more about who we are and how we can support your journey into the exciting world of cryptocurrency." />
        </Helmet>
        <AboutUsContainer className="h-card" itemScope itemType="http://schema.org/Organization">
        <HeaderContainer>
            <HeaderTitle  className="p-name"><CompanyName />About Us</HeaderTitle>
            <Motto><strong>Everything you need for your cryptocurrency</strong></Motto>
        </HeaderContainer>
        <div className="p-description" itemProp="description">
        <SectionContainer  ref={sitemapRef} isVisible={isSitemapVisible}>
        <SectionTitle id="company">Our company</SectionTitle>
        <p>
        At <Link to={Links.HOME}><mark itemProp="name">Coin Data Shake</mark></Link>, we're a team of dedicated cryptocurrency fans who are passionate about helping others navigate the exciting world of digital assets. Our mission is to provide the tools and information you need to make informed decisions about your investments and stay up-to-date with the latest developments in the crypto space.
        </p>
      <p>
      Whether you're a seasoned investor or just getting started, our blog articles cover a wide range of topics to help you deepen your understanding of blockchain technology, trading strategies, market analysis, and more. We believe that knowledge is power, and we're committed to empowering our readers with the insights they need to succeed in the fast-moving world of cryptocurrency.
      </p>
      <p>
      In addition to our blog, we also offer a suite of handy tools and resources to help you stay on top of your investments. From real-time price tracking to portfolio management tools, we've got everything you need to stay informed and make smart decisions. At <Link to={Links.HOME}><mark itemProp="name">Coin Data Shake</mark></Link>, we're more than just a website – we're a community of like-minded individuals who share a passion for cryptocurrency. Join us on our journey as we explore the cutting-edge technology and game-changing innovations that are shaping the future of finance.
      </p>
      <AnimatedNumbersContainer>
      <AnimatedNumbersMark>
        <strong>Unique tools</strong>
        {isAnimationVisible ? <AnimatedNumbers
            includeComma
            animateToNumber={2}
            fontStyle={{ fontSize: 40 }}
            locale="en-US"
            configs={[
            { mass: 1, tension: 220, friction: 100 },
            { mass: 1, tension: 180, friction: 130 },
            { mass: 1, tension: 280, friction: 90 },
            { mass: 1, tension: 180, friction: 135 },
            { mass: 1, tension: 260, friction: 100 },
            { mass: 1, tension: 210, friction: 180 },
            ]}
        /> : <strong>2</strong>}
        
      </AnimatedNumbersMark>
      <AnimatedNumbersMark>
        <strong className="big">Crypto forecasts</strong>
        {isAnimationVisible ? <AnimatedNumbers
            includeComma
            animateToNumber={100}
            fontStyle={{ fontSize: 40 }}
            locale="en-US"
            configs={[
            { mass: 1, tension: 220, friction: 100 },
            { mass: 1, tension: 180, friction: 130 },
            { mass: 1, tension: 280, friction: 90 },
            { mass: 1, tension: 180, friction: 135 },
            { mass: 1, tension: 260, friction: 100 },
            { mass: 1, tension: 210, friction: 180 },
            ]}
        /> : <strong>100</strong>}
      </AnimatedNumbersMark>
      <AnimatedNumbersMark>
        <strong>Blog posts</strong>
        {isAnimationVisible ? <AnimatedNumbers
            includeComma
            animateToNumber={6}
            fontStyle={{ fontSize: 40 }}
            locale="en-US"
            configs={[
            { mass: 1, tension: 220, friction: 100 },
            { mass: 1, tension: 180, friction: 130 },
            { mass: 1, tension: 280, friction: 90 },
            { mass: 1, tension: 180, friction: 135 },
            { mass: 1, tension: 260, friction: 100 },
            { mass: 1, tension: 210, friction: 180 },
            ]}
        /> : <strong>6</strong>}
      </AnimatedNumbersMark>
      </AnimatedNumbersContainer>
        <SectionTitle id="goals">Goals and values</SectionTitle>
        <p>
        Our goal at  <Link to={Links.HOME}><mark itemProp="name">Coin Data Shake</mark></Link> is to provide reliable, accessible, and easy-to-understand information about cryptocurrency to anyone who wants to learn. We believe that education is the key to unlocking the full potential of digital assets, and we strive to make our tools and resources available to as many people as possible.
        </p>
        <p>
        We are committed to operating with transparency, honesty, and integrity in everything we do. We believe that the crypto space should be a safe and welcoming place for everyone, regardless of their background or level of experience. That's why we're dedicated to providing accurate information, and we never compromise on the quality of our content.
        </p>
        <p>
        At <Link to={Links.HOME}><mark>Coin Data Shake</mark></Link>, we value innovation and creativity, and we're constantly exploring new ideas and technologies to help our readers stay ahead of the curve. We believe in the power of collaboration and are always looking for ways to work with other players in the crypto space to deliver the best possible experience to our users. Ultimately, our goal is to empower our readers with the knowledge and tools they need to succeed in the fast-paced and ever-changing world of cryptocurrency. We're passionate about what we do, and we're committed to making a positive impact on the crypto community.
        </p>
        <AnimatedNumbersContainer>
      <AnimatedNumbersMark>
        <strong className='text'>reliability</strong>
      </AnimatedNumbersMark>
      <AnimatedNumbersMark>
        <strong className='text'>creativity</strong>
      </AnimatedNumbersMark>
      <AnimatedNumbersMark>
        <strong className='text'>knowledge</strong>
      </AnimatedNumbersMark>
      </AnimatedNumbersContainer>
        <SectionTitle id="contentCreation">How we create our cryptocurrency content</SectionTitle>
        <p>
        At <Link to={Links.HOME}><mark>Coin Data Shake</mark></Link>, we're committed to providing our readers with accurate, informative, and engaging content about cryptocurrency. To ensure that our articles and tools meet the highest standards, we use a combination of human expertise and cutting-edge artificial intelligence (AI) technology. Our team of experienced writers and analysts works tirelessly to research and produce content that is both informative and accessible. We cover a wide range of topics, from the basics of blockchain technology to more advanced trading strategies and market analysis.
        </p>
        <p>
            In addition to our human-generated content, we also use AI tools to help us stay on top of the latest developments in the crypto space. Our AI algorithms analyze vast amounts of data from a variety of sources to identify emerging trends, news stories, and market movements. This allows us to stay ahead of the curve and provide our readers with the most up-to-date information. We believe that the combination of human expertise and AI technology is the key to creating high-quality cryptocurrency content that is both accurate and engaging. Our commitment to using the latest technology helps us stay ahead of the competition and provide our readers with the best possible experience.
        </p>
        <AnimatedNumbersContainer>
      <AnimatedNumbersMark>
        <strong className='text'>Analysis</strong>
      </AnimatedNumbersMark>
      <AnimatedNumbersMark>
        <strong className='text'>Forecasts</strong>
      </AnimatedNumbersMark>
      <AnimatedNumbersMark>
        <strong className='text'>Investing</strong>
      </AnimatedNumbersMark>
      </AnimatedNumbersContainer>
        <h3 id="contact">Contact</h3>
        <ul className="h-card" itemProp="contactPoint" itemScope itemType="http://schema.org/ContactPoint">
            <li>Email: <a className="u-email" href="mailto:contact@coindatashake.com" itemProp="email">contact@coindatashake.com</a></li>
            <li>Instagram: <a href="https://www.instagram.com/coindatashakecrypto">coindatashakecrypto</a></li>
            <li>Facebook: <a href="https://www.facebook.com/coindatashake">coindatashake</a></li>
        </ul>
        </SectionContainer>
        </div>
    </AboutUsContainer>
    </> 
}

const AnimatedNumbersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media(max-width: 960px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const AnimatedNumbersMark = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    color: ${colors.bgcPrimary};
    border: 6px solid ${colors.textPrimary};
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    text-align: center;
    cursor: text;
    &:hover {
        filter: brightness(1.15);
        }

    & strong {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
    }

    & strong:nth-of-type(2) {
        font-size: 2.1rem;
        font-weight: normal;
    }

    & .text {
        display: inline-block;
        padding-bottom: 0.4rem;
        border-bottom: 3px solid ${colors.textPrimary};
    }

    & .big+div {
        transform: translateY(-0.5rem);
    }
`;

export const AboutUsContainer = styled.div`
    & mark {
        background-color: transparent;
        color: ${colors.textPrimary};
        font-weight: bold;
    }
    &>header {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    & a {
        color: ${colors.white}!important;
    }

    & ul {
        list-style: none;
        margin-left: 4rem;
        font-size: 1.4rem;
        margin-bottom: 2rem;
        & li {
            line-height: 150%;
        }
    }

    & section p {
        font-size: 1.4rem;
        margin-bottom: 2rem;
        text-align: left;
        line-height: 140%;
    }

    & img {
        width: 100%;
        display: block;
        padding: 0.5rem 0;
        margin-right: 1rem;
    }

    & .MuiCard-root {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }

    & .container {
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    & .imgLink {
        color: ${colors.transparent};
    } 

    & h3 {
margin-bottom: 1rem;
font-size: 1.8rem;
    }
`;

export default AboutUs;