import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {colors} from '../../assets/colors';
import {bitcoinData} from '../../provider/bitcoinData';
import { CommunityList, CommunityListItem, Label } from './CommunityTab';

const DevelopmentTab = observer(() => {
    const endLineSign = '.';
    const itemEmptyValue = '';

    const developmentList = Object
        .entries(bitcoinData.developerData || {})
        .map((developerItem, index) => {

            if (developerItem[1] === null) {
                return itemEmptyValue;
            }

            if (typeof developerItem[1] === 'object' || Array.isArray( developerItem[1])) {
                return itemEmptyValue;
            }

            return <CommunityListItem key={index}>
            <p>
                <>
                <Label><FormattedMessage id={developerItem[0]}/></Label>
                {developerItem[1]}
                {endLineSign}
                </>
               
            </p>
        </CommunityListItem>
        });

    const allZeroes = ((bitcoinData
        .developerData as any)
        .last_4_weeks_commit_activity_series as Array<number>)
        .every(function (a, _, aa) {
            return a === aa[0];
        })

    return (bitcoinData.developerData as any).last_4_weeks_commit_activity_series.length && !allZeroes
        ? (
            <>
            <CommunityList>
            {developmentList}
            </CommunityList>
            </>
        )
        : <Message>
            <FormattedMessage id="developmentTab.error"/>
        </Message>
});

const Message = styled.p `
    padding-top: 1rem;
    line-height: 150%;
    font-size: 1.4rem;
`;

export const Scroll = styled.div `
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    
    @media (max-width: 750px) {
        overflow-x: scroll;
        justify-content: flex-start;
    }
`;

export const ChartContainer = styled.div `
    width: 600px;
    height: 300px;
    & .tickLabel {
        fill: ${colors.white} !important;
        font-size: 0.9rem !important;
    }
    
    & .Series * {
        stroke: ${colors.textPrimary} !important;
    }

    & .tick:last-of-type {
        * {
            display: none;
        }
    }

    & .Axis * {
        stroke: transparent !important;
    }
`;

export const ChartTitle = styled.h2 `
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
`;

export default DevelopmentTab;