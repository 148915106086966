import {observer} from 'mobx-react';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import { colors } from '../assets/colors';
import {bitcoinData} from '../provider/bitcoinData';

const Price = observer(({currentCurrency}: {currentCurrency: string}) => {

    return <PriceContainer>
        <PriceTitle>
            <FormattedMessage id="priceTitle"/>
        </PriceTitle>
        <h3>
            <Value>
                {bitcoinData.currentPrice}
            </Value>
            <Currency>
                {currentCurrency?.toUpperCase()}
            </Currency>
        </h3>
    </PriceContainer>;
})

const Value = styled.span`
    color: ${colors.textPrimary};
    text-shadow: rgba(0, 0, 0, 0.1) 2px 6px;
    -webkit-text-shadow:  rgba(0, 0, 0, 0.1) 2px 6px;
    -moz-text-shadow:   rgba(0, 0, 0, 0.1) 2px 6px;
`;

const PriceContainer = styled.div `
    position: initial;
    margin-top: 2rem;
    text-align: center;
    right: 10%;
    font-size: 1.2rem;
    & p {
        font-size: 1.5rem;
    }
    @media (min-width: 1000px) {
        font-size: 1.5rem;
        position: absolute;
        top: 30%;
        margin-top: 0;
        & p {
            font-size: 1.5rem;
        }
    }
`;

const PriceTitle = styled.h2 `
    margin-bottom: 1rem;
    text-shadow: rgba(0, 0, 0, 0.1) 2px 6px;
    -webkit-text-shadow:  rgba(0, 0, 0, 0.1) 2px 6px;
    -moz-text-shadow:   rgba(0, 0, 0, 0.1) 2px 6px;`

const Currency = styled.abbr `
    margin-left: 0.3rem;
    color: ${colors.textPrimary};
    text-shadow: rgba(0, 0, 0, 0.1) 2px 6px;
    -webkit-text-shadow:  rgba(0, 0, 0, 0.1) 2px 6px;
    -moz-text-shadow:   rgba(0, 0, 0, 0.1) 2px 6px;


`

export default Price;