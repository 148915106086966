import React, {useRef, useEffect, useState, useMemo} from "react";
import styled from "styled-components";
import Logo from "../components/logo/Logo";
import gsap from "gsap";
import {useMediaQuery} from 'react-responsive'
import {HomeForm} from "../components/homeForm/HomeForm";
import {colors} from "../assets/colors";
import {FormattedMessage} from "react-intl";
import MarketCap from "../components/homeRankings/MarketCap";
import {currencyNames} from "../assets/currencyNames";
import BestReturn from "../components/homeRankings/BestReturn";
import ToMax from "../components/homeRankings/ToMax";
import HomeLinksSection from "../components/homeLinksSection/HomeLinksSection";
import UndervaluedDay from "../components/homeRankings/UndervaluedDay";
import LowestReturn from "../components/homeRankings/LowestReturn";
import { Description, Title } from "../components/homeRankings/Slider";
import { Link } from "react-router-dom";
import { Links } from "../assets/links";
import OtherPosts from "../blog/components/OtherPosts";
import { btcVsEth } from "../blog/posts/btcVsEth";
import { cryptoTaxes } from "../blog/posts/cryptoTaxes";
import { decentralizedFin } from "../blog/posts/decentralizedFin";
import { riseOfNft } from "../blog/posts/riesOfNft";
import { stakingCrypto } from "../blog/posts/stakingCrypto";
import { top5Altcoins } from "../blog/posts/top5Altcoins";
import { Img, ImgContainer } from "./BlogPost";
import {Post, PostTitle} from "./Blog";
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga';

const Home = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const headerRef = useRef(null);
    const bigScreenQuery = "(min-width: 1000px)";
    const isBigScreen = useMediaQuery({query: bigScreenQuery});

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[])


    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, [])

    useEffect(() => {
        if (headerRef && isBigScreen) {
            let timeLine = gsap.timeline();
            timeLine.set(headerRef.current, {
                color: colors.transparent,
                textShadow: '2px 6px rgba(0,0,0,0)'
            })
            timeLine.fromTo(headerRef.current, {
                color: colors.bgcPrimary,
                opacity: 1,
                duration: 3,
                repeat: 1
            }, {
                color: colors.white,
                duration: 3,
                delay: .5,
                textShadow: '2px 6px rgba(0,0,0,0.1)'
            });
        }
    }, [headerRef, isBigScreen]);

    const [crypto,
        setCrypto] = useState('');
    const [cryptoError,
        setCryptoError] = useState(false);
    const [currency,
        setCurrency] = useState('');
    const [currencyList,
        setCurrencyList] = useState([]);
    const [currencyError,
        setCurrencyError] = useState(false);
    const [cryptoNames,
        setCryptoNames] = useState([]);

    useEffect(() => {
        const allCryptoNames = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd';
        const allAvailableCurrency = 'https://api.coingecko.com/api/v3/simple/supported_vs_currencies';

        const cryptoErrorMessage = "Cant read currency names";
        fetch(allCryptoNames)
            .then(res => res.json())
            .then(data => setCryptoNames(data.map((item : any) => ({id: item.id, value: item.name, image: item.image, marketCap: item.market_cap, priceChange: item.price_change_percentage_24h, toMax: ((item.ath / item.current_price) * 100).toFixed(2),
            undervaluedDay: ((item.high_24h / item.current_price) * 100).toFixed(2)
        }))))
            .catch(err => console.log(err + cryptoErrorMessage));

        const currencyErrorMessage = "Cant read currency names";
        fetch(allAvailableCurrency)
            .then(res => res.json())
            .then(data => setCurrencyList(data.map((item : any) => ({
                id: item,
                value: item,
                description: (currencyNames as any)[item] ?? item
            }))))
            .catch(err => console.log(err + currencyErrorMessage));
    }, []);

    const [isBlogVisible,
        setIsBlogVisible] = useState(false);
    const [isToolsVisible,
        setIsToolsVisible] = useState(false);

    const blogRef = useRef(null);
    const toolsRef = useRef(null);

    useEffect(() => {
        if (blogRef.current) {
            const formPositionFromTop = (blogRef.current as HTMLDivElement)
                ?.offsetTop - 150;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isBlogVisible && isInScrollContext)) {
                setIsBlogVisible(true);
            }

        }

    }, [isBlogVisible, blogRef, scrollPosition]);

    useEffect(() => {
        if (toolsRef.current) {
            const formPositionFromTop = (toolsRef.current as HTMLDivElement)
                ?.offsetTop - 150;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isToolsVisible && isInScrollContext)) {
                setIsToolsVisible(true);
            }

        }

    }, [isToolsVisible, toolsRef, scrollPosition]);

    const posts = useMemo(() => [
        btcVsEth,
        cryptoTaxes,
        decentralizedFin,
        riseOfNft,
        stakingCrypto,
        top5Altcoins
    ], []);


    const [sliderIndex,
        setSliderIndex] = useState(0);

    useEffect(() => {
        if (isNaN(sliderIndex) || sliderIndex < 0){
            setSliderIndex(0);
        }
    },[sliderIndex]);

return <>
    <Helmet>
            <title>Cryptocurrency Market Tools and Articles | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Our cryptocurrency page offers a wide range of analytical tools and articles to help you navigate the fast-moving world of the cryptocurrency market. From technical analysis tools to statistical forecasts and moving average calculators. Explore our page today and take your trading to the next level." />
                <meta property="og:title" content="Cryptocurrency Market Tools and Articles | Coin Data Shake" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://coindatashake.com/" />
                <meta property="og:image" content="https://coindatashake.com/images/home/coin-data-shake-home.webp" />
                <meta property="og:description" content="Our cryptocurrency page offers a wide range of analytical tools and articles to help you navigate the fast-moving world of the cryptocurrency market. From technical analysis tools to statistical forecasts and moving average calculators. Explore our page today and take your trading to the next level." />
        </Helmet>
        <HomeContainer className="h-feed" itemScope itemType="http://schema.org/WebSite">
        <Banner>
            <LogoContainer>
                <Logo/>
            </LogoContainer>
            <BannerText ref={headerRef} >
                <p itemProp="name" className="p-name">Coin Data Shake</p>
                <p>
                <FormattedMessage id='logo.motto'/>
                <AnimatedText itemProp="url" className="u-url" to={Links.HOME}>
                    <FormattedMessage id='logo.highlightedText'/>
                </AnimatedText>
                </p>   
            </BannerText>
        </Banner>
        <HomeForm
            crypto={crypto}
            currency={currency}
            cryptoError={cryptoError}
            currencyError={currencyError}
            cryptoNames={cryptoNames}
            currencyList={currencyList}
            setCrypto={setCrypto}
            setCryptoError={setCryptoError}
            setCurrency={setCurrency}
            setCurrencyError={setCurrencyError}
            scrollPosition={scrollPosition} />
        <BestReturn cryptoNames={cryptoNames} scrollPosition={scrollPosition}/>
        <LowestReturn cryptoNames={cryptoNames} scrollPosition={scrollPosition}/>
        <UndervaluedDay cryptoNames={cryptoNames} scrollPosition={scrollPosition} />  
        <MarketCap cryptoNames={cryptoNames} scrollPosition={scrollPosition}/>
        <ToMax cryptoNames={cryptoNames} scrollPosition={scrollPosition}/>
        <SectionContainer id="homeBlog" isVisible={isBlogVisible} ref={blogRef} className="lowMargin">
            <Title>Cryptocurrency blog</Title>
            <Description>
            Cryptocurrency has become an increasingly popular topic in recent years as more people have become interested in the concept of digital currencies. The cryptocurrency market is a fast-paced and dynamic space, with new coins and tokens being created all the time. In this blog, we will explore the latest developments in the cryptocurrency market, from new coins and tokens to regulatory changes and market trends. We will also provide analysis and insights on the cryptocurrency market, helping you stay up-to-date with the latest news and trends in this exciting space.
            </Description>
            <Description>
            At the heart of the cryptocurrency market is the blockchain technology that underpins it. Blockchain is a decentralized ledger that allows for secure, transparent, and immutable transactions between parties. This technology has the potential to revolutionize a wide range of industries, from finance and banking to healthcare and supply chain management. In this blog, we will delve into the technical aspects of blockchain and how it is changing the way we think about transactions and data storage.
            </Description>
            <Description>
            Whether you're an experienced cryptocurrency investor or simply curious about this exciting space, this blog is for you. We will cover a range of topics related to the cryptocurrency market, from the latest trends and regulatory changes to in-depth analysis of individual coins and tokens. So, if you want to stay ahead of the curve and stay informed about the latest developments in the cryptocurrency market, be sure to bookmark this blog and check back regularly for updates. <Link to={Links.BLOG}>Visit our blog.</Link>
            </Description>
        </SectionContainer>
        <OtherPosts posts={posts} sliderIndex={sliderIndex} setSliderIndex={setSliderIndex} />
        <SectionContainer id="homeTools" isVisible={isToolsVisible} ref={toolsRef}>
            <Title>Cryptocurrency market analytical tools</Title>
            <Description>
            As the cryptocurrency market continues to grow and evolve, investors and traders are increasingly turning to analytical tools to help them make informed decisions. There are a variety of analytical tools available to those who trade in the cryptocurrency market, ranging from technical indicators and charting tools to sentiment analysis and machine learning algorithms. Our analytical tools provide reliable and up-to-date analysis, along with clear interpretation and descriptions of how they can be used to make informed trading decisions.
            </Description>
            <Description>
            One of the most widely used analytical tools in the cryptocurrency market is technical analysis. Technical analysis involves studying past price and volume data to identify trends and patterns that can be used to predict future price movements. This type of analysis is often used in conjunction with charting tools, which allow traders to visually analyze price movements over time. By using technical analysis and charting tools, traders can identify support and resistance levels, as well as potential entry and exit points for trades.
            </Description>
            <Description>
            In addition to technical analysis and sentiment analysis, machine learning algorithms are also becoming increasingly popular in the cryptocurrency market. These algorithms can be used to analyze vast amounts of data and identify patterns that may not be apparent to human traders. By using machine learning algorithms, traders can gain a deeper understanding of the market and make more informed trading decisions based on data-driven insights. As the cryptocurrency market continues to evolve, it is likely that we will see even more innovative analytical tools emerge to help traders navigate this exciting and fast-moving space.
            </Description>
            <div className="posts">

            <Post className="h-entry maxWidth" itemScope itemType='https://schema.org/Article'>
            <Link to={Links.CRYPTO_WALLET}  className="u-url" itemProp="url">
                <PostTitle className="p-name" itemProp="headline">Cryptocurrency wallet correlation analysis</PostTitle>
                <ImgContainer>
                    <Img src="/images/wallet-correlation/crypto-wallet-correlation.webp" alt="On the image you can see a our form to generate cryptocurrency wallet" className="u-photo" itemProp="image"/>
                </ImgContainer>
                <p className="e-content" itemProp="articleBody">
                A cryptocurrency wallet correlation tool is a powerful analytical tool that can help investors and traders better understand the dynamics of the cryptocurrency market. This tool analyzes the transactions and holdings of various cryptocurrency wallets to identify patterns and correlations between different coins and tokens. By using a cryptocurrency wallet correlation tool, investors and traders can gain insights into the market and make more informed trading decisions. One of the main benefits of using a cryptocurrency wallet correlation tool is the ability to identify relationships between different coins and tokens. For example, if the tool identifies a strong correlation between Bitcoin and Ethereum, it may indicate that the two coins are moving in tandem and that a change in the price of one may affect the price of the other. This information can be used to make more informed trading decisions and minimize risk in a fast-moving market.
                </p>
            </Link>
        </Post>
        <Post className="h-entry maxWidth" itemScope itemType='https://schema.org/Article'>
            <Link to={Links.MOVING_AVERAGE} className="u-url" itemProp="url">
                <PostTitle className="p-name" itemProp="headline">Moving average cryptocurrency analysis</PostTitle>
                <ImgContainer>
                    <Img src="/images/ma/coin-moving-average.webp" alt="On the image you can see a chart with results of moving average calculation" className="u-photo" itemProp="image"/>
                </ImgContainer>
                <p  className="e-content" itemProp="articleBody">
                Moving averages are an important analytical tool used in the cryptocurrency market to help traders identify trends and potential trading opportunities. A moving average is simply the average price of a coin or token over a specified period of time. By plotting moving averages on a chart, traders can identify trends in the market and potential support and resistance levels. By using a moving average calculator, traders can gain a deeper understanding of the market and make more informed trading decisions based on data-driven insights. Whether you're a seasoned trader or just starting out in the cryptocurrency market, a moving average calculator can be a valuable tool in your trading arsenal. With the ability to quickly and easily calculate moving averages and identify trends in the market, you can stay ahead of the curve and make the most of your trading opportunities in this exciting and dynamic space.
                </p>
            </Link>
        </Post>
        </div>
        </SectionContainer>
        <HomeLinksSection scrollPosition={scrollPosition} />
    </HomeContainer>
</>  
};

const SectionContainer = styled.section< {
    isVisible?: boolean
} > `
    opacity: ${props => props.isVisible
    ? '1'
    : '0'};
    margin: 10rem auto;
    @media(max-width: 980px) {
        margin: 2rem auto;
    }
    max-width: 1200px;
    font-size: 1.2rem;
    padding: 0;
    & p {
        padding-left: 4rem;
        @media (orientation: portrait) and (max-width: 900px) {
            padding: 1rem;
        }
    }
    transition: 1s;
    &.lowMargin {
        margin-bottom: 2rem;
    }

    & .posts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 2rem;
    }

        & .maxWidth {
            flex-basis: 80%!important;
            @media (max-width: 980px) {
                padding: 0;
            }

            &>a {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: center;
                padding: 1rem;
            }
            & h3 {
                color: ${colors.white};
                text-align: center;
                margin-bottom: 2rem;
            }
            & p {
                color: ${colors.white};
                font-size: 1.4rem;
                line-height: 140%;
                @media (max-width: 980px) {
                    padding: 0;
                }
            }
        }

`;


const HomeContainer = styled.div `
    margin-bottom: 10rem;
    padding-bottom: 5rem;
    position: absolute;
    top: 20%;
    width: 100vw;
    @media (max-width: 980px) {
        padding: 0 1rem 0 1rem;
        top: 10%;
    }
`;

const AnimatedText = styled(Link)`
    margin-left: 0.5rem;
    background-color: ${colors.transparent};
    text-decoration: none;
    color: ${colors.textPrimary};
`;

const LogoContainer = styled.div `
    width: 380px;
    height: 380px;
    margin-left: 3rem;
    margin-bottom: 1rem;
    @media(max-width: 700px) {
        & {
            margin-left: 1rem;
        }
    }
    
    @media (orientation: portrait) {
        width: 220px;
        height: 220px;
    }
`;

const BannerText = styled.h1 `
    padding-top: 1rem;
    font-size: 2.6rem;
    transform: translateY(-3rem);
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 2px 6px rgba(0,0,0,0.1);
    & p:first-of-type {
        padding-bottom: 1rem;
    }
    & p:last-of-type {
        line-height: 150%;
    }

    @media(max-width: 500px) {
        & {
            margin-right: 1rem;
        }
    };
    @media (orientation: portrait) {
        font-size: 1.6rem;

    }
`;

const Banner = styled.header `
    background-color: ${colors.transparent};
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin: 0 1rem;
    @media (max-width: 960px) {
        transform: translateX(1rem);
    }
`;

export default Home;