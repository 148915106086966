import React from "react";
import {observer} from "mobx-react";
import {bitcoinData} from "../../provider/bitcoinData";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";

const CommunityTab = observer(() => {
    const endLineSign = '.';
    const itemEmptyValue = '';
    const communityList = Object
        .entries(bitcoinData.communityData || {})
        .map((communityItem, index) => communityItem[1] !== null
            ? <CommunityListItem key={index}>
                    <p>
                        <Label><FormattedMessage id={communityItem[0]}/></Label>
                        {communityItem[1]}
                        {endLineSign}
                    </p>
                </CommunityListItem>
            : itemEmptyValue);

    return <CommunityList>
        {communityList}
        {!communityList.join(itemEmptyValue) && <FormattedMessage id="communityTab.error"/>}
    </CommunityList>;
})

export const CommunityList = styled.ul `
    padding-top: 1rem;
    list-style: none;
    font-size: 1.4rem;
`;

export const CommunityListItem = styled.li `
    line-height: 200%;
`;

export const Label = styled.span `
    margin-right: 1.2rem;
`

export default CommunityTab;