export const homeTexts = {
    'logo.motto': 'Everything you need for your',
    'logo.highlightedText': 'cryptocurrency',
    'form.title': 'See our forecasts and daily market analysis:',
    'form.firstParagraph': 'Select coin you want to analyse and currency in which data will be presented.',
    'form.secondParagraph': 'Please select a value from expanded list. Click analyse button to get:',
    'form.cryptoLabel': 'Select cryptocurrency to forecast',
    'form.currencyLabel': 'Select currency for data display',
    'form.correct': 'You can now go to next page.',
    'form.incorrect': 'Please fill all above fields with correct value from list.',
    'form.buttonMessage': 'Analyse',
    'marketCap.title': "Top 20 cryptocurrency based on market capitalization",
    'marketCap.label': "Market cap: ",
    'bestReturn.title': "Top 20 cryptocurrency returns 24h",
    'bestReturn.label': "Day return:",
    'toMax.title': "Top 20 undervalued cryptocurrency based on ATH",
    'toMax.label': "Percentage to max: ",
    'undervaluedDay.title': "Top 20 undervalued cryptocurrency based on percentage to max 24h",
    'undervaluedDay.label': 'Percentage to max:',
    "marketCap.description": 'The cryptocurrency market capitalization refers to the total value of all cryptocurrencies in circulation. It is calculated by multiplying the total number of coins or tokens in circulation by their current market price. The market capitalization of the cryptocurrency market is a key metric used to assess the overall size and health of the market. It can be used to compare the value of the cryptocurrency market to other financial markets, such as the stock market or the bond market. The market capitalization of the cryptocurrency market has been known to fluctuate widely, driven by various factors such as changes in the regulatory environment, technological advancements, and global economic conditions. As the market for cryptocurrencies continues to evolve and mature, the market capitalization will likely continue to be an important indicator of its overall growth and potential.',
    "toMax.description": 'Undervalued cryptocurrencies are those that have a low market capitalization compared to their all-time high (ATH) price. This means that at some point in the past, the cryptocurrency had a much higher value than it currently does, but the market has since corrected, causing its price to drop. Identifying undervalued cryptocurrencies can be a challenging task, as it requires a careful analysis of market trends and the underlying technology of the cryptocurrency in question. However, if done correctly, investing in undervalued cryptocurrencies can be a lucrative strategy, as their potential for growth is significant.One way to identify undervalued cryptocurrencies is to look at their historical price movements and compare them to their current market capitalization. If a cryptocurrency has a low market cap relative to its ATH, it could be a good opportunity for investment, especially if the underlying technology has strong potential for growth.',
    "priceChange.description": "Cryptocurrencies are known for their volatile nature, which can lead to significant fluctuations in their returns on investment. The return on investment (ROI) of a cryptocurrency is the percentage change in its price over a given period of time, and is an important factor that investors consider when deciding to invest in a particular cryptocurrency. The ROI of a cryptocurrency can be influenced by a variety of factors, including the demand for the coin, the overall sentiment in the market, and the development progress of the project. While some cryptocurrencies have delivered incredible returns on investment in a relatively short period of time, others have experienced significant losses. As with any investment, it is important for investors to carefully evaluate the potential risks and rewards of investing in a particular cryptocurrency before making any investment decisions. It is also important to diversify investments across different cryptocurrencies and other asset classes to manage risk and potentially enhance returns.",
    'cryptocurrency.placeholder.small': 'Cryptocurrency',
    'currency.placeholder.small': 'Exchange currency',
    'undervaluedDay.description': "The price to max indicator in the last 24 hours is a metric used by some investors and traders to assess the current price of a cryptocurrency in relation to its highest price within the last 24 hours. This indicator can help investors identify whether a particular cryptocurrency is currently trading near its peak, or if it has room for further growth potential. The price to max indicator is calculated by dividing the current price of the cryptocurrency by its highest price in the last 24 hours and then multiplying the result by 100. For example, if a cryptocurrency's current price is $50, and its highest price in the last 24 hours is $60, then its price to max indicator would be 83.3%. This means that the cryptocurrency is currently trading at 83.3% of its highest price in the last 24 hours. While the price to max indicator can provide some insights into a cryptocurrency's current market performance, it is important to note that this metric only reflects the price movements within the last 24 hours. This means that it may not accurately reflect the cryptocurrency's long-term value or potential. Additionally, the price to max indicator should not be used as the sole factor when making investment decisions, as other factors such as market trends, project fundamentals, and news developments should also be considered.",
    'lowestReturn.title': 'Top 20 cryptocurrency lowest returns 24h',
    'lowestReturn.label': 'Day return:',
    'priceChangeLowest.description': "The lowest return in cryptocurrency in the last 24 hours refers to the cryptocurrency with the least price increase or even a price decrease over a period of 24 hours compared to other cryptocurrencies. Investors may use this metric to evaluate the market sentiment towards a particular cryptocurrency and to identify potential investment opportunities. However, it is important to note that a single day's performance should not be used as the sole indicator for an asset's long-term investment potential. Cryptocurrency markets are highly volatile and unpredictable, and short-term price fluctuations are common. Investors should conduct thorough research and analysis, including fundamental analysis and technical analysis, to fully understand the potential risks and rewards of investing in a particular cryptocurrency. In summary, while the lowest return in cryptocurrency in the last 24 hours can provide some insights into market sentiment and potential investment opportunities, it should not be used as the sole indicator for investment decisions. Investors should conduct comprehensive research and analysis, have a solid risk management strategy in place, and monitor the cryptocurrency market to make informed investment decisions."
}