export const navigation = {
    'coinDetails': 'Coin details',
    'marketData': 'Market data',
    'forecast': 'Forecast',
    'resources': 'Resources',
    'description': 'Description',
    'createWallet': 'Create wallet',
    'interpretation': 'Interpretation',
    'homeForm': 'Forecasts and data analysis',
    'marketCap': 'Market capitalization',
    'priceChange': 'Best return 24h',
    'toMax': 'Undervalued cryptocurrency',
    'homeLinks': 'Additional resources',
    'maForm': 'Analysis',
    'blogDescription': "Blog description",
    'posts': 'Recent Posts',
    'toolsLinks': 'Cryptocurrency Tools',
    'company': 'Company',
    'goals': 'Goals and Values',
    'contentCreation': 'Content creation',
    'contact': 'Contact',
    'technicalities': 'Technicalities',
    'howTo': 'How to use',
    'undervaluedDay': 'Undervalued 24h',
    'priceChangeLowest': 'Lowest return 24h',
    'homeBlog': 'Cryptocurrency blog',
    'homeTools': 'Investment tools',
};