import React from 'react';
import { Card, CardIcon, CardTitle, Container, Title } from '../../components/homeRankings/Slider';
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Links } from '../../assets/links';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Link } from 'react-router-dom';

interface Post {
    title: string;
    link: string;
    mainImage: string;
    alt: string;
}

const OtherPosts = ({currentPost, posts, sliderIndex, setSliderIndex}: {posts: Array<Post>; currentPost?: Post; sliderIndex: number; setSliderIndex: any}) => {
    const filterPosts = posts.filter(post => post.title !== currentPost?.title);
    const bigScreenQuery = "(orientation: landscape) AND (min-width: 1000px)";
    const middleScreenQuery = "(min-width: 800px) AND (max-width: 999px)";
    const isBigScreen = useMediaQuery({query: bigScreenQuery});
    const isMiddleScreen = useMediaQuery({query: middleScreenQuery});
    const slidesVisible = isBigScreen
        ? 3
        : isMiddleScreen
            ? 2
            : 1;
    const animationSpeedMs = 3000;

        const postsItemsList = filterPosts
        ?.map((item, index) => <Card key={index} style={{ height: isBigScreen ? '400px' : '450px'}} className="h-entry" itemScope itemType='https://schema.org/Article'>
            <CardIcon className="cardIcon">
                <img src={item.mainImage} alt={item.alt} loading="lazy" className="u-featured" itemProp="image"/>
            </CardIcon>
            <CardTitle className="p-name" itemProp="headline">
                <Link to={Links.BLOG + '/' + item.link} title={`Visit our "${item.title}" post page`} className="u-url" itemProp="url">
                    {item.title}
                </Link>
            </CardTitle>
        </Card>);

    return <PostContainer><Container
        sliderIndex={sliderIndex}
        sliderLength={filterPosts.length}
        slidesVisible={slidesVisible}
        isVisible
        >
        <Title>
            {currentPost ? "Other posts" : "See our blog posts"}
        </Title>
        {!!postsItemsList.length &&  <Carousel
            plugins={[
            'arrows', {
                resolve: slidesToShowPlugin,
                options: {
                    numberOfSlides: slidesVisible
                }
            }
        ]}
            animationSpeed={animationSpeedMs}
            value={sliderIndex}
            onChange={setSliderIndex}
            draggable={false}
            >
            {postsItemsList}
        </Carousel>}
       
    </Container>
    </PostContainer> 
};

const PostContainer = styled.div`
& img {
    transition: 1s;
}

& .p-name>a {
    color: ${colors.white};
}


& .cardIcon {
    width: 90%;
    height: 170px;
    overflow: hidden;
}

& .p-name {
    line-height: 140%;
    margin-top: 2rem;
}

&>section {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 1rem;
}

@media (min-width: 960px) {
    & .h-entry:hover {
        cursor: pointer;
        border-color: ${colors.textPrimary};
        @media (min-width: 960px) {
            & img {
                transform: scale(1.1);
            }
        }
      
        & h3 {
            color: ${colors.textPrimary}!important;
        }
    
        & .p-name>a {
            color: ${colors.textPrimary};
        }
    }
}


`;

export default OtherPosts;