import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {SectionContainer, SectionTitle} from '../../pages/CryptoWalletCorr';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    scales
  } from 'chart.js';
import {Line} from 'react-chartjs-2';
import { colors } from '../../assets/colors';
import { useMediaQuery } from 'react-responsive';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    scales
  );

ChartJS.defaults.color = colors.white;
ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Ubuntu, sans-serif';
  
function calculateMovingAverage(data : Array < Array < number >>, windowSize : number) : Array < Array < number >> {
    const result: Array < Array < number >>= [];

    for (let i = 0; i < data.length - windowSize + 1; i++) {
        let sum = 0;
        for (let j = 0; j < windowSize; j++) {
            sum += data[i + j][1];
        }
        result.push([
            data[i + windowSize - 1][0],
            sum / windowSize
        ]);
    }

    return result;
}

const MaCalculation = ({crypto, currency, firstMa, secondMa} : {
    crypto: string;
    currency: string;
    firstMa: number;
    secondMa: number
}) => {
    const [prices,
        setPrices] = useState < Array < Array < number >>> ([]);

    const shortMa = useMemo(() => {
        return calculateMovingAverage(prices, Math.min(firstMa, secondMa));
    }, [prices, firstMa, secondMa]);

    const longMa = useMemo(() => {
        return calculateMovingAverage(prices, Math.max(firstMa, secondMa));
    }, [prices, firstMa, secondMa]);

    useEffect(() => {
        if (crypto && currency && firstMa && secondMa) {
            fetch(`https://api.coingecko.com/api/v3/coins/${crypto}/market_chart?days=91&vs_currency=${currency}`)
                .then(res => res.json())
                .then(data => setPrices(data.prices))
                .catch(err => console.log(err))
        }
    }, [crypto, currency, firstMa, secondMa]);

    const maVisibleValues = 11;

const options = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Moving Averages',
      },
    }
  }), []);

const labels = useMemo(() => shortMa.slice(-maVisibleValues, -1).map(priceObj => new Date(priceObj[0]).toLocaleDateString()), [shortMa]);

const data = useMemo(() => (
    {
        labels,
        datasets: [
          {
            label: `MA1 (${Math.min(firstMa, secondMa)} days)`,
            data: shortMa.slice(-maVisibleValues, -1).map(priceObj => priceObj[1]),
            borderColor: 'yellow',
            backgroundColor: 'rgba(255, 255, 0, 0.5)',
          },
          {
            label: `MA2 (${Math.max(firstMa, secondMa)} days)`,
            data: longMa.slice(-maVisibleValues, -1).map(priceObj => priceObj[1]),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      }
),[shortMa, longMa, labels, firstMa, secondMa]);
const portraitQuery = "(orientation: portrait) and (max-width: 960px)";
const isPortrait = useMediaQuery({query: portraitQuery});
   
      const buySignals = useMemo( () => {
        const result: Array<number> = [];
        shortMa.slice(-maVisibleValues, -1).forEach((priceObj, index, arr) => {
            if (index === arr.length - 1) return;
            const currentLongValue = longMa.slice(-maVisibleValues, -1)[index][1];
            const nextLongValue = longMa.slice(-maVisibleValues, -1)[index + 1][1];
            if (priceObj[1] < currentLongValue && arr[index + 1][1] >= nextLongValue) {
                // Save only dates
                result.push(arr[index + 1][0]);
            }
        });
        return result;

      }, [shortMa, longMa]);

      const sellSignals = useMemo( () => {
        const result: Array<number> = [];
        shortMa.slice(-maVisibleValues, -1).forEach((priceObj, index, arr) => {
            if (index === arr.length - 1) return;
            const currentLongValue = longMa.slice(-maVisibleValues, -1)[index][1];
            const nextLongValue = longMa.slice(-maVisibleValues, -1)[index + 1][1];
            if (priceObj[1] > currentLongValue && arr[index + 1][1] <= nextLongValue) {
                // Save only dates
                result.push(arr[index + 1][0]);
            }
        });
        return result;

      }, [shortMa, longMa]);

      const [isResultsVisible,
        setIsResultsVisible] = useState(false);
    

    useEffect(() => {
        setIsResultsVisible(true);
    }, []);

    return <MaContainer> <SectionContainer id="maValues" isVisible={isResultsVisible}>
        <SectionTitle>Moving Average Values</SectionTitle>
        <Table>
            <thead>
                <tr>
                    <TableHeader>Date</TableHeader>
                    <TableHeader>{`MA1 (${Math.min(firstMa, secondMa)} days)`}</TableHeader>
                    <TableHeader>{`MA2 (${Math.max(firstMa, secondMa)} days)`}</TableHeader>
                </tr>
            </thead>
            <tbody>
                {shortMa
                    .slice(-maVisibleValues, -1)
                    .map((priceObj, index) => {
                        return <tr key={index}>
                            <TableData>{new Date(priceObj[0]).toLocaleDateString()}</TableData>
                            <TableData>{priceObj[1].toFixed(2)}</TableData>
                            <TableData>{longMa
                                    .slice(-maVisibleValues, -1)[index][1]
                                    .toFixed(2)}</TableData>
                        </tr>
                    })}
            </tbody>
        </Table>
        <ChartContainer>
            <Line options={options} data={data} {...(isPortrait  ? {height: 300} : {})}/>
        </ChartContainer>
    </SectionContainer>
    <SectionContainer isVisible={isResultsVisible}>
        <SectionTitle>Trading Signals</SectionTitle>
        {!!buySignals.length && <SubHeader style={{color: colors.success}}>Buy Signals:</SubHeader>} 
        {buySignals.map((value) => <SignalDescription key={value}>{new Date(value).toLocaleDateString()} - short term moving average cross long term moving average from bottom.</SignalDescription>)}
        {!!sellSignals.length && <SubHeader style={{color: colors.error}}>Sell Signals:</SubHeader>}
        {sellSignals.map((value) => <SignalDescription key={value}>{new Date(value).toLocaleDateString()} - short term moving average cross long term moving average from above.</SignalDescription>)}
        {!buySignals.length && !sellSignals.length && <SignalDescription style={{color: colors.warning}}>There are no trading signals in current period for this cryptocurrency.</SignalDescription>}
    </SectionContainer>
    </MaContainer>
};

const MaContainer = styled.div`
    &>section:last-of-type {
        margin: 4rem auto;
        margin-bottom: 0;
        padding-bottom: 3rem;
        border-bottom: 2px solid ${colors.white};
    }
`;

const SubHeader = styled.h3`
    margin: 1rem 0;
    font-size: 1.4rem;
`;

const SignalDescription = styled.p`
    margin-bottom: 1rem;
    font-size: 1.2rem;
`;

export const ChartContainer = styled.div`
    margin-top: 4rem;
`;

const Table = styled.table `
    width: 100%;
    font-size: 1.5rem;
`;

const TableHeader = styled.th `
    padding-bottom: 1rem;
`;

const TableData = styled.td `
    padding-bottom: 0.5rem;
    text-align: center;
`;

export default MaCalculation;