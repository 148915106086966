export const makeParagraphs = (longText : string) => {
    const parSign = '\r\n\r\n';
    const minParagraphs = 0;
    const maxParagraphs = 4;
    const joinAllSign = "";

    const description = longText.split(parSign)
        ?.map(paragraph => `<p>${paragraph}</p>`);

    return description.slice(minParagraphs, maxParagraphs).join(joinAllSign);
};