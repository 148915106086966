import React from 'react';
import styled from 'styled-components';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {colors} from './assets/colors';
import {Links} from './assets/links';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import Navigation from './components/navigation/Navigation';
import PageNotFound from './components/errors/PageNotFound';
import CookieConsent from './components/cookieConsent/CookieConsent';
import CoinInfo from './pages/CoinInfo';
import CryptoWalletCorr from './pages/CryptoWalletCorr';
import MovingAverage from './pages/MovingAverage';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Sitemap from './pages/Sitemap';
import Home from './pages/Home';
import Loading from './components/loading/Loading';


const router = createBrowserRouter([
    {
        path: `${Links.COIN_INFO}`,
        element: <>
        < Navigation /> < CoinInfo /> </>,
        errorElement: <>
         <Helmet>
            <title>Cryptocurrency Market Daily Data and Forecast | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/cryptocurrency-market-data/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Looking to stay up-to-date on the cryptocurrency market? Look no further than our daily data and forecast. Our reliable and accurate market analysis of real-time trends, empowering you to make informed and better investments. Don't let uncertainty hold you back - check our novel analysis now." />
            <meta property="og:title" content="Cryptocurrency Market Daily Data and Forecast | Coin Data Shake" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://coindatashake.com/cryptocurrency-market-data/" />
            <meta property="og:image" content="https://coindatashake.com/images/coin-info/coin-market-daily-data.webp" />
            <meta property="og:description" content="Looking to stay up-to-date on the cryptocurrency market? Look no further than our daily data and forecast. Our reliable and accurate market analysis of real-time trends, empowering you to make informed and better investments. Don't let uncertainty hold you back - check our novel analysis now." />
        </Helmet>
        <Loading />
        </>   
    }, {
        path: Links.HOME,
        element: <>< Navigation /> < Home /> </>,
        errorElement: <>
         <Helmet>
            <title>Cryptocurrency Market Tools and Articles | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Our cryptocurrency page offers a wide range of analytical tools and articles to help you navigate the fast-moving world of the cryptocurrency market. From technical analysis tools to statistical forecasts and moving average calculators. Explore our page today and take your trading to the next level." />
                <meta property="og:title" content="Cryptocurrency Market Tools and Articles | Coin Data Shake" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://coindatashake.com/" />
                <meta property="og:image" content="https://coindatashake.com/images/home/coin-data-shake-home.webp" />
                <meta property="og:description" content="Our cryptocurrency page offers a wide range of analytical tools and articles to help you navigate the fast-moving world of the cryptocurrency market. From technical analysis tools to statistical forecasts and moving average calculators. Explore our page today and take your trading to the next level." />
        </Helmet>
        <Loading />
        </>   
    },
    {
        path: Links.CRYPTO_WALLET,
        element: <>
            <Navigation />
            <CryptoWalletCorr />
        </>,
        errorElement: <>
        <PageNotFound />   
        <Navigation />
        </>   
    },
    {
        path: Links.MOVING_AVERAGE,
        element: <>
            <Navigation />
            <MovingAverage />
        </>,
        errorElement: <>
        <PageNotFound />   
        <Navigation />
        </>   
    },
    {
        path: Links.BLOG,
        element: <>
        <Navigation />
        <Blog />
    </>,
        errorElement: <>
        <PageNotFound />   
        <Navigation />
        </>   
    },
    {
        path: Links.BLOG + "/*",
        element: <>
        <Navigation />
        <BlogPost />
    </>,
        errorElement: <>
        <PageNotFound />   
        <Navigation />
        </>    
    },
    {
        path: Links.ABOUT_US,
        element: <>
        <Navigation />
        <AboutUs />
    </>,
    errorElement: <>
    <PageNotFound />   
    <Navigation />
    </> 
    },
    {
        path: Links.PRIVACY_POLICY,
        element: <>
        <Navigation />
        <PrivacyPolicy />
    </>,
    errorElement: <>
    <PageNotFound />   
    <Navigation />
    </> 
    },
    {
        path: Links.SITEMAP,
        element: <>
        <Navigation />
        <Sitemap />
            </>,
    errorElement: <>
    <PageNotFound />   
    <Navigation />
    </> 
    },
]);



const App = () => {
    return (
            <AppGlobal>
                <Content>
                    <HelmetProvider>
                            <RouterProvider router={router}/>
                            <CookieConsent />
                    </HelmetProvider>
                </Content>
            </AppGlobal>
    );
};

const Content = styled.main `
  min-height: 100vh;
`

const AppGlobal = styled.div `
  background-color: ${colors.bgcPrimary};
  color: ${colors.white};
  position: relative;
`

export default App;
