import React, { useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useMediaQuery} from "react-responsive";
import Slider from "./Slider";

const UndervaluedDay = ({cryptoNames, scrollPosition} : {
    cryptoNames: Array < any >;
    scrollPosition: number;
}) => {
    const cryptoSorted = cryptoNames;
    const cryptoList = useMemo(() => cryptoSorted.sort((a, b) => b.undervaluedDay - a.undervaluedDay).slice(0, 20), [cryptoSorted]);
    const bigScreenQuery = "(orientation: landscape) AND (min-width: 1000px)";
    const middleScreenQuery = "(min-width: 800px) AND (max-width: 999px)";
    const isBigScreen = useMediaQuery({query: bigScreenQuery});
    const isMiddleScreen = useMediaQuery({query: middleScreenQuery});
    const {formatMessage} = useIntl();
    const sectionRef = useRef(null);

    const slidesVisible = isBigScreen
        ? 3
        : isMiddleScreen
            ? 2
            : 1;

    const [sliderIndex,
        setSliderIndex] = useState(0);

    const [isVisible,
        setIsVisible] = useState(false);

        useEffect(() => {
            if (sectionRef.current) {
                const formPositionFromTop = (sectionRef.current as HTMLDivElement)?.offsetTop - 150;
                const isInScrollContext = scrollPosition >= formPositionFromTop
                if ((!isVisible && isInScrollContext)) {
                    setIsVisible(true);
                }
    
            }
    
        }, [isVisible, sectionRef, scrollPosition]);

    const postfix = "%";

    return <> <Slider
        sliderIndex={sliderIndex}
        isVisible={isVisible}
        slidesVisible={slidesVisible}
        setSliderIndex={setSliderIndex}
        cryptoList={cryptoList}
        title={formatMessage({id: 'undervaluedDay.title'})}
        label={formatMessage({id: 'undervaluedDay.label'})}
        valueName="undervaluedDay"
        postfix={postfix}
        forwardedRef={sectionRef}/>
        </>
}

export default UndervaluedDay;