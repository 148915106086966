import {observer} from "mobx-react";
import React, {useMemo, useRef, useEffect, useState} from "react";
import styled from "styled-components";
import {forecastData} from "../provider/forecastData";
import MaterialReactTable, {MRT_ColumnDef} from 'material-react-table';
import {FormattedMessage} from "react-intl";
import {CSVLink} from "react-csv";
import {colors} from "../assets/colors";
import {Links} from "../assets/links";
import { ChartContainer } from "./WeekForecast";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    scales
  } from 'chart.js';
import {Line} from 'react-chartjs-2';
import { useMediaQuery } from "react-responsive";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    scales
  );

ChartJS.defaults.color = colors.white;
ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Ubuntu, sans-serif';

interface MarketData {
    date : string | JSX.Element;
    price : number;
    volume : number;
}

const HistoricalData = observer(({currentCurrency, currentCoin}: {currentCurrency: string; currentCoin: string}) => {
    const data : Array < MarketData > = (forecastData.prices
        ?.slice(0, forecastData?.prices?.length - 1)
            ?.reverse() || []).map((price, index) => ({
        price: (price as any)[1],
        date: <time dateTime={new Date((price as any)[0]).toISOString()}>{new Date((price as any)[0]).toLocaleDateString()}</time>  ,
        volume: ((forecastData?.volumes
            ?.slice()
                ?.reverse() || [])[index] as any)[1]?.toFixed(0)
    }));

    const columns = useMemo < MRT_ColumnDef < MarketData > [] > (() => [
        {
            header: 'Date',
            accessorKey: 'date'
        }, {
            header: `Price (${currentCurrency
                ?.toUpperCase()})`,
            accessorKey: 'price'
        }, {
            header: 'Volume',
            accessorKey: 'volume'
        }
    ], [currentCurrency]);

    const dataCsv = useMemo(() => data
    ?.map((value, index) => ({
        Date: String((value.date as any).props.children) ,
        [`Price ${currentCurrency
                ?.toUpperCase()}`]: String(value.price),
        Volume: String(value.volume)
    })) || [], [data, currentCurrency]) 

    const startOfScientificNotation = 0.000001;
    const dotSign = '.';

    const tableRef = useRef(null);

    useEffect(() => {
        if (tableRef.current) {
            Object.values((tableRef.current as any).refs.tableHeadCellRefs.current as HTMLTableElement).forEach(el => el.querySelector("div[title]")?.removeAttribute("title"));
            (tableRef.current as any).refs.bottomToolbarRef.current.querySelector("input").setAttribute("aria-label", "Change number of items to display");
        }
    }, [tableRef]);
    const portraitQuery = "(orientation: portrait) and (max-width: 960px)";
    const isPortrait = useMediaQuery({query: portraitQuery});

    const labels = useMemo(() => data.slice(0, 20).reverse().map(item => (item.date as any).props.children), [data]);

    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            position: isPortrait ? 'bottom' : 'top' as const,
          },
          title: {
            display: false,
            text: 'Week forecast',
          },
        }
      }), [isPortrait]);

      const dataChart = useMemo(() => (
        {
            labels,
            datasets: [
              {
                label: `${(currentCoin as any)?.[0]?.toUpperCase() + currentCoin?.substring(1)} daily data in ${currentCurrency?.toUpperCase()}`,
                data: data.slice(0, 20).reverse().map(item => item.price),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              }
            ],
          }
    ), [data, currentCoin, currentCurrency, labels]);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 1050;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }
        }
    }, [isPostsVisible, postsRef, scrollPosition]);

    return <DataContainer id="marketData" ref={postsRef} isVisible={isPostsVisible}>
        <DataTitle>
            Market daily coin data
        </DataTitle>
        <ChartContainer>
            <Line options={options as any} data={dataChart} {...(isPortrait  ? {height: 300} : {})}/>
        </ChartContainer>
        {data[0] && data[0].price < startOfScientificNotation && <AdditionalText>
            <FormattedMessage id='historicalData.scientificNotation.info'/>
            <a href={Links.SCIENTIFIC_NOTATION} target="_blank"  rel="noreferrer">
                <FormattedMessage id='historicalData.scientificNotation.link'/>
            </a>
            {dotSign}
        </AdditionalText>}
        <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableSorting={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{
            hover: false
        }}
            tableInstanceRef={tableRef}/>
            
        <DownloadDescription>
        Cryptocurrency daily data and charts are important tools for investors and traders looking to stay up-to-date with the latest market trends and make informed investment decisions. The cryptocurrency market is highly volatile, with prices fluctuating rapidly in response to various events and news. By analyzing data, investors can gain insights into market trends and make informed decisions about buying and selling cryptocurrencies.
        </DownloadDescription>
        <DownloadDescription>
        Daily data and charts can also be used to track market sentiment and investor sentiment. The cryptocurrency market is highly influenced by investor sentiment, which can be affected by news, rumors, and other events. By tracking daily data and charts, investors can gain insights into market sentiment and use this information to make informed investment decisions.
        </DownloadDescription>
        <DownloadDescription>
        Overall, cryptocurrency daily data are essential tools for investors and traders looking to stay up-to-date with the latest market trends and make informed investment decisions. By analyzing daily data, investors can gain insights into market trends, track the performance of different cryptocurrencies, and identify potential buying or selling opportunities. In an ever-changing cryptocurrency market, daily market information are critical for staying ahead of the curve and making informed investment decisions.
        </DownloadDescription>
        <DownloadDescription>
        If you're a cryptocurrency investor or trader, you know how important it is to stay up-to-date with the latest market trends. One way to do this is by downloading cryptocurrency data in Excel format if you do it, you can easily manipulate and analyze the data to gain insights into market trends and make informed investment decisions.
        </DownloadDescription>
        <DownloadDescription>
        Downloading cryptocurrency data in Excel format is quick and easy. Once you download the data in Excel format, you can use a variety of tools and functions to analyze the market, including pivot tables, charts, and graphs. By downloading cryptocurrency data in Excel format, you can gain a deeper understanding of the cryptocurrency market and make more informed investment decisions. With access to this information, you can identify market trends, track the performance of different cryptocurrencies, and make informed decisions about buying and selling. Overall, downloading cryptocurrency data in Excel format is a simple yet effective way to stay ahead of the curve in the ever-changing cryptocurrency market.
        </DownloadDescription>
        <DownloadDescription>
            <FormattedMessage id="download.description"/>
        </DownloadDescription>
        <DownloadButton color={colors.textPrimary}>
            <CSVLink data={dataCsv} filename={`coin_data_shake_${currentCoin}_${currentCurrency}`} aria-label="Click this button to download data in csv format.">
                <FormattedMessage id="download.button"/>
            </CSVLink>
        </DownloadButton>
    </DataContainer>;
})

const AdditionalText = styled.p `
    margin-bottom: 1rem;
    font-style: italic;
`;

const DownloadDescription = styled.p `
    font-size: 1.3rem;
`;

export const DownloadButton = styled.div < {
    color: string
} > `
    margin-top: 2rem;
    &&& a, &&& span {
        color: ${colors.white} !important;
        display: block;
        width: 18rem;
        margin: 0 auto;
        text-align: center;
        line-height: 300%;
        background-color: ${props => props.color} !important;
        border-radius: 4px;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 1px;
        position: relative;
        overflow: hidden;
        transition: 0.4s linear;
        font-size: 1.5rem;
        @media (max-width: 980px) {
            font-size: 2rem;
            line-height: 200%;
            width: 85%;
        };
        @media(orientation: portrait) {
            font-size: 1.8rem!important;
        };
    }

    @media (min-width: 800px) {
        &&& a::after, &&& span::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 100%;
            bottom: 0;
            background-color: #000;
            opacity: 0.03;
            transition: 0.6s linear;
        }
    
        &&& a:hover::after, &&& span:hover::after {
            right: 0;
            transform: translateY(0);
            border-radius: 0 0 0 0;
            transition: 0.4s linear;
        }
    }
   
`;

export const DataTitle = styled.h2 `
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 2rem!important;
`

export const DataContainer = styled.section< {
    isVisible?: boolean
} > `
    margin: 0 auto;
    padding-bottom: 5rem;
    opacity: ${props => props.isVisible
        ? '1'
        : '0'};
    transition: 0.5s;

    & p {
        margin-top: 2rem;
    }

    &:last-of-type {
        padding-bottom: 10rem
    }

    width: 60vw;

    @media (max-width: 750px) {
        width: 90%;
    }

    & * {
        background-color: ${colors.transparent} !important;
        color: ${colors.white} !important;
    }

    & div.Mui-TableHeadCell-Content-Wrapper {
        font-size: 1.5rem;
    }

    & td {
        font-size: 1.4rem;
    }
    & .MuiTablePagination-root * {
        font-size: 1.2rem!important;
    }

    & .MuiToolbar-root {
        font-size: 1.4rem;

    }

    & .Mui-TableHeadCell-Content-Wrapper {
        color:  ${colors.textPrimary} !important;
    }

    & table {
        border-top: 1px solid #222;
    }

    & a {
        color: ${colors.textPrimary}!important;
        margin-left: 0.3rem;
    }
`

export default HistoricalData;