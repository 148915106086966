import {observer} from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import {bitcoinData} from '../../provider/bitcoinData';
import {DataContainer, DataTitle} from '../HistoricalData';

const LinksSection = observer(() => {
    const createResources = () => {
        const links = bitcoinData.links;
        const items = Object
            .entries(links)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    const filteredValue = value.filter(item => item !== "");
                    if (!filteredValue.length) {
                        return null;
                    }
                    
                    const arrayToString = filteredValue.map(link => {
                        return <a href={link as any} target="_blank" rel="noreferrer" key={link} className="u-url" itemProp="mainEntityOfPage">{link as any}</a>
                    });
                    return <ResourcesListItem key={key}>
                    <p>
                        <Label><FormattedMessage id={key} /></Label>
                        {arrayToString}
                    </p>
                </ResourcesListItem>
                }

                if (typeof value === 'string' && !value.startsWith('http')) {
                    return null;
                }


                if (typeof value === 'object') {
                    if (value === null || value === undefined) {
                        return null;
                    }
                    const listOfItems: Array<any> = [];
                    Object.entries(value).forEach(([key, itemValue]) => {
                        const filteredValue = itemValue.filter((item: string) => item !== "");
                        if (!filteredValue.length) {
                            return null;
                        }

                        const arrayToString = filteredValue.map((link: string) => {
                            return <a href={link as any} target="_blank" rel="noreferrer" key={link} className="u-url" itemProp="mainEntityOfPage">{link as any}</a>
                        });
                        listOfItems.push(<ResourcesListItem key={key}>
                            <p>
                                <Label><FormattedMessage id={key} /></Label>
                                {arrayToString}
                            </p>
                        </ResourcesListItem>)
                    })

                    return listOfItems;
                }

                return value && <ResourcesListItem key={key}>
                    <p>
                        <Label><FormattedMessage id={key} /></Label>
                        <a href={value as any} target="_blank" rel="noreferrer" className="u-url" itemProp="mainEntityOfPage">{value as any}</a>
                    </p>
                </ResourcesListItem>;
            });
        return <ResourcesList>{items as any}</ResourcesList>
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 1050;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }
        }
    }, [isPostsVisible, postsRef, scrollPosition]);


    return <DataContainer id="resources" style={{paddingBottom: "3rem", marginTop: "-5rem"}} ref={postsRef} isVisible={isPostsVisible}>
        <DataTitle>Coin and market resources</DataTitle>
        {createResources()}
    </DataContainer>
});

const ResourcesList = styled.ul `
    list-style: none;
    padding-left: 1rem;
    @media (max-width: 960px) {
            & li {
                margin-bottom: 1rem;
                line-height: 160%!important;
            }
       } 
`;

const ResourcesListItem = styled.li `
    & p {
        font-size: 1.2rem;
        word-break: break-all;
        overflow-wrap: break-word;
        font-weight: bold;
        margin-top: 2rem;
        text-align: center;

    }
    
    & span {
        line-height: 150%;
    }

    & a::after {
        content: ' ';
        display: inline;
        margin-right: 0.5rem;
    }
    
    &&& a[target] {
        @media (max-width: 980px) {
            color: ${colors.white} !important;
        }
    }
    & a {
        display: block;
        line-height: 200%;
    }
`;

const Label = styled.span `
    margin-right: 0.5rem;
`;

export default LinksSection;