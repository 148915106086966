import React, { useEffect, useState} from "react";
import CoinDetails from "../components/CoinDetails";
import Header from "../components/Header";
import HistoricalData from "../components/HistoricalData";
import WeekForecast from "../components/WeekForecast";
import {observer} from "mobx-react"
import {bitcoinData} from "../provider/bitcoinData";
import {forecastData} from "../provider/forecastData";
import {useLocation} from 'react-router-dom'
import Footer from "../components/Footer";
import LinksSection from "../components/linksSection/LinksSection";
import { HomeForm } from "../components/homeForm/HomeForm";
import {currencyNames} from "../assets/currencyNames";
import styled from "styled-components";
import Loading from "../components/loading/Loading";
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga';

const CoinInfo = observer(() => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[])

    const location = useLocation();
    const { currency, coin } = location.state || {coin: 'bitcoin', currency: 'usd'};
    const [crypto,
        setCrypto] = useState(coin);
    const [cryptoError,
        setCryptoError] = useState(false);
    const [currentCurrency,
        setCurrentCurrency] = useState(currency);
    const [currencyList,
        setCurrencyList] = useState([]);
    const [currencyError,
        setCurrencyError] = useState(false);
    const [cryptoNames,
        setCryptoNames] = useState([]);

    const [mainContentLoaded, setLoaded] = useState(false);

        useEffect(() => {
            if (mainContentLoaded && !cryptoNames.length && !currencyList.length) {
                const allCryptoNames = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd';
                const allAvailableCurrency = 'https://api.coingecko.com/api/v3/simple/supported_vs_currencies';
        
                const cryptoErrorMessage = "Cant read currency names";
                fetch(allCryptoNames)
                    .then(res => res.json())
                    .then(data => setCryptoNames(data.map((item : any) => ({id: item.id, value: item.name, image: item.image, marketCap: item.market_cap, priceChange: item.price_change_percentage_24h, toMax: ((item.ath / item.current_price) * 100).toFixed(2),
                    undervaluedDay: ((item.high_24h / item.current_price) * 100).toFixed(2)
                }))))
                    .catch(err => console.log(err + cryptoErrorMessage));
        
                const currencyErrorMessage = "Cant read currency names";
                fetch(allAvailableCurrency)
                    .then(res => res.json())
                    .then(data => setCurrencyList(data.map((item : any) => ({
                        id: item,
                        value: item,
                        description: (currencyNames as any)[item] ?? item
                    }))))
                    .catch(err => console.log(err + currencyErrorMessage));
            }
        }, [mainContentLoaded, cryptoNames, currencyList]);

    const defaultCrypto = 'bitcoin';
    const defaultCurrency = 'usd';

    useEffect(() => {
        bitcoinData.setIsError(false);
        bitcoinData.fetchData(crypto || defaultCrypto, currentCurrency || defaultCurrency, setLoaded);
        forecastData.fetchData(crypto || defaultCrypto, currentCurrency || defaultCurrency);
    }, [crypto, currentCurrency]);


    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, []);

    return <>
         <Helmet>
            <title>Cryptocurrency Market Daily Data and Forecast | Coin Data Shake</title>
            <link rel="canonical" href="https://coindatashake.com/cryptocurrency-market-data/" />
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Looking to stay up-to-date on the cryptocurrency market? Look no further than our daily data and forecast. Our reliable and accurate market analysis of real-time trends, empowering you to make informed and better investments. Don't let uncertainty hold you back - check our novel analysis now." />
            <meta property="og:title" content="Cryptocurrency Market Daily Data and Forecast | Coin Data Shake" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://coindatashake.com/cryptocurrency-market-data/" />
            <meta property="og:image" content="https://coindatashake.com/images/coin-info/coin-market-daily-data.webp" />
            <meta property="og:description" content="Looking to stay up-to-date on the cryptocurrency market? Look no further than our daily data and forecast. Our reliable and accurate market analysis of real-time trends, empowering you to make informed and better investments. Don't let uncertainty hold you back - check our novel analysis now." />
        </Helmet>
        { mainContentLoaded ? <Container className="hCard" itemScope itemType="http://schema.org/Article">
        <Header currentCurrency={currentCurrency}/>
        <HomeForm
            crypto={crypto}
            currency={currentCurrency}
            cryptoError={cryptoError}
            currencyError={currencyError}
            cryptoNames={cryptoNames}
            currencyList={currencyList}
            setCrypto={setCrypto}
            setCryptoError={setCryptoError}
            setCurrency={setCurrentCurrency}
            setCurrencyError={setCurrencyError}
            scrollPosition={scrollPosition} 
            isCoinInfo/>
        <CoinDetails />
        <div itemProp="articleBody" className="e-content">
            <HistoricalData currentCurrency={currentCurrency} currentCoin={crypto} />
            <WeekForecast currentCurrency={currentCurrency} currentCoin={crypto} />
        </div>
        <LinksSection />
        <Footer />
        </Container> : <Loading />  }
    </>
});

const Container = styled.div`
    & section:nth-of-type(2) {
        padding-top: 1rem;
    }
`;

export default CoinInfo;