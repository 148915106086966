import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Links } from '../../assets/links';

const CompanyName = () => {
    const name = 'Coin Data Shake';
    const smallScreenQuery = "(max-width: 1000px)";
    const isSmallScreen = useMediaQuery({query: smallScreenQuery});
    const separator = isSmallScreen ? '' : '| ';

    return <Container to={Links.HOME}>{`${name} ${separator}`}</Container>
};

const Container = styled(Link)`
    color: ${colors.white}!important;
    @media(max-width: 1000px) {
        display: block;
    }
`;

export default CompanyName;