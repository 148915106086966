import { blog } from "./blog";
import { coinInfo } from "./coinInfo";
import { correlationPage } from "./correlationPage";
import { errorPages } from "./errorPages";
import { externalLinks } from "./externalLinks";
import { homeTexts } from "./homeTexts";
import { navigation } from "./navigation";

export const texts = {
    ...errorPages,
    ...homeTexts,
    ...coinInfo,
    ...externalLinks,
    ...correlationPage,
    ...navigation,
    ...blog,
    'coinName': 'Cryptocurrency:',
    'date': 'Start date:',
    'symbol': 'Market symbol:',
    'priceTitle': 'Current price',
    'currency': 'USD',
    'twitter_followers': 'Twitter followers:',
    'reddit_average_posts_48h': 'Reddit average posts (48h):',
    'reddit_average_comments_48h': 'Reddit average comments (48h):',
    'reddit_subscribers': 'Reddit subscribers:',
    'reddit_accounts_active_48h': 'Reddit accounts active (48h):',
    'telegram_channel_user_count': 'Telegram channel user count:',
    'developmentLabel': 'Number of commits in last 4 weeks:',
    'forks': 'Forks:',
    'stars': 'Stars:',
    'subscribers': 'Subscribers:',
    'total_issues': 'Total issues:',
    'closed_issues': 'Closed issues:',
    'pull_requests_merged': 'Pull requests merged:',
    'pull_request_contributors': 'Pull request contributors:',
    'commit_count_4_weeks': 'Commit count 4 weeks:',
    'updated': 'Last update ',
    'api': 'Powered by CoinGecko API',
    'historicalData': 'Daily Data',
    'forecastDataAutoArima': 'Week Forecast (Auto-ARIMA)',
    'forecastDataAutoArimaDescription': 'Auto ARIMA (Auto-Regressive Integrated Moving Average) forecast is a popular time-series analysis technique used for forecasting future values of a variable based on its past behavior. It is an automated way of selecting the best ARIMA model for a time-series dataset by identifying the optimal values for the parameters of the ARIMA model. The ARIMA model is composed of three main parameters: p, d, and q. These parameters determine the order of the autoregressive (AR), integrated (I), and moving average (MA) terms of the model, respectively. The order of these terms can have a significant impact on the accuracy of the forecast, making it essential to select the best parameters for the model. ',
    'forecastDataAutoArimaDescription2': 'Auto ARIMA is a technique that automates the process of selecting the optimal values for the p, d, and q parameters by analyzing the autocorrelation and partial autocorrelation functions of the time-series data. It uses a stepwise approach to identify the best model by testing various combinations of p, d, and q values and selecting the one that has the lowest Akaike Information Criterion (AIC) score. The AIC score is a measure of the quality of a statistical model that takes into account both its goodness of fit and the number of parameters used in the model. The model with the lowest AIC score is considered the best-fit model and is selected as the final Auto ARIMA model.',
    'forecastDataAutoArimaDescription3':'Auto ARIMA has several advantages over traditional manual ARIMA modeling. Firstly, it eliminates the need for manual selection of the best ARIMA model, which can be a time-consuming and challenging task, especially for large datasets. Secondly, it provides a more objective and automated approach to time-series analysis, which can reduce the potential for human errors and biases. Furthermore, Auto ARIMA can handle non-stationary time-series data, which are time-series datasets that exhibit trends or seasonal patterns. These datasets can be challenging to model using traditional ARIMA techniques, as they require the use of differencing to make the data stationary before modeling. Auto ARIMA automatically detects and incorporates differencing into the model selection process, making it a powerful tool for modeling non-stationary time-series data.',
    'forecastDataAutoArimaDescription4': 'However, there are also some limitations to the use of Auto ARIMA. Firstly, it assumes that the time-series data is stationary, which may not always be the case. If the data is non-stationary, additional pre-processing steps, such as differencing, may be required before using Auto ARIMA. Secondly, Auto ARIMA may not always produce the most accurate forecast, especially for datasets with irregular patterns or outliers. In these cases, manual intervention and adjustment of the model parameters may be necessary to improve the accuracy of the forecast.',
    'forecastDataAutoArimaDescription5': 'In conclusion, Auto ARIMA is a powerful and efficient time-series analysis technique that can automate the process of selecting the best ARIMA model for a time-series dataset. It provides an objective and automated approach to time-series analysis and can handle non-stationary data. However, it also has some limitations, and manual intervention may be necessary in some cases to improve the accuracy of the forecast. Overall, Auto ARIMA is a valuable tool for time-series analysis and forecasting, especially for large datasets with complex patterns.',
    'forecastWarning': 'This forecast is made only for educational purpose, every predicted value has forecast model error and are not suitable for investment decisions.',
    'download.button': 'Download data',
    'download.description': "You can download daily cryptocurrency data by clicking the above button. Data are in csv format (you can open it in e.g. Excel application). Data contain information about daily price and volume of asset in USD. You can use generated file as you prefer, modify it, make analysis or use it as input to other applications. Volume value 0 means that for described period we don't have information about volume, we can replace it with N/A value."
}