import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faClose} from '@fortawesome/free-solid-svg-icons'
import {faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons'
import gsap from "gsap";
import { useLocation, Link as MainLink } from 'react-router-dom';
import { Links } from '../../assets/links';
import { useMediaQuery } from 'react-responsive';
import InnerNavigation, { Details, LinksHeader, MenuList, MenuListItem } from './InnerNavigation';
import Logo from '../logo/Logo';

const Navigation = () => {
    const [isNavigationVisible, setIsNavigationVisible] = useState(false);
    const navigationRef = useRef(null);
    const isLandscape = useMediaQuery({query: '(max-width: 980px) and (orientation: landscape)'});
    const isPortrait = useMediaQuery({query: '(orientation: portrait)'});
    const isSmall =  useMediaQuery({query: '(min-width: 980px)'});
    
    useEffect(()=> {
        if (isNavigationVisible && navigationRef.current) {
            const timeline = gsap.timeline();
            timeline.to(navigationRef.current, {
                duration: 1,
                left: '0',
                ease: 'power3'
            });
        } else if(!isNavigationVisible && navigationRef.current) {
            const timeline = gsap.timeline();
            timeline.to(navigationRef.current, {
                duration: 1,
                left: isLandscape ? 'calc(-80vw - 25px)' : isPortrait ? 'calc(-80vw - 25px)' : '-32vw',
                ease: 'power3'
            });
        }
    },[isNavigationVisible, navigationRef, isLandscape, isPortrait]);

    const location = useLocation();
    const currentPath = location.pathname.includes(Links.COIN_INFO) ? Links.COIN_INFO : location.pathname;
    const isBlogPost = location.pathname.includes('blog/');

    const createBreadCrumb = () => {
        return {
            [Links.COIN_INFO]: <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><span itemProp="name" className='p-name'>Coin Info</span><meta itemProp="position" content="2" />
            </BreadCrumb>,
            [Links.CRYPTO_WALLET]: <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><span itemProp="name" className='p-name'>Wallet Correlation</span><meta itemProp="position" content="2" /></BreadCrumb>,
            [Links.MOVING_AVERAGE]: <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><span itemProp="name" className='p-name'>Moving Average</span><meta itemProp="position" content="2" /></BreadCrumb>,
            [Links.BLOG]: <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><span itemProp="name" className='p-name'>Blog</span><meta itemProp="position" content="2" /></BreadCrumb>,
            [Links.ABOUT_US]: <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><span itemProp="name" className='p-name'>About Us</span><meta itemProp="position" content="2" /></BreadCrumb>,
            [Links.PRIVACY_POLICY]: <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><span itemProp="name" className='p-name'>Privacy Policy</span><meta itemProp="position" content="2" /></BreadCrumb>,
            [Links.SITEMAP]: <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><span itemProp="name" className='p-name'>Sitemap</span><meta itemProp="position" content="2" /></BreadCrumb>
        }[currentPath]
    }

    return <>
    {!isNavigationVisible && <NavigationButton tabIndex={0} type='button' aria-label='Click to open navigation menu' onClick={() => setIsNavigationVisible(true)}>
        <FontAwesomeIcon icon={faBars} color={colors.black}/>
    </NavigationButton> }
   
    <NavigationContainer ref={navigationRef} className="h-navigation breadcrumb">
       <BreadCrumbs className="breadcrumb__list" itemScope itemType="https://schema.org/BreadcrumbList" aria-label="breadcrumb">
       <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
        <MainLink className="breadcrumb__link" itemProp="item" to={Links.HOME}>
            <span itemProp="name" className='p-name'>Home</span>
        </MainLink>
        <meta itemProp="position" content="1" />
       </BreadCrumb>
        {createBreadCrumb()}
        {isBlogPost && <>
        <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
        <MainLink to={Links.BLOG} className="breadcrumb__link" itemProp="item">
            <span itemProp="name" className='p-name'>Blog</span>
        </MainLink>
        <meta itemProp="position" content="2" />
        </BreadCrumb>
        <BreadCrumb className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <span itemProp="name" className='p-name'>Post</span>
            <meta itemProp="position" content="3" />
        </BreadCrumb></> }
       </BreadCrumbs>
       <InnerNavigation />
       {isSmall && <Horizontal />}
       <Details  className="h-navigation" itemScope itemType="https://schema.org/SiteNavigationElement">
            <summary> <LinksHeader className="p-name" itemProp="name">Investing Tools</LinksHeader></summary>
            <MenuList className="h-navigation__list">
       <MenuListItem className="h-navigation__item"><MainLink itemProp="url" className="h-navigation__link" tabIndex={0} to={Links.CRYPTO_WALLET} onClick={() => setIsNavigationVisible(false)}><span className="p-name">Crypto wallet correlation</span></MainLink></MenuListItem>
       <MenuListItem className="h-navigation__item"><MainLink itemProp="url" className="h-navigation__link" tabIndex={0} to={Links.MOVING_AVERAGE} onClick={() => setIsNavigationVisible(false)}><span className="p-name">Moving average calculator</span></MainLink></MenuListItem>
       </MenuList>  
       </Details>
       {isSmall && <Horizontal />}
       <Details className="h-navigation" itemScope itemType="https://schema.org/SiteNavigationElement">
            <summary> <LinksHeader className="p-name" itemProp="name">Main pages</LinksHeader></summary>
            <MenuList className="h-navigation__list">
            <MenuListItem className="h-navigation__item"><MainLink itemProp="url" className="h-navigation__link" tabIndex={0} to={Links.HOME} onClick={() => setIsNavigationVisible(false)}><span className="p-name">Home</span></MainLink></MenuListItem>
       <MenuListItem className="h-navigation__item"><MainLink itemProp="url" className="h-navigation__link" tabIndex={0} to={Links.BLOG} onClick={() => setIsNavigationVisible(false)}><span className="p-name">Blog</span></MainLink></MenuListItem>
       <MenuListItem className="h-navigation__item"><MainLink itemProp="url" className="h-navigation__link" tabIndex={0} to={Links.ABOUT_US} onClick={() => setIsNavigationVisible(false)}><span className="p-name">About us</span></MainLink></MenuListItem>
       <MenuListItem className="h-navigation__item"><MainLink itemProp="url" className="h-navigation__link" tabIndex={0} to={Links.PRIVACY_POLICY} onClick={() => setIsNavigationVisible(false)}><span className="p-name">Privacy policy</span></MainLink></MenuListItem>
       <MenuListItem className="h-navigation__item"><MainLink itemProp="url" className="h-navigation__link" tabIndex={0} to={Links.SITEMAP} onClick={() => setIsNavigationVisible(false)}><span className="p-name">Sitemap</span></MainLink></MenuListItem>
       </MenuList>
        
       </Details>
       {isSmall && <Horizontal />}

       {isNavigationVisible &&  <CloseButton tabIndex={0} type="button" onClick={() => setIsNavigationVisible(false)} aria-label='Click to close navigation menu'>
            <FontAwesomeIcon icon={faClose} color={colors.black}/>
        </CloseButton>}

        <Contact itemScope itemType="http://schema.org/Organization">

    <LogoContainer>
        <Logo isNavigation />
    </LogoContainer>
    <Company itemProp="name">
        Coin Data Shake
    </Company>
    <Company itemProp="email">
        contact@coindatashake.com
    </Company>
    <Company>
        &copy; 2023 - All rights reserved
    </Company>
    <SocialMediaIcons>
        <a href="https://www.instagram.com/coindatashakecrypto" target='_blank' rel="noreferrer" aria-label="Visit our instagram page by clicking this icon.">
            <FontAwesomeIcon icon={faInstagram} color="#F56040" fontSize="2.2rem" />
        </a>
        <a href="https://www.facebook.com/coindatashake" target='_blank' rel="noreferrer" aria-label="Visit our facebook page by clicking this icon.">
            <FontAwesomeIcon icon={faFacebook} color="#1877f2" fontSize="2rem" />
        </a>
    </SocialMediaIcons>
    </Contact>

    </NavigationContainer>
    </>;
};

const SocialMediaIcons = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    gap: 15px;
    flex-wrap: wrap;
`;

const Horizontal = styled.div`
    height: 1px;
    background-color: ${colors.black};
    width: 0;
    margin: 0 auto;
    margin-top: -1rem;
    margin-bottom: 1rem;
    transition: 0.8s;
`;

const Contact = styled.div`
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 1px 2px 14px 3px rgba(0,0,0,0.09);
-webkit-box-shadow: 1px 2px 14px 3px rgba(0,0,0,0.09);
-moz-box-shadow: 1px 2px 14px 3px rgba(0,0,0,0.09);
    margin-bottom: 2rem;
    margin-top: 3rem;
`;

const LogoContainer = styled.div`
    margin: 0 auto 0 auto;
    padding-left: 1rem;
    max-width: 35%;
`;

const Company = styled.p`
margin-top: 1rem;
    &:first-of-type {
        margin-top: 0rem;
    } 
padding: 1rem 1rem;
border: 1px solid ${colors.black};
text-align: center;
transition: 1s;
border-radius: 5px;
font-weight: 400;
font-size: 1.1rem;
@media (max-width: 380px) {
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    &:nth-of-type(2) {
        font-size: 1rem;
    }  
}
`;

const BreadCrumbs = styled.h2`
    position: absolute;
    width: 75%;
    top: 1.8rem;
    left: 1rem;
    color: ${colors.black};
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-left: 0.5rem;
    font-weight: normal;
    border-bottom: 1px solid black;
    padding-bottom: 1rem;
`;

const BreadCrumb = styled.span`
    &:not(:first-of-type)::before {
        content: '-';
        display: inline;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }
`;

export const CloseButton = styled.button`
    display: block;
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: 1rem;
    right: 1rem;
    background-color: ${colors.transparent};
    border: none;
    outline: none;
    cursor: pointer;

    &:focus {
        outline: 5px solid ${colors.textPrimary}
    }
  
    overflow: hidden;
    & svg {
        width: 100%;
        height: 100%;
    }
`;

const NavigationContainer = styled.nav`
    position: fixed;
    left: -82vw;
    @media (min-width: 980px) {
        left: -32vw;
    }
    top: 0;
    bottom: 0;
    width: 30vw;
    padding-top: 7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    color: ${colors.black};
    background-color: ${colors.white};
    z-index: 100;
    box-shadow: 11px 0 25px -6px rgba(0,0,0,0.68);
    -webkit-box-shadow: 11px 0 25px -6px rgba(0,0,0,0.68);
    -moz-box-shadow: 11px 0 25px -6px rgba(0,0,0,0.68);


    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
    display: none;
    }

    /* Hide scrollbar for Firefox and Edge */
    scrollbar-width: none;
    -ms-overflow-style: none;
    
    overflow-y: scroll;



    @media (max-width: 980px) {
        // width: 50vw;
        width: 80vw;
    }
    @media (orientation: portrait) {
        width: 80vw;
    }
`;

const NavigationButton = styled.button`
    display: block;
    position: fixed;
    top: 2rem;
    left: 4rem;
    width: 3.5rem;
    height: 3.5rem;
    background-color: ${colors.white};
    padding: 0.5rem 0;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 100;
    box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.6);
-webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.6);
-moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.6);
    &:focus {
        outline: 5px solid ${colors.textPrimary}
    }

    @media (max-width: 980px) {
        left: 1.5rem;
    }
    
    & svg {
        width: 100%;
        height: 100%;
    }
`;

export default Navigation;