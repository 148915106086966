export function predictTimeSeries(data: number[], numPredictions: number): number[] {
    const predictions: number[] = [];
  
    // Calculate the mean of the time series data
    const mean = data.reduce((acc, val) => acc + val, 0) / data.length;
  
    // Calculate the variance and standard deviation of the time series data
    const variance = data.reduce((acc, val) => acc + (val - mean) ** 2, 0) / (data.length - 1);
  
    // Calculate the autocorrelation coefficients
    const autocorrelations: number[] = [];
    for (let lag = 0; lag < data.length; lag++) {
      const sum = data.slice(lag).reduce((acc, val, index) => acc + (val - mean) * (data[index] - mean), 0);
      autocorrelations.push(sum / ((data.length - lag) * variance));
    }
  
    // Apply the simple forecasting formula to predict future values
    for (let i = 1; i <= numPredictions; i++) {
      const nextValue = mean + autocorrelations.reduce((acc, val, index) => acc + val * (data[data.length - index - 1] - mean), 0);
      predictions.push(nextValue);
      data.push(nextValue);
    }
  
    return predictions;
  }