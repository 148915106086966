import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { colors } from '../assets/colors';
import { Links } from '../assets/links';
import { btcVsEth } from '../blog/posts/btcVsEth';
import { cryptoTaxes } from '../blog/posts/cryptoTaxes';
import { decentralizedFin } from '../blog/posts/decentralizedFin';
import { riseOfNft } from '../blog/posts/riesOfNft';
import { stakingCrypto } from '../blog/posts/stakingCrypto';
import { top5Altcoins } from '../blog/posts/top5Altcoins';
import { HeaderContainer, HeaderTitle } from '../components/Header';
import { Motto, SectionContainer, SectionTitle } from './CryptoWalletCorr';
import {useMediaQuery} from 'react-responsive';
import OtherPosts from '../blog/components/OtherPosts';
import { BlogContainer, Container, PostTitle } from './Blog';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Post as ToolPost } from './Blog';
import PageNotFound from '../components/errors/PageNotFound';
import CompanyName from '../components/companyName/CompanyName';
import Loading from '../components/loading/Loading';
import ReactGA from 'react-ga';

const BlogPost = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const location = useLocation();
    const postUrl = location.pathname.replace("/blog/", "");
    const posts = useMemo(() => [
        btcVsEth,
        cryptoTaxes,
        decentralizedFin,
        riseOfNft,
        stakingCrypto,
        top5Altcoins
    ], []);

    const currentPost = posts.find(post => postUrl.includes(post.link));

    const isSmallQuery = "(max-width: 960px)";
    const isSmallScreen = useMediaQuery({query: isSmallQuery});

    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, [currentPost]);

    const canonicalLink = "https://coindatashake.com" + Links.BLOG + "/" + currentPost?.link;

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }
        }
    }, [isPostsVisible, postsRef, scrollPosition]);

    const [isToolsVisible,
        setIsToolsVisible] = useState(false);
    
    const toolsRef = useRef(null);

    useEffect(() => {
        if (toolsRef.current) {
            const formPositionFromTop = (toolsRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isToolsVisible && isInScrollContext)) {
                setIsToolsVisible(true);
            }
        }
    }, [isToolsVisible, toolsRef, scrollPosition]);

    const [sliderIndex,
        setSliderIndex] = useState(0);

    useEffect(() => {
        if (isNaN(sliderIndex) || sliderIndex < 0){
            setSliderIndex(0);
        }
    },[sliderIndex]);

    return currentPost ? isLoading ? <Loading/> : <>
     <Helmet>
            <title> {currentPost?.title} | Coin Data Shake</title>
            <link rel="canonical" href={canonicalLink} />
            <meta name="robots" content="index, follow" />
            <meta name="description" content={(currentPost as any)?.metaDescription} />
            <meta property="og:title" content={`${currentPost?.title} | Coin Data Shake`} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalLink} />
            <meta property="og:image" content={"https://coindatashake.com" + currentPost?.mainImage} />
            <meta property="og:description" content={(currentPost as any)?.metaDescription} />
        </Helmet>
    <BlogContainer className="h-entry" itemScope itemType='https://schema.org/Article'>
     <HeaderContainer>
        <HeaderTitle className="p-name" itemProp="headline"><CompanyName />{currentPost?.title}</HeaderTitle>
        <Motto><strong><Link to={Links.BLOG}>Read our blog for the latest updates</Link></strong></Motto>
    </HeaderContainer>
    <Post className="e-content" itemProp="articleBody">
        <SectionContainer ref={postsRef} isVisible={isPostsVisible}>
            <MainImageContainer>
            <MainImage className="u-featured" itemProp="image" src={isSmallScreen ? currentPost?.mainImage : currentPost?.mainImage.replace("Sm", "Md")} alt={currentPost?.alt} onLoad={() => setIsLoaded(true)}/>
            </MainImageContainer>
          
            {isLoaded && <>
                {(currentPost as any)?.content.map((item: any, index: number) => <Fragment key={index}>{item}</Fragment>)}
            <Resources>Resources</Resources>
            <ResourcesList>
                {(currentPost as any)?.resources.map((item: any) => <li key={item}><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={item} target="_blank" rel="noreferrer">{item}</WhiteLink></li>)}
            </ResourcesList>
            <Resources>Author</Resources>
            <Author><span className="p-author h-card" itemProp="author" itemScope itemType="http://schema.org/Organization"><Link to={Links.ABOUT_US} itemProp="url" className="u-url p-name">Coin Data Shake</Link></span> - click on the author to see more information.</Author>
            </>}
        </SectionContainer>
    </Post>
    </BlogContainer>
    {isLoaded && <>
        <OtherPosts currentPost={currentPost} posts={posts} sliderIndex={sliderIndex} setSliderIndex={setSliderIndex} />
    <SectionContainer id="toolsLinks" ref={toolsRef} isVisible={isToolsVisible}>
        <SectionTitle>Check our cryptocurrency unique tools</SectionTitle>
        <Container>
        <ToolPost className="h-entry maxWidth" itemScope itemType='https://schema.org/Article'>
            <Link to={Links.CRYPTO_WALLET} className="u-url" itemProp="url">
                <PostTitle className="p-name" itemProp="headline">Cryptocurrency wallet correlation analysis</PostTitle>
                <ImgContainer>
                    <Img src="/images/wallet-correlation/crypto-wallet-correlation.webp" alt="On the image you can see a our form to generate cryptocurrency wallet" className="u-photo" itemProp="image"/>
                </ImgContainer>
                <p className="e-content" itemProp="articleBody">
                    <FormattedMessage id="correlationTool.description" />
                </p>
            </Link>
        </ToolPost>
        <ToolPost className="h-entry maxWidth" itemScope itemType='https://schema.org/Article'>
            <Link to={Links.MOVING_AVERAGE} onClick={() => setIsLoading(true)} className="u-url" itemProp="url">
                <PostTitle className="p-name" itemProp="headline">Moving average cryptocurrency analysis</PostTitle>
                <ImgContainer>
                    <Img src="/images/ma/coin-moving-average.webp" alt="On the image you can see a chart with results of moving average calculation" className="u-photo" itemProp="image" />
                </ImgContainer>
                <p className="e-content" itemProp="articleBody">
                    <FormattedMessage id="movingAverage.description" />
                </p>
            </Link>
        </ToolPost>
        </Container>
    </SectionContainer>
    </>}
    
    </> : <PageNotFound />;
};

export const ResourcesList = styled.ul`
    & li {
        word-break: break-all;
        overflow-wrap: break-word;
    }

    @media (max-width: 960px) {
        margin-left: 2rem!important;
            & li {
                margin-bottom: 1rem;
                line-height: 130%!important;
            }
    } 
`;

export const Author = styled.p`
    color: ${colors.white};
    font-size: 1rem!important;
    
    & a {
        color: ${colors.white}!important;
        font-size: 1rem;
        font-weight: bold;
    }
`;


export const WhiteLink = styled.a`
    color: ${colors.white}!important;
`;

export const Img = styled.img`
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
`;

export const ImgContainer = styled.div`
    overflow: hidden;
    margin-bottom: 1rem;
`

export const Resources = styled.h3`
    & + ul {
        list-style: none;
    }
`;

const Post = styled.div`
    & h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    & p {
        font-size: 1.4rem;
        line-height: 140%;
        word-wrap: break-word;
    }

    
    
    & h3, & p + p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    & ul {
        margin-left: 4rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        & li {
            line-height: 150%;
        }
    }
`;

const MainImageContainer = styled.div`
    width: 70%;
    @media (max-width: 500px) {
        width: 100%;
    }
    margin-bottom: 1rem;
    @media (max-width: 960px) {
        margin-bottom: 3rem;
    }
    
    float: left;
    margin-right: 2rem;
`;

const MainImage = styled.img`
    border: 4px solid ${colors.white};
    width: 100%;
   

    border-radius: 20px;
    
    margin: 0 auto;
    display: block;
    aspect-ratio: 16 / 9;
    &+h2 {
        margin-top: 2rem;
    }
`;

export default BlogPost;