import {observer} from "mobx-react";
import React from "react";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {colors} from "../assets/colors";
import {Links} from "../assets/links";
import {bitcoinData} from "../provider/bitcoinData";

const Footer = observer(({lastUpdated}:{lastUpdated?: string}) => {
const dateExist = lastUpdated || bitcoinData.updatedAt;
const dateObject =  dateExist && new Date(dateExist)
return <FooterContainer>
        <FooterText>
            <FormattedMessage id="updated"/>
            {dateObject && <time itemProp="dateModified" className="dt-updated" dateTime={dateObject.toISOString()}>{dateObject.toLocaleString()}</time>}     
        </FooterText>
        <p>
            <ApiLink href={Links.COINGECKO} target="_blank" rel="noreferrer">
                <FormattedMessage id="api"/>
            </ApiLink>
        </p>
    </FooterContainer>
});

export const ApiLink = styled.a`
    font-weight: bold;
    font-size: 1.2rem !important;
    @media (max-width: 980px) {
        font-size: 1.4rem !important;
    }
`;

const FooterContainer = styled.footer `
    min-height: 6rem;
    border-top: 1px solid ${colors.white};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const FooterText = styled.h3 `
    margin-bottom: 0.3rem;
    font-weight: bold;
    font-size: 1.2rem;
    @media (max-width: 980px) {
        font-size: 1.4rem !important;
    }
`;

export default Footer;