import {makeAutoObservable, runInAction} from "mobx"
import { predictTimeSeries } from "../assets/predictTimeSeries";

type marketData = [ number, number];

interface HistoricalData {
    prices?: marketData;
    volumes?: marketData;
    weekForecastArima?: Array < number >;
}

class ForecastData implements HistoricalData {
    private readonly apiBaseUrl = 'https://api.coingecko.com/api/v3/coins/';
    public prices?: marketData | undefined;
    public volumes?: marketData | undefined;
    private trainingDataLength = 30;
    private forecastLength = 7;

    public weekForecastArima = [];

    constructor() {
        makeAutoObservable(this);
    }

    private calculateArima() {
        const ts = (this.prices as any)
                    ?.map((price: marketData) => price[1])
                    ?.slice(-this.trainingDataLength)
                     || [];
        this.weekForecastArima = predictTimeSeries(ts, this.forecastLength) as any;
    }

    public fetchData(coin: string, currency : string) {
        fetch(`${this.apiBaseUrl}${coin}/market_chart?days=max&vs_currency=${currency}`)
            .then(response => response.json())
            .then(data => {
                runInAction(() => {
                    this.prices = data
                        .prices.filter((price: marketData) => price[1] !== null)
                    this.volumes = data
                        .total_volumes.filter((volume: marketData) => volume[0] >= data
                        .prices.filter((price: marketData) => price[1] !== null)[0][0])
                    this.calculateArima();
                })
            })
    }
}

export const forecastData = new ForecastData();