export const currencyNames = {
    btc: 'Bitcoin',
    eth: 'Ethereum',
    ltc: 'Litecoin',
    bch: 'Bitcoin Cash',
    bnb: 'Binance Coin',
    eos: 'EOS IO',
    xrp: 'Ripple',
    xlm: 'Stellar',
    link: 'Chainlink',
    dot: 'Polkadot',
    yfi: 'Yearn Finance',
    usd: 'United States Dollar',
    aed: 'United Arab Emirates Dirham',
    ars: 'Argentina Peso',
    aud: 'Australian Dollar',
    bdt: 'Bangladeshi Taka',
    bhd: 'Bahraini Dinar',
    bmd: 'Bermudan Dollar',
    brl: 'Brazilian Real',
    cad: 'Canadian Dollar',
    chf: 'Swiss Franc',
    clp: 'Chilean Peso',
    cny: 'Chinese Yuan',
    czk: 'Czech Koruna',
    dkk: 'Danish Krone',
    eur: 'Euro',
    gbp: 'Pound Sterling',
    hkd: 'Hong Kong Dollar',
    huf: 'Hungarian Forint',
    idr: 'Indonesian Rupee',
    ils: 'Israeli new shekel',
    inr: 'Indian Rupee',
    jpy: 'Japanese Yen',
    krw: 'South Korean won',
    kwd: 'Kuwaiti Dinar',
    lkr: 'Sri Lankan Rupee',
    mmk: 'Myanmar Kyat',
    mxn: 'Mexican Peso',
    myr: 'Malaysian Ringgit',
    ngn: 'Nigerian Naira',
    nok: 'Norwegian Krone',
    nzd: 'New Zealand Dollar',
    php: 'Philippine Peso',
    pkr: 'Pakistani Rupee',
    pln: 'Polish Zloty',
    sar: 'Saudi Riyal',
    sek: 'Swedish Krona',
    sgd: 'Singapore Dollar',
    thb: 'Thai Baht',
    try: 'Turkish Lira',
    twd: 'New Taiwan dollar',
    uah: 'Hryvnia',
    vef: 'Venezuelan Bolívar',
    vnd: 'Vietnamese dong',
    zar: 'South African Rand',
    xdr: 'Special Drawing Rights',
    xag: 'Silver Ounces',
    xau: 'Gold Ounces',
    bits: 'Bits',
    sats: 'Satoshis'
}