export const Links = {
    HOME: '/',
    COINGECKO: "https://www.coingecko.com",
    SCIENTIFIC_NOTATION: 'https://en.wikipedia.org/wiki/Scientific_notation',
    AUTO_ARIMA_DESC: "https://medium.com/featurepreneur/what-is-auto-arima-b8025c6d732d",
    NO_COIN: '/no-coin',
    COIN_INFO: '/cryptocurrency-market-data',
    CRYPTO_WALLET: '/crypto-wallet-correlation',
    MOVING_AVERAGE: '/cryptocurrency-moving-average',
    BLOG: '/blog',
    ABOUT_US: '/about-us',
    PRIVACY_POLICY: '/privacy-policy',
    SITEMAP: '/coin-sitemap'
}