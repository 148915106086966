import React from "react";
import {bitcoinData} from '../provider/bitcoinData';
import {observer} from "mobx-react"
import styled from "styled-components";
import {FormattedMessage} from 'react-intl';
import Price from "./Price";
import {colors} from "../assets/colors";

const Header = observer(({currentCurrency}: {currentCurrency: string}) => <HeaderContainer>
    <HeaderIcon
        src={bitcoinData.imageLarge}
        alt={bitcoinData.symbol}
        />
    <HeaderList>
    <HeaderListItem>
            <HeaderTitle className='p-name' itemProp="headline">
                Cryptocurrency Market Data
            </HeaderTitle>
        </HeaderListItem>
        <HeaderListItem>
            Coin name:
                <Label>
                    {bitcoinData.name}
                </Label>
        </HeaderListItem>
        {bitcoinData.genesisDate && <HeaderListItem>
            <FormattedMessage id="date"/>
            <Label>
                {bitcoinData.genesisDate}
            </Label>
        </HeaderListItem>}
        <HeaderListItem>
        <FormattedMessage id="symbol"/>
            <Label>
            {bitcoinData.symbol}
            </Label>
        </HeaderListItem>
    </HeaderList>
    <Price currentCurrency={currentCurrency}/>
</HeaderContainer>)

export const HeaderTitle = styled.h1 `
            font-size: 1.5rem;
            display: inline;
            font-weight: 400;
            @media (max-width: 980px) {
                font-size: 1.8rem;
                margin-bottom: 1rem;
                margin-top: 1rem;
            }
`;

export const HeaderContainer = styled.header `
    position: relative;
    display: flex;
    padding: 3rem 3rem 3rem 10rem;
            
    @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 3rem 5rem;
    }
    align-items: center;
    border-bottom: 1px solid ${colors.white};
    box-shadow: -1px 24px 53px 0px rgba(0,0,0,0.18);
-webkit-box-shadow: -1px 24px 53px 0px rgba(0,0,0,0.18);
-moz-box-shadow: -1px 24px 53px 0px rgba(0,0,0,0.18);
`;

const iconSize = '150px';

const HeaderIcon = styled.img `
    width: ${iconSize};
    height: ${iconSize};
    background-color: ${colors.white};
    margin-right: 2rem;
    margin-bottom: 0.5rem;
    @media (max-width: 1000px) {
        margin-right: 0;
        margin-bottom: 2rem;
    }
    border-radius: 50%;
`;

const HeaderList = styled.ul `
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`

const HeaderListItem = styled.li `
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-shadow: rgba(0, 0, 0, 0.1) 2px 6px;
    @media (max-width: 980px) {
        &:first-of-type {
            margin-bottom: 3rem;
        }
    }
`;

const Label = styled.span `
    margin-left: 0.5rem;
`;

export default Header;