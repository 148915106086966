export const externalLinksExchanges = {
    "Binance": "https://www.binance.com/",
    "Coinbase": "https://www.coinbase.com/",
    "Kraken": "https://www.kraken.com/",
    "Gemini": "https://www.gemini.com/",
    "Bitfinex": "https://www.bitfinex.com/",
    "Bitstamp": "https://www.bitstamp.net/",
    "Huobi": "https://www.huobi.com/",
    "OKEx": "https://www.okex.com/",
    "Bittrex": "https://bittrex.com/",
    "KuCoin": "https://www.kucoin.com/",
};

export const externalLinksNews = {
    "CoinDesk": "https://www.coindesk.com/",
    "Cointelegraph": "https://cointelegraph.com/",
    "CryptoSlate": "https://cryptoslate.com/",
    "Crypto Briefing": "https://cryptobriefing.com/",
    "Decrypt": "https://decrypt.co/",
    "The Block": "https://www.theblockcrypto.com/",
    "Crypto Insider": "https://cryptoinsider.com/",
    "CryptoPotato": "https://cryptopotato.com/",
    "NewsBT": "https://www.newsbtc.com/",
    "BeInCrypto": "https://beincrypto.com/",
}

export const externalLinksStartups = {
    "Chainlink": "https://chain.link/",
    "Solana": "https://solana.com/",
    "Polygon": "https://polygon.technology/",
    "Filecoin": "https://filecoin.io/",
    "Algorand": "https://www.algorand.com/",
    "Oasis Network": "https://oasisprotocol.org/",
    "Skale Network": "https://skale.network/",
    "Radix DLT": "https://www.radixdlt.com/",
    "Injective Protocol": "https://injectiveprotocol.com/",
    "Mirror Protocol": "https://mirror.finance/",
}