import React from 'react';
import ReactDOM from 'react-dom/client';
import {IntlProvider} from 'react-intl';
import App from './App';
import './reset.css'
import {texts} from './texts/texts';
import {QueryClient, QueryClientProvider} from 'react-query'
import { CookiesProvider } from 'react-cookie';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactGA from 'react-ga';

ReactGA.initialize('G-QEFXMEGR17');


const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root')as HTMLElement);

const locale = 'en';

root.render(
    <React.StrictMode>
        <IntlProvider locale={locale} messages={texts}>
            <QueryClientProvider client={queryClient}>
                <CookiesProvider>
                    <App/>
                </CookiesProvider>
            </QueryClientProvider>
        </IntlProvider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();