import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import gsap from "gsap";
import {useMediaQuery} from 'react-responsive'
import { colors } from "../../assets/colors";

const Logo = ({isNavigation}: {isNavigation?: boolean}) => {
    const circleRef = useRef(null);
    const svgRef = useRef(null);
    const bigScreenQuery = "(min-width: 1000px)";
    const isBigScreen = useMediaQuery({query: bigScreenQuery});

    useEffect(() => {
        if (circleRef && svgRef && isBigScreen && !isNavigation) {
            const timeline = gsap.timeline();
            timeline.fromTo(svgRef.current, {}, {});
            timeline.fromTo(svgRef.current, {
                opacity: 0,
                delay: 1,
                duration: 3,
                repeat: 1
            }, {
                opacity: 1,
                delay: 1,
                duration: 2
            })
            timeline.fromTo(circleRef.current, {
                scale: 0.2,
                transformOrigin: 'center center'
            }, {
                duration: 3,
                scale: 1,
                repeat: -1,
                yoyo: true,
                transformOrigin: 'center center',
                ease: 'bounce'
            });
        }
    }, [circleRef, svgRef, isBigScreen, isNavigation]);

    return (
        <LogoSvg
            ref={svgRef}
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 83 88"
            width="83"
            height="88"
            isNavigation={isNavigation}>
            <title>crypto logo svg</title>
            <g id="layer1">
                <path
                    ref={circleRef}
                    id="path131"
                    className="s0"
                    d="m37.6 54.3c-8.1 0-14.6-6.5-14.6-14.6 0-8.1 6.5-14.6 14.6-14.6 8.1 0 14.6 6.5 14.6 14.6 0 8.1-6.5 14.6-14.6 14.6z"/>
                <path
                    id="path8181"
                    className="s1"
                    d="m36.9 68.5c-16.2 0-29.2-13-29.2-29.2 0-16.1 13-29.2 29.2-29.2 16.1 0 29.2 13.1 29.2 29.2 0 16.2-13.1 29.2-29.2 29.2z"/>
                <path
                    id="path9917"
                    className="s2"
                    d="m65.9 52.4c-4.5 0-8.1-6.4-8.1-14.2 0-7.8 3.6-14.1 8.1-14.1 4.5 0 8.1 6.3 8.1 14.1 0 7.8-3.6 14.2-8.1 14.2z"/>
            </g>
        </LogoSvg>
    );
}

const LogoSvg = styled.svg<{isNavigation?: boolean}>`
    width: 100%;
    height: 100%;
    & .s0 {
    fill: ${props => props.isNavigation ? colors.textPrimary : colors.textPrimary };
    }

    & .s1 {
    fill: none;
    stroke: ${props => props.isNavigation ? colors.black : colors.white };
    stroke-width: 9.9;
    }

    & .s2 {
    opacity: 1;
    fill: ${props => props.isNavigation ? colors.white : colors.bgcPrimary };
    stroke: ${props => props.isNavigation ? colors.white : colors.bgcPrimary };
    stroke-width: 9
    }
`;

export default Logo;