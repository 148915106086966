import gsap from 'gsap';
import React, {useRef, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Links } from '../../assets/links';

const PageNotFound = () => {
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef) {
            let timeLine = gsap.timeline();
            timeLine.fromTo(contentRef.current, {
                opacity: 0,
                duration: 3,
                repeat: 1
            }, {
                opacity: 1,
                duration: 3,
                delay: .5
            });
        }
    }, [contentRef]);

    return <> 
     <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>
    <Container ref={contentRef}>
        <Status>404</Status>
        <h1>Sorry, page not found</h1>
        <p>Go to <Link to={Links.HOME}>home page</Link></p>
        <h2>Useful links:</h2>
        <ul>
            <li><Link to={Links.CRYPTO_WALLET}>crypto wallet correlation</Link></li>
            <li><Link to={Links.MOVING_AVERAGE}>moving average trading bot</Link></li>
            <li><Link to={Links.BLOG}>blog</Link></li>
        </ul>
    </Container>
    </>
};

const Status = styled.p`
    font-size: 4rem;
    text-align: center;
`;

const Container = styled.section`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    text-transform: uppercase;
    & h1 {
        font-size: 3rem;
        text-transform: uppercase;
    }
    & h1+p {
        margin-top: 1rem;
        margin-bottom: 5rem;
        text-align: center;
        text-transform: uppercase;
    }

    & h2 {
        font-size: 2rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
        text-align: center;
        text-transform: uppercase;
    }
    & ul {
        list-style: none;
        text-align: center;
        & li {
            font-size: 2rem;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }
    }
`;

export default PageNotFound;