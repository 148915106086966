import React from "react";
import styled from "styled-components";
import ReactLoading from 'react-loading';
import { colors } from "../../assets/colors";
import { FormattedMessage } from "react-intl";

const Loading = () => {
    const iconSize = "20rem";

    return <LoadingContainer>
        <Label>
            <FormattedMessage id="loading.label" />
        </Label>
        <ReactLoading type="spin" color={colors.textPrimary} height={iconSize} width={iconSize} />
    </LoadingContainer>
};

const Label = styled.p`
    position: absolute;
    font-size: 2rem;
    color: ${colors.textPrimary};
    font-weight: bold;
`;

const LoadingContainer = styled.div `
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export default Loading;