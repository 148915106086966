import {observer} from "mobx-react";
import React, {MouseEventHandler, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {colors} from "../assets/colors";
import CommunityTab from "./tabs/CommunityTab";
import DescriptionTab from "./tabs/DescriptionTab";
import DevelopmentTab from "./tabs/DevelopmentTab";

const CoinDetails = observer(() => {
    const firstTab = 'Description';
    const secondTab = 'Community';
    const thirdTab = 'Development';

    const tabsContent = {
        [firstTab]: <DescriptionTab/>,
        [secondTab]: <CommunityTab/>,
        [thirdTab]: <DevelopmentTab/>
    }

    const [currentTab,
        setCurrentTab] = useState < string > (firstTab);
    const clickHandler : MouseEventHandler < HTMLButtonElement > = (evt) => setCurrentTab((evt.target as HTMLButtonElement).textContent || "");
    const activeTabClass = 'active';

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 2050;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }
        }
    }, [isPostsVisible, postsRef, scrollPosition]);

    return <DetailsContainer id="coinDetails" ref={postsRef} isVisible={isPostsVisible}>
        <div>
            <TabsNavigation>
                {Object
                    .keys(tabsContent)
                    .map(tabName => <TabsNavigationItem key={tabName}>
                        <TabsButton
                            type="button"
                            className={`${currentTab === tabName && activeTabClass}`}
                            onClick={clickHandler}
                            title={tabName + ' tab'}
                            >
                            {tabName}
                        </TabsButton>
                    </TabsNavigationItem>)}
            </TabsNavigation>
        </div>
        {tabsContent[currentTab as keyof typeof tabsContent]}
    </DetailsContainer>;
});

const DetailsContainer = styled.section< {
    isVisible?: boolean
} >`
    margin: 0 auto;
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 5rem;
    width: 60vw;

    @media (max-width: 750px) {
        width: 90%;
    }

    opacity: ${props => props.isVisible
        ? '1'
        : '0'};
        transition: 0.5s;
`;

const TabsNavigation = styled.ul `
    list-style: none;
    display: flex;
    
    @media (max-width: 980px) {
        flex-direction: column;
    }
`;

const TabsNavigationItem = styled.li `
    list-style: none;
    border-bottom: 2px solid ${colors.bgcPrimary};

    @media (min-width: 980px) {
        border-bottom: none;
        &:not(:last-of-type) {
            border-right: 2px solid ${colors.bgcPrimary};

        }
    }
`;

const TabsButton = styled.button `
    width: 100%;
    text-transform: uppercase;
    line-height: 270%;
    border-radius: 0;
    border: none;
    cursor: pointer;
    color: black;
    font-weight: bold;
    font-size: 1.3rem;
    background-color: ${colors.white};
    @media (min-width: 980px) {
        transition-property: background-color;
        transition-duration: 0.3s;
        width: 13rem;
    }


    &.active {
        background-color: #0077B3;
        color: ${colors.white};
    }

    &:hover {
        opacity: 0.95;
    }
`;

export default CoinDetails;