import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { currencyNames } from "../assets/currencyNames";
import { HeaderContainer, HeaderTitle } from "../components/Header";
import { CustomItem, customItemCurrencyParams, customItemParams, CustomItemsCurrency, SearchField } from "../components/homeForm/HomeForm";
import { AuthorHeader, Description, LinksExternal, Motto, SectionContainer, SectionTitle } from "./CryptoWalletCorr";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons'
import { colors } from "../assets/colors";
import { DownloadButton } from "../components/HistoricalData";
import { Tooltip } from "react-tooltip";
import MaCalculation from "../components/maCalculation/MaCalculation";
import { Author, Resources, WhiteLink } from "./BlogPost";
import { Link } from "react-router-dom";
import { Links } from "../assets/links";
import Footer from "../components/Footer";
import OtherPosts from "../blog/components/OtherPosts";
import { btcVsEth } from "../blog/posts/btcVsEth";
import { cryptoTaxes } from "../blog/posts/cryptoTaxes";
import { decentralizedFin } from "../blog/posts/decentralizedFin";
import { riseOfNft } from "../blog/posts/riesOfNft";
import { stakingCrypto } from "../blog/posts/stakingCrypto";
import { top5Altcoins } from "../blog/posts/top5Altcoins";
import CompanyName from "../components/companyName/CompanyName";
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga';

const MovingAverage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    const posts = useMemo(() => [
        btcVsEth,
        cryptoTaxes,
        decentralizedFin,
        riseOfNft,
        stakingCrypto,
        top5Altcoins
    ], []);

    const [crypto,
        setCrypto] = useState('');
    const [cryptoError,
        setCryptoError] = useState(false);
    const [cryptoNames,
        setCryptoNames] = useState([]);
    const [currency,
        setCurrency] = useState('');
    const [currencyList,
        setCurrencyList] = useState([]);
    const [currencyError,
        setCurrencyError] = useState(false);
    const [firstMa, setFirstMa] = useState(10);
    const [secondMa, setSecondMa] = useState(20);
    const [resultVisible, setResultVisible] = useState(false);
    const [lastUpdated, setLastUpdated] = useState("");

    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, []);

        useEffect(() => {
            const allCryptoNames = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd';
            const allAvailableCurrency = 'https://api.coingecko.com/api/v3/simple/supported_vs_currencies';
    
            const cryptoErrorMessage = "Cant read currency names";
            fetch(allCryptoNames)
                .then(res => res.json())
                .then(data => {
                    setCryptoNames(data.map((item : any) => ({id: item.id, value: item.name, image: item.image, marketCap: item.market_cap, priceChange: item.price_change_percentage_24h, toMax: ((item.ath / item.current_price) * 100).toFixed(2)
            })));
            setLastUpdated(data[0].last_updated);
        })
                .catch(err => console.log(err + cryptoErrorMessage));
    
            const currencyErrorMessage = "Cant read currency names";
            fetch(allAvailableCurrency)
                .then(res => res.json())
                .then(data => setCurrencyList(data.map((item : any) => ({
                    id: item,
                    value: item,
                    description: (currencyNames as any)[item] ?? item
                }))))
                .catch(err => console.log(err + currencyErrorMessage));
        }, []);    

            
    const customItems = cryptoNames.map((item : customItemParams) => ({
            ...item,
            node: <CustomItem item={item}/>
    }));

    const customItemsCurrency = currencyList.map((item : customItemCurrencyParams) => ({
        ...item,
        node: <CustomItemsCurrency item={item}/>
    }));

    const submit = () => {
        setResultVisible(true);
    }

    const isValid = useMemo(() => {
        if (cryptoError || currencyError) {
            return false;
        }
        
        if (!crypto || !currency) {
            return false;
        }

        if (firstMa === secondMa) return false;
        if (firstMa < 1 || firstMa > 30) return false;
        if (secondMa < 1 || firstMa > 30) return false;

        return true;
    },[cryptoError, currencyError, crypto, currency, firstMa, secondMa])

    
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }
        }
    }, [isPostsVisible, postsRef, scrollPosition]);

    const [isDescriptionVisible,
        setIsDescriptionVisible] = useState(false);
    
    const descriptionRef = useRef(null);

    useEffect(() => {
        if (descriptionRef.current) {
            const formPositionFromTop = (descriptionRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isDescriptionVisible && isInScrollContext)) {
                setIsDescriptionVisible(true);
            }
        }
    }, [isDescriptionVisible, descriptionRef, scrollPosition]);

    const [isHowToVisible,
        setIsHowToVisible] = useState(false);
    
    const howToRef = useRef(null);

    useEffect(() => {
        if (howToRef.current) {
            const formPositionFromTop = (howToRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isHowToVisible && isInScrollContext)) {
                setIsHowToVisible(true);
            }
        }
    }, [isHowToVisible, howToRef, scrollPosition]);

    const [isTechnicalitiesVisible,
        setIsTechnicalitiesVisible] = useState(false);
    
    const technicalitiesRef = useRef(null);

    useEffect(() => {
        if (technicalitiesRef.current) {
            const formPositionFromTop = (technicalitiesRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isTechnicalitiesVisible && isInScrollContext)) {
                setIsTechnicalitiesVisible(true);
            }
        }
    }, [isTechnicalitiesVisible, technicalitiesRef, scrollPosition]);

    const [sliderIndex,
        setSliderIndex] = useState(0);

    useEffect(() => {
        if (isNaN(sliderIndex) || sliderIndex < 0){
            setSliderIndex(0);
        }
    },[sliderIndex]);

    return <>
         <Helmet>
                <title>Crypto Moving Average Calculator - Analyze Trends and Improve Trading</title>
                <link rel="canonical" href="https://coindatashake.com/cryptocurrency-moving-average/" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Our advanced Cryptocurrency Moving Average Calculator provides comprehensive analysis of crypto trends and helps you improve your trading strategy. Analyze market trends in real-time and make informed decisions with our reliable tool. Try it now!" />
                <meta property="og:title" content="Crypto Moving Average Calculator - Analyze Trends and Improve Trading" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://coindatashake.com/cryptocurrency-moving-average/" />
                <meta property="og:image" content="https://coindatashake.com/images/ma/coin-moving-average.webp" />
                <meta property="og:description" content="Our advanced Cryptocurrency Moving Average Calculator provides comprehensive analysis of crypto trends and helps you improve your trading strategy. Analyze market trends in real-time and make informed decisions with our reliable tool. Try it now!" />
            </Helmet>
            <div className='h-card' itemScope itemType="http://schema.org/Article">
         <HeaderContainer style={{justifyContent: "space-between", flexWrap: 'wrap'}}>
    <HeaderTitle className='p-name' itemProp="headline"><CompanyName />Cryptocurrency Moving Average Calculator</HeaderTitle>
    <Motto><strong>Stay Ahead of the Crypto Curve with Our Moving Average Calculator</strong></Motto>
    </HeaderContainer>
    <SectionContainer id="maForm" ref={postsRef} isVisible={isPostsVisible}>
    <SectionTitle>Select Parameters To The Analysis</SectionTitle>
        <form onSubmit={(e) => e.preventDefault()}>
        <NumberContainer onClick={e => e.preventDefault()}>
        <span>Select cryptocurrency to analyse</span>
        <SearchField
                placeholder={"Cryptocurrency"}
                showLabel={false}
                label={"Cryptocurrency"}
                onSelect={(item : {
                id: string
            }) => {
                setCrypto(item.id);
                setCryptoError(false);
            }}
                onChange={() => setCryptoError(true)}
                items={customItems}/>
        </NumberContainer>
        <NumberContainer onClick={e => e.preventDefault()}>
        <span>Select currency to measure value of asset</span>
            <SearchField
                placeholder={"Currency"}
                showLabel={false}
                label={"Currency"}
                onSelect={(item : {
                id: string
            }) => {
                setCurrency(item.id);
                setCurrencyError(false)
            }}
                onChange={() => setCurrencyError(true)}
                items={customItemsCurrency}/>
                </NumberContainer>
            <NumberContainer>
                <span>Select first MA period</span>
            <NumberField type="number" placeholder="Add number" min={1} max={30} pattern="[0-9]+" disabled style={{backgroundColor: colors.white, color: colors.black}} value={firstMa} onChange={(e) => {
                if ( isNaN(+parseInt(e.target.value)) ) {
                    return;
                }
                if (+e.target.value > 30) {
                    setFirstMa(30);
                    return;
                } 
                if (+e.target.value < 0) {
                    setFirstMa(1);
                    return;
                }

                setFirstMa(+e.target.value);
            }} />
                <FontAwesomeIcon tabIndex={0} icon={faMinus} color={colors.black} role="button" aria-hidden="false" aria-label="Decrease the number of MA period" onClick={(e) => {
                    e.stopPropagation();
                    if (firstMa <= 1) return;
                    setFirstMa(firstMa - 1)}    
                } />
                <FontAwesomeIcon tabIndex={0} icon={faPlus} color={colors.black} role="button" aria-hidden="false" aria-label="Increase the number of MA period" onClick={(e) => {
                    e.stopPropagation();
                    if (firstMa >= 30) return;
                    setFirstMa(firstMa + 1)}    
                }/>
            </NumberContainer>
            <NumberContainer>
            <span>Select second MA period</span>
            <NumberField type="number" placeholder="Add number" min={1} max={30} pattern="[0-9]+" disabled style={{backgroundColor: colors.white, color: colors.black}}  value={secondMa} onChange={(e) => {
                if ( isNaN(+parseInt(e.target.value)) ) {
                    return;
                }
                if (+e.target.value > 30) {
                    setSecondMa(30);
                    return;
                } 
                if (+e.target.value < 0) {
                    setSecondMa(1);
                    return;
                }

                setSecondMa(+e.target.value);
            }}/>
                <FontAwesomeIcon  tabIndex={0}icon={faMinus} color={colors.black} role="button" aria-hidden="false" aria-label="Decrease the number of MA period" onClick={(e) => {
                     e.stopPropagation();
                    if (secondMa <= 1) return;
                    setSecondMa(secondMa - 1)}
                } />
                <FontAwesomeIcon  tabIndex={0} icon={faPlus} color={colors.black} role="button" aria-hidden="false" aria-label="Increase the number of MA period" onClick={(e) => {
                    e.stopPropagation();
                    if (secondMa >= 30) return;
                    setSecondMa(secondMa + 1)}
                }/>
            </NumberContainer>
            {!resultVisible && <DownloadButton tabIndex={0} color={colors.textPrimary} style={{
                    cursor: isValid
                        ? 'pointer'
                        : 'not-allowed',
                    fontSize: "1.6rem"
                }} onClick={ isValid ? submit : ()=>{}}>
                    <span id='SubmitButton'  data-tooltip-content={isValid ? "Data are correct." : firstMa === secondMa ? "MAs periods should be different." : "Please fill all required fields."}>Analyse</span>
                    <Tooltip
                    anchorId="SubmitButton"
                    style={{
                    maxWidth: '60vw',
                    textAlign: 'center'
                }}/>
                </DownloadButton>}
            
        </form>
    </SectionContainer>
    {resultVisible && <MaCalculation crypto={crypto} currency={currency} firstMa={firstMa} secondMa={secondMa}/>}
    <div itemProp="articleBody" className="e-content">
    <SectionContainer id="description" ref={descriptionRef} isVisible={isDescriptionVisible}>
        <SectionTitle>Tool Description</SectionTitle>
        <Description>
        Our Cryptocurrency Moving Average Tool is a powerful tool designed to help traders analyze and understand trends in the ever-changing cryptocurrency market. By using advanced moving averages, our tool provides real-time analysis of cryptocurrency trends, allowing users to make informed trading decisions and stay ahead of the curve.
        </Description>
        <Description>
        With our Cryptocurrency Moving Average Tool, users can easily analyze trends and patterns in the market. The tool provides accurate and reliable analysis of cryptocurrency trends, allowing traders to make informed decisions about when to buy, sell, or hold their crypto assets. Whether you're a seasoned trader or just starting out, our tool is an essential resource for anyone looking to stay ahead of the cryptocurrency curve.
        </Description>
        <Description>
        Our tool is easy to use and accessible to traders of all levels. With its intuitive interface and real-time analysis, you can quickly and easily gain valuable insights into the cryptocurrency market. By leveraging the power of advanced moving averages, our tool provides a comprehensive view of market trends, allowing you to make informed decisions about your investments. Whether you're a day trader or a long-term investor, our Cryptocurrency Moving Average Tool is an essential resource for anyone looking to succeed in the world of cryptocurrency trading.
        </Description>
    </SectionContainer>
    <SectionContainer id="howTo" ref={howToRef} isVisible={isHowToVisible}>
    <SectionTitle>How to use</SectionTitle>
        <Guide>
            <li>
                <p>Select asset you want to analyse.</p>
            </li>
            <li>
                <p>Select currency in which asset value is measured.</p>
            </li>
            <li>
                <p>Select period for short term moving average.</p>
            </li>
            <li>
                <p>Select period for long term moving average.</p>
            </li>
            <li>
                <p>For the first time click the analyse button, after it just change parameters and the tool calculate results automatically.</p>
            </li>
        </Guide>
    </SectionContainer>
    <SectionContainer id="interpretation" ref={howToRef} isVisible={isHowToVisible}>
    <SectionTitle id="interpretation">Interpretation</SectionTitle>
    <Description>
    Moving averages are a popular technical analysis tool used by traders to identify trends and patterns in the cryptocurrency market. Essentially, a moving average is the average price of a particular cryptocurrency over a specific time period, and it can be calculated for any period, from minutes to weeks or even months. By analyzing the movement of the moving averages over time, traders can gain insights into market trends and make informed trading decisions.
    </Description>
    <Description>
    When analyzing cryptocurrency moving averages, traders typically look for two main things: crossovers and divergences. A crossover occurs when a short-term moving average (such as the 10-day moving average) crosses above or below a long-term moving average (such as the 20-day moving average). This is seen as a potential signal that the trend is changing, and traders may look to buy or sell depending on the direction of the crossover. A divergence occurs when the price of a cryptocurrency diverges from the moving average, which can indicate a potential trend reversal.
    </Description>
    <Description>
    It's important to note that while moving averages can be a useful tool for analyzing cryptocurrency trends, they should not be relied upon as the sole basis for making trading decisions. Traders should always consider other factors, such as market sentiment, news events, and fundamental analysis, in addition to technical analysis tools like moving averages. Additionally, past performance is not indicative of future results, so traders should use caution when making trading decisions based on moving average analysis.
    </Description>
    </SectionContainer>
    <OtherPosts posts={posts} setSliderIndex={setSliderIndex} sliderIndex={sliderIndex} />
    <SectionContainer id="technicalities" ref={technicalitiesRef} isVisible={isTechnicalitiesVisible}>
        <SectionTitle>Technicalities</SectionTitle>
        <Description>
        Technical analysis is a method of predicting future price movements by analyzing past market data. Technical traders use a variety of tools and techniques to analyze market trends and patterns, and one of the most commonly used tools is the Moving Average (MA) indicator.The Moving Average is a popular technical indicator that smooths out price data by creating a constantly updating average price. It is calculated by adding up the prices over a specific time period and dividing by the number of periods. The result is a line that moves along with the price action, providing traders with a clearer picture of market trends and potential trading opportunities.
        </Description>
        <Description>
        Trend identification is one of the most common uses of Moving Averages. Traders will often use two Moving Averages – a shorter-term MA and a longer-term MA – to identify the overall direction of the trend. When the shorter-term MA crosses above the longer-term MA, it is considered a bullish signal, indicating that the price is likely to continue moving higher. Conversely, when the shorter-term MA crosses below the longer-term MA, it is a bearish signal, indicating that the price is likely to move lower.Another common use of Moving Averages is to identify potential areas of support and resistance. 
        </Description>
        <Description>
        When prices are trending higher, the Moving Average line can act as a support level, providing a potential buying opportunity when prices pull back to the line. Conversely, when prices are trending lower, the Moving Average line can act as a resistance level, providing a potential selling opportunity when prices bounce off the line.Moving Averages can also be used to identify potential trading opportunities by generating buy and sell signals. The most common type of signal is the Moving Average crossover, which occurs when the shorter-term MA crosses above or below the longer-term MA.
        </Description>
        <Description>
        When the shorter-term MA crosses above the longer-term MA, it is considered a buy signal, indicating that the price is likely to continue moving higher. Conversely, when the shorter-term MA crosses below the longer-term MA, it is a sell signal, indicating that the price is likely to move lower.Traders can also use Moving Averages in combination with other technical indicators to generate more accurate buy and sell signals. For example, the Moving Average Convergence Divergence (MACD) indicator uses two Moving Averages – a shorter-term EMA and a longer-term EMA – to identify changes in momentum and generate buy and sell signals.
        </Description>
        <Description>
        In conclusion, the Moving Average is a powerful tool in technical analysis, providing traders with a clearer picture of market trends and potential trading opportunities. By using Moving Averages in combination with other technical indicators and forms of analysis, traders can generate more accurate buy and sell signals and make more informed trading decisions.
        </Description>
        <Resources>Resources</Resources>
        <LinksExternal>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://www.investopedia.com/terms/m/movingaverage.asp'} target="_blank" rel="noreferrer">https://www.investopedia.com/terms/m/movingaverage.asp</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://www.tradingview.com/support/solutions/43000502589-moving-average/'} target="_blank" rel="noreferrer">https://www.tradingview.com/support/solutions/43000502589-moving-average/</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://coinmarketcap.com/alexandria/article/how-to-use-moving-averages-in-trading'} target="_blank" rel="noreferrer">https://coinmarketcap.com/alexandria/article/how-to-use-moving-averages-in-trading</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://bitsgap.com/blog/moving-average-ma-explained-for-crypto-trading-how-does-it-work'} target="_blank" rel="noreferrer">https://bitsgap.com/blog/moving-average-ma-explained-for-crypto-trading-how-does-it-work</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://cryptotelegram.com/moving-average-strategies-cryptocurrencies/'} target="_blank" rel="noreferrer">https://cryptotelegram.com/moving-average-strategies-cryptocurrencies/</WhiteLink></li>
        </LinksExternal>
        <AuthorHeader>Author</AuthorHeader>
        <Author><span className="p-author h-card" itemProp="author" itemScope itemType="http://schema.org/Organization"><Link to={Links.ABOUT_US} itemProp="url" className="u-url p-name">Coin Data Shake</Link></span> - click on the author to see more information.</Author>
    </SectionContainer>
    </div>
    <Footer lastUpdated={lastUpdated}/>
    </div>
    </>  
};

const Guide = styled.ol`
    margin-left: 3rem;
    font-size: 1.4rem;
    &>li {
        line-height: 140%;
    }
`;

const NumberContainer = styled.label`
    display: block;
    position: relative;
    margin-top: 1rem;
    &>span {
        font-size: 1.2rem;
    }

    & * {
        -webkit-user-select: none;  
        -moz-user-select: none;    
        -ms-user-select: none;      
        user-select: none;
    }

    & svg {
        position: absolute;
        right: 20%;
        top: 65%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 1.5rem;
        
        @media(min-width: 800px) AND (max-width: 1000px) AND (orientation: portrait) {
            right: 10%;
        }
        @media(max-width: 900px) AND (orientation: landscape) {
            right: 10%;
        }

    }

    & svg:last-of-type {
        right: 15%;
        @media(max-width: 900px) {
            right: 5%;
        }

        @media (orientation: portrait) AND (max-width: 800px) {
            & {
                right: 8%;
            }
        }
    }
`;

const NumberField = styled.input`
    display: block;
    width: 90%;
    margin-top: 1rem;
    line-height: 300%;
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 30%;

    @media (max-width: 1000px) {
        & {
            width: 100%;
        }
    }

    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
    }
`;

export default MovingAverage;