import React, {useEffect, useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import {externalLinksExchanges, externalLinksNews, externalLinksStartups} from '../../assets/externalLinksHome';
import {FormBox} from '../homeForm/HomeForm';

const HomeLinksSection = ({scrollPosition} : {
    scrollPosition: number;
}) => {
    const sectionRef = useRef(null);
    const [isVisible,
        setIsVisible] = useState(false);
    const bigScreenQuery = "(orientation: landscape) AND (min-width: 1000px)";
    const mobileQuery = "(max-width: 980px)";
    const isBigScreen = useMediaQuery({query: bigScreenQuery});
    const isMobile = useMediaQuery({query: mobileQuery});

    useEffect(() => {
        if (sectionRef.current) {
            const formPositionFromTop = (sectionRef.current as HTMLDivElement)
                ?.offsetTop - 500;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isVisible && isInScrollContext) || !isBigScreen) {
                setIsVisible(true);
            }

        }

    }, [isVisible, isBigScreen, sectionRef, scrollPosition]);

    return <FormBox
        isVisible={isVisible}
        ref={sectionRef}
        id="homeLinks"
        style={{
        margin: isMobile ? "1rem auto 0 auto" : "15rem auto 0 auto"
    }}>
        <Title>Additional Resources</Title>
        <Container>
            <div>
                <SubTitle>Popular Cryptocurrency Exchanges</SubTitle>
                <LinksList>
                    {Object
                        .entries(externalLinksExchanges)
                        .map(([key, value]) => {
                            return <LinksListItem key={key}>
                                <span>{key}</span>
                                <a href={value} target="_blank" rel="noreferrer">{value}</a>
                            </LinksListItem>
                        })}
                </LinksList>
            </div>
            <div>
                <SubTitle>Cryptocurrency News Portals</SubTitle>
                <LinksList>
                    {Object
                        .entries(externalLinksNews)
                        .map(([key, value]) => {
                            return <LinksListItem key={key}>
                                <span>{key}</span>
                                <a href={value} target="_blank" rel="noreferrer">{value}</a>
                            </LinksListItem>
                        })}
                </LinksList>
            </div>
            <div>
                <SubTitle>Cryptocurrency StartUps</SubTitle>
                <LinksList>
                    {Object
                        .entries(externalLinksStartups)
                        .map(([key, value]) => {
                            return <LinksListItem key={key}>
                                <span>{key}</span>
                                <a href={value} target="_blank" rel="noreferrer">{value}</a>
                            </LinksListItem>
                        })}
                </LinksList>
            </div>

        </Container>

    </FormBox>
}
const Container = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media (orientation: portrait) and (max-width: 700px) {
        justify-content: flex-start;
    }
    &>div {
        margin-bottom: 3rem;
    }
`;

const Title = styled.h2 `
    margin-bottom: 2rem;
`;

const SubTitle = styled.h3 `
    margin-bottom: 1rem;
`;

const LinksList = styled.ul `
    list-style: none;
`;

const LinksListItem = styled.li `
    margin-bottom: 1rem;
    word-break: break-all;
    overflow-wrap: break-word;
    & span::after {
        content: ':';
        display: inline;
        margin-right: 1rem;
    }

    & a {
        @media (orientation: portrait) or (max-width: 900px) {
            color: ${colors.white};
        }
    }
`;

export default HomeLinksSection;