import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { SelectorIcon, SelectorImg } from '../../pages/CryptoWalletCorr';

const CorrList = ({corrData}: {corrData: any}) => {
    const filtered = corrData.filter((pair: any) => Math.abs(pair.corr) !== 1);
    const highCorr = filtered.filter((pair: any) => Math.abs(pair.corr) >= 0.9);
    const lowCorr = filtered.filter((pair: any) => Math.abs(pair.corr) <= 0.5);
    const mediumCorr = filtered.filter((pair: any) => Math.abs(pair.corr) < 0.9 && Math.abs(pair.corr) > 0.5);

    return <Container>
        {!!highCorr.length && <>
            <Header color={colors.error}>High correlated cryptocurrency</Header>
            <ResultList>
                {highCorr.map((pair: any) => (<ResultListItem key={pair.item1.id + pair.item2.id}><span><SelectorIcon><SelectorImg src={pair.item1.image} alt="" loading='lazy' /></SelectorIcon>{pair.item1.value}</span> <span><SelectorIcon><SelectorImg src={pair.item2.image} alt="" loading='lazy' /></SelectorIcon>{pair.item2.value}</span> <span>{pair.corr}</span></ResultListItem>))}
            </ResultList>
        </>}
        {!!mediumCorr.length && <>
            <Header color={colors.warning}>Medium correlated cryptocurrency</Header>
            <ResultList>
                {mediumCorr.map((pair: any) => (<ResultListItem key={pair.item1.id + pair.item2.id}><span><SelectorIcon><SelectorImg src={pair.item1.image} alt="" loading='lazy' /></SelectorIcon>{pair.item1.value}</span> <span><SelectorIcon><SelectorImg src={pair.item2.image} alt="" loading='lazy' /></SelectorIcon>{pair.item2.value}</span> <span>{pair.corr}</span></ResultListItem>))}
            </ResultList>
        </>}
        {!!lowCorr.length && <>
            <Header color={colors.success}>Low correlated cryptocurrency</Header>
            <ResultList>
                {lowCorr.map((pair: any) => (<ResultListItem key={pair.item1.id + pair.item2.id}><span><SelectorIcon><SelectorImg src={pair.item1.image} alt="" loading='lazy' /></SelectorIcon>{pair.item1.value}</span> <span><SelectorIcon><SelectorImg src={pair.item2.image} alt="" loading='lazy' /></SelectorIcon>{pair.item2.value}</span> <span>{pair.corr}</span></ResultListItem>))}
            </ResultList>
        </>}
        
    </Container>;
}

const Container = styled.div`
    padding-bottom: 1rem;
`;

const Header = styled.h3<{color: string}>`
    margin: 3rem 0 1rem 0;
    color: ${props => props.color};
    font-size: 1.4rem;
`;

const ResultList = styled.ul`
    list-style: none;
`;

const ResultListItem = styled.li`
    line-height: 250%;
    background-color: ${colors.white};
    color: ${colors.black};
    font-size: 1.2rem;
    padding: 0 2rem;
    border-bottom: 1px solid ${colors.black};
    display: flex;
    justify-content: space-evenly;
    &>span {
        width: 30%;
        @media (orientation: portrait) or (max-width: 1000px) {
            width: 45%;
            flex-grow: 1;
            line-height: 150%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }

    }
    &>span:last-of-type {
        width: 10%;
        @media (orientation: portrait) {
            flex-shrink: 1;
        }
    }
`;


export default CorrList;