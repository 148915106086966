import React, { useEffect } from "react";
import {colors} from "../../assets/colors";
import styled from "styled-components";
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {formatCount} from "../../assets/numbers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faArrowTrendDown, faMinus} from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import { Links } from "../../assets/links";

const Slider = ({
    sliderIndex,
    setSliderIndex,
    slidesVisible,
    isVisible,
    cryptoList,
    title,
    label,
    valueName,
    postfix,
    forwardedRef
} : {
    sliderIndex: number;
    setSliderIndex: (value : number) => void;
    slidesVisible: number;
    isVisible: boolean;
    cryptoList: Array < any >;
    title: string;
    label: string;
    valueName: string;
    postfix: string | JSX.Element;
    forwardedRef: any;
}) => {
    useEffect(() => {
        if (isNaN(sliderIndex) || sliderIndex < 0 || sliderIndex >= cryptoList.length){
            setSliderIndex(0);
        }
    },[sliderIndex, setSliderIndex, cryptoList]);

    const rankingSign = "#"
    const animationSpeedMs = 3000;

    const createPostfixIcon = (item : any) => {
        if (valueName !== "priceChange" && valueName !== 'priceChangeLowest') {
            return null;
        }

        if (Math.abs(item[valueName !== 'priceChangeLowest' ? valueName : 'priceChange']) < 0.1) {
            return <FontAwesomeIcon icon={faMinus} color={'yellow'}/>
        }

        if (item[valueName !== 'priceChangeLowest' ? valueName : 'priceChange'] > 0) {
            return <FontAwesomeIcon icon={faArrowTrendUp} color={colors.success}/>
        }

        return <FontAwesomeIcon icon={faArrowTrendDown} color={colors.error}/>
    }

    const cryptoItemsList = cryptoList
        ?.map((item, index) => <Card key={item.id}>
            <CardIcon>
                <img src={item.image} alt={item.value} loading="lazy"/>
            </CardIcon>
            <CardPosition>{rankingSign}{index + 1}</CardPosition>
            <CardTitle>
                <Link to={Links.COIN_INFO} state={{currency: 'usd', coin: item.id }} title={`${item.value} cryptocurrency information page.`}>
                    {item.value}
                </Link>
            </CardTitle>
            <CardValue>
                <LineBreak>{label}</LineBreak>{formatCount(item[valueName !== 'priceChangeLowest' ? valueName : 'priceChange'])}
                <CurrencyDisplay hasMargin={valueName === "marketCap"}>{postfix}{createPostfixIcon(item)}</CurrencyDisplay>
            </CardValue>
        </Card>);

    return <Container
        sliderIndex={sliderIndex}
        sliderLength={cryptoItemsList.length}
        slidesVisible={slidesVisible}
        isVisible={isVisible}
        ref={forwardedRef}
        id={valueName}>
            <Title>
            {title}
        </Title>
        {!!cryptoList.length &&  <>
        <Carousel
            plugins={[
            'arrows', {
                resolve: slidesToShowPlugin,
                options: {
                    numberOfSlides: slidesVisible
                }
            }
        ]}
            animationSpeed={animationSpeedMs}
            value={sliderIndex}
            onChange={setSliderIndex}
            draggable={false}
            >
            {cryptoItemsList}
        </Carousel></>}
        <Description>
            <FormattedMessage id={`${valueName}.description`}/>
        </Description>
    </Container>
}

export const Description = styled.p `
    font-size: 1.4rem;
    padding: 2rem 3rem;
    line-height: 130%;
    @media (orientation: portrait) and (max-width: 900px) {
        padding: 2rem 1rem;
    }
`;

export const LineBreak = styled.span `
    display: block;
    margin-bottom: 0.5rem;
`;

export const Title = styled.h2 `
    margin-left: 4rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    @media(orientation: portrait) {
        margin-left: 0;
        text-align: center;
    }
`;

const CurrencyDisplay = styled.span < {
    hasMargin: boolean
} > `
    ${props => props.hasMargin
    ? 'margin-left: 6px;'
    : ''} 

    & svg {
        margin-left: 1rem;
        font-weight: bold;
        font-size: 2rem;
    }
`;

export const CardValue = styled.h4 `
    text-align: center;
    font-size: 1.5rem;
    margin-top: 2rem;
`;

export const Container = styled.section < {
    sliderIndex: number;
    sliderLength: number;
    slidesVisible: number;
    isVisible?: boolean;
} > `
    margin: 10rem auto;
    @media(max-width: 980px) {
        margin: 0 auto;
    }
    max-width: 1200px;
    padding: 1rem;
    opacity: ${props => props.isVisible
    ? '1'
    : '0'};
        transition: 0.5s;

    & .BrainhubCarousel__arrows {
        background-color: ${colors.white}!important;
    }

    & .BrainhubCarousel__arrowLeft {
        margin-right: 0.5rem;
        opacity: ${props => props.sliderIndex
        ? '1'
        : '0'};

        pointer-events: ${props => props.sliderIndex
            ? 'initial'
            : 'none'};

        & span {
            border-color: ${colors.black}!important;
        }
    }

    & .BrainhubCarousel__arrowRight {
        margin-left: 0.5rem;
        opacity: ${props => props.sliderIndex >= props.sliderLength - props.slidesVisible
                ? '0'
                : '1'};

            pointer-events: ${props => props.sliderIndex >= props.sliderLength - props.slidesVisible
                    ? 'none'
                    : 'initial'};

        & span {
            border-color: ${colors.black}!important;
        }
    }
    & .BrainhubCarousel__arrows span {
        font-size: 20rem;
        text-indent: -9999px;

    }
`;

export const Card = styled.div `
    width: 100%;
    height: 350px;
    @media (orientation: portrait) {
        height: 375px;
    }
    margin: 1rem;
    padding: 1rem;
    border: 4px solid ${colors.white};
    overflow: hidden;
`;

export const CardPosition = styled.h3 `
    text-align: center;
    margin: 1rem 0;
    font-size: 1.5rem;
    letter-spacing: 2px;
`;

const iconSize = "7rem";

export const CardIcon = styled.div `
    width: ${iconSize};
    height: ${iconSize};
    margin: 0 auto;
    & img {
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
       }
`;

export const CardTitle = styled.h3 `
    text-align: center;
    // line-height: 200%;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6rem;
    word-break: break-all;
    overflow-wrap: break-word;
    hyphens: auto;
`;

export default Slider;