import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { CloseButton } from '../navigation/Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons'
import { Links } from '../../assets/links';

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies();
    const modalRoot = document.getElementById('root');
    const nextYear = new Date();
    nextYear.setDate(nextYear.getDate()+360);

    const acceptCookie = () => setCookie('cookieAccepted', true, {path: '/', sameSite: 'none', secure: true, expires: nextYear});
    const [modal, setModal] = useState<boolean>(false);

    useEffect(() => {
        if (!modal && !cookies.cookieAccepted) {
            setModal(true);
        }

        if (modal && cookies.cookieAccepted) {
            setModal(false);
        }

    }, [cookies, modal]);
    
    return ReactDOM.createPortal(modal ?  <ModalContent acceptCookie={acceptCookie} /> :  <></>, modalRoot as any);
};

const ModalContent = ({acceptCookie}: {acceptCookie: () => void}) => (
    <ModalContainer>
        <ModalTitle>Cookies</ModalTitle>
        <p>Our website uses cookies to improve your browsing experience, help us analyze site traffic, personalize content, and show you relevant ads. By continuing to browse our site, you agree to our use of cookies as described in our <a href={Links.PRIVACY_POLICY} target="_blank" rel="noreferrer" >Privacy Policy</a>. You can control your cookie preferences at any time through your browser settings.</p>
        <CloseButton style={{width: "1rem", height: "1rem", right: "2rem"}} onClick={acceptCookie} tabIndex={0} type="button" aria-label='Click to close cookie consent'>
            <FontAwesomeIcon icon={faClose} color={colors.black}/>
        </CloseButton>
    </ModalContainer>
);

const ModalContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: ${colors.white};
    z-index: 99;
    padding: 1rem 2rem;
    font-family: 'Ubuntu', sans-serif;
    font-size: 0.8rem;
    & a {
        font-weight: bold;
        color: black!important;
    }
`;

const ModalTitle = styled.h2`
    margin-bottom: 0.5rem;
`;

export default CookieConsent;