import  { useEffect, useState, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { colors } from '../assets/colors';
import { HeaderContainer, HeaderTitle } from '../components/Header';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRightLong, faArrowLeftLong, faSearch} from '@fortawesome/free-solid-svg-icons'
import { DownloadButton } from '../components/HistoricalData';
import CorrList from '../components/corrList/CorrList';
import Footer from '../components/Footer';
import { Author, Resources, WhiteLink } from './BlogPost';
import { Link } from 'react-router-dom';
import { Links } from '../assets/links';
import OtherPosts from '../blog/components/OtherPosts';
import { btcVsEth } from '../blog/posts/btcVsEth';
import { cryptoTaxes } from '../blog/posts/cryptoTaxes';
import { decentralizedFin } from '../blog/posts/decentralizedFin';
import { riseOfNft } from '../blog/posts/riesOfNft';
import { stakingCrypto } from '../blog/posts/stakingCrypto';
import { top5Altcoins } from '../blog/posts/top5Altcoins';
import CompanyName from '../components/companyName/CompanyName';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

function calculateCorrelation(x: number[], y: number[]): number {
    // Calculate the mean of x and y
    const xMean = x.reduce((acc, val) => acc + val, 0) / x.length;
    const yMean = y.reduce((acc, val) => acc + val, 0) / y.length;
  
    // Calculate the numerator and denominator of the correlation coefficient formula
    let numerator = 0;
    let xDenominator = 0;
    let yDenominator = 0;
  
    for (let i = 0; i < x.length; i++) {
      numerator += (x[i] - xMean) * (y[i] - yMean);
      xDenominator += (x[i] - xMean) ** 2;
      yDenominator += (y[i] - yMean) ** 2;
    }
  
    // Calculate the correlation coefficient
    const denominator = Math.sqrt(xDenominator) * Math.sqrt(yDenominator);
    const correlation = numerator / denominator;
  
    return correlation;
  };

  function calculateAll(allCoins: Array<any>) {
    let result: Array<any> = [];
    allCoins.forEach(coinA => {
        allCoins.forEach(coinB => {
            if (coinA.id === coinB.id) return;
            if (result.filter(pair => (pair.item1.id === coinA.id && pair.item2.id === coinB.id) || (pair.item1.id === coinB.id && pair.item2.id === coinA.id)).length) return;
            const aLength = coinA.data.length;
            const bLength = coinB.data.length;

            if ( aLength !== bLength ) {
                const minLength = Math.min(aLength, bLength);
                coinA.data.length = minLength;
                coinB.data.length = minLength;
            }
            result.push({item1: coinA, item2: coinB, corr: calculateCorrelation(coinA.data, coinB.data).toFixed(2)});
        })
    });
    return result;
  }
  

const CryptoWalletCorr = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    const [cryptoNames,
        setCryptoNames] = useState<Array<any>>([]);
    const [wallet,
        setWallet] = useState<Array<any>>([]);
    const [resultCorr, setResultCorr] = useState<Array<any>>([]);
    const [resultVisible, setResultVisible] = useState(false);
    const [lastUpdated, setLastUpdated] = useState("");
    const [searchText, setSearchText] = useState("");
    const allCryptoNames = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd';
    const cryptoErrorMessage = "Cant read currency names";

    const isSmallQuery = "(max-width: 500px)";
    const isSmallScreen = useMediaQuery({query: isSmallQuery});

    const posts = useMemo(() => [
        btcVsEth,
        cryptoTaxes,
        decentralizedFin,
        riseOfNft,
        stakingCrypto,
        top5Altcoins
    ], []);

    const addCrypto = (value: string, image: string, id: string) => {
        if (wallet.length >= 5) {
            return null;
        }
        setWallet([...wallet, {value, image, id}]);
        setCryptoNames(cryptoNames.filter((item: any) => item.id !== id));
    }

    const removeCrypto = (value: string, image: string, id: string) => {
        setCryptoNames([{value, image, id}, ...cryptoNames]);
        setWallet(wallet.filter((item: any) => item.id !== id));
    }

    useEffect( () => {
        fetch(allCryptoNames)
        .then(res => res.json())
        .then(data => {
            setCryptoNames(data.map((item : any) => ({ value: item.name, image: item.image, id: item.id})));
            setLastUpdated(data[0].last_updated);
        })
        .catch(err => console.log(err + cryptoErrorMessage))
    }, []);

    useEffect(() => {
        document.documentElement.scrollTo({top: 0});
    }, []);

    const resultsRef = useRef(null);

    const submit = async () => {
        await setResultVisible(true);
        await setResultCorr([]);

        const createUrl = (id: string) => `https://api.coingecko.com/api/v3/coins/${id}/market_chart?days=91&vs_currency=usd`;
        const urls = wallet.map(item => createUrl(item.id));

        const arrayOfFetch = () => {
            return urls.map(url => fetch(url));
        }

        await Promise.all(arrayOfFetch()).then(allResponses => {
            Promise.all(allResponses.map(response => response.json())).then(allData => {
                setWallet(wallet.map((item, index) => ({...item, data: allData[index].prices.map((price: any) => price[1])})))
            });
          });
         
        const element = resultsRef.current;
        if (element) {
          (element as HTMLDivElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        if (wallet.length && !wallet.filter(item => !item.data).length && !resultCorr.length) {
            setResultCorr(calculateAll(wallet));
        }
    }, [wallet, resultCorr, setResultCorr]);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrollPosition(document.documentElement.scrollTop);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },[]);

    const [isPostsVisible,
        setIsPostsVisible] = useState(false);
    
    const postsRef = useRef(null);

    useEffect(() => {
        if (postsRef.current) {
            const formPositionFromTop = (postsRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isPostsVisible && isInScrollContext)) {
                setIsPostsVisible(true);
            }
        }
    }, [isPostsVisible, postsRef, scrollPosition]);

    const [isToolsVisible,
        setIsToolsVisible] = useState(false);
    
    const toolsRef = useRef(null);

    useEffect(() => {
        if (toolsRef.current) {
            const formPositionFromTop = (toolsRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isToolsVisible && isInScrollContext)) {
                setIsToolsVisible(true);
            }
        }
    }, [isToolsVisible, toolsRef, scrollPosition]);

    const [isWalletVisible,
        setIsWalletVisible] = useState(false);
    
    const walletRef = useRef(null);

    useEffect(() => {
        if (walletRef.current) {
            const formPositionFromTop = (walletRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isWalletVisible && isInScrollContext)) {
                setIsWalletVisible(true);
            }
        }
    }, [isWalletVisible, walletRef, scrollPosition]);

    const [isInterpretationVisible,
        setIsInterpretationVisible] = useState(false);
    
    const interpretationRef = useRef(null);

    useEffect(() => {
        if (interpretationRef.current) {
            const formPositionFromTop = (interpretationRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isInterpretationVisible && isInScrollContext)) {
                setIsInterpretationVisible(true);
            }
        }
    }, [isInterpretationVisible, interpretationRef, scrollPosition]);

    const [isTechnicalitiesVisible,
        setIsTechnicalitiesVisible] = useState(false);
    
    const technicalitiesRef = useRef(null);

    useEffect(() => {
        if (technicalitiesRef.current) {
            const formPositionFromTop = (technicalitiesRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isTechnicalitiesVisible && isInScrollContext)) {
                setIsTechnicalitiesVisible(true);
            }
        }
    }, [isTechnicalitiesVisible, technicalitiesRef, scrollPosition]);

    const [isResultsVisible,
        setIsResultsVisible] = useState(false);
    

    useEffect(() => {
        if (resultsRef.current && resultVisible) {
            const formPositionFromTop = (resultsRef.current as HTMLDivElement)
                ?.offsetTop - 550;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isResultsVisible && isInScrollContext)) {
                setIsResultsVisible(true);
            }
        }
    }, [isResultsVisible, resultsRef, scrollPosition, resultVisible]);

    const [sliderIndex,
        setSliderIndex] = useState(0);

    useEffect(() => {
        if (isNaN(sliderIndex) || sliderIndex < 0){
            setSliderIndex(0);
        }
    },[sliderIndex]);

    return <>
      <Helmet>
                <title>Cryptocurrency Wallet Correlation Tool - Analyze Crypto Transactions</title>
                <link rel="canonical" href="https://coindatashake.com/crypto-wallet-correlation/" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Use our free cryptocurrency wallet correlation tool to identify connections in your crypto transactions. Find out which assets are linked and discover new insights about your crypto portfolio. Analyze your transactions and make smarter investment decisions with our intuitive tool. Try it now!" />
                <meta property="og:title" content="Cryptocurrency Wallet Correlation Tool - Analyze Crypto Transactions" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://coindatashake.com/crypto-wallet-correlation/" />
                <meta property="og:image" content="https://coindatashake.com/images/wallet-correlation/crypto-wallet-correlation.webp" />
                <meta property="og:description" content="Use our free cryptocurrency wallet correlation tool to identify connections in your crypto transactions. Find out which assets are linked and discover new insights about your crypto portfolio. Analyze your transactions and make smarter investment decisions with our intuitive tool. Try it now!" />
            </Helmet>
        <div className='h-card' itemScope itemType="http://schema.org/Article">
    <HeaderContainer style={{justifyContent: "space-between", flexWrap: 'wrap'}}>
    <HeaderTitle className='p-name' itemProp="headline"><CompanyName />Cryptocurrency Wallet Correlation</HeaderTitle>
    <Motto><strong>Unlock the power of cryptocurrency with our Wallet Correlation</strong></Motto>
    </HeaderContainer>
    <div itemProp="articleBody" className="e-content">
    <SectionContainer id="description"  ref={postsRef} isVisible={isPostsVisible}>
        <SectionTitle>Tool Description</SectionTitle>
        <Description>
            <FormattedMessage id="toolDescription" />
        </Description>
        <Description>
            <FormattedMessage id="toolDescription2" />
        </Description>
    </SectionContainer>
    <SectionContainer id="createWallet" ref={walletRef} isVisible={isWalletVisible}>
        <SectionTitle>Create your wallet</SectionTitle> 
        <SelectorContainer>
            <SelectorBox>
                <SelectorSubTitle>Available Coins {cryptoNames.length}</SelectorSubTitle>
                {cryptoNames &&  <SelectorList>
                    {cryptoNames.filter((item) => !searchText || item.value.toLowerCase().includes(searchText.toLowerCase())).map((item: any) => {
                        return  <SelectorListItem key={item.id} onClick={() => addCrypto(item.value, item.image, item.id)}><SelectorIcon><SelectorImg itemProp="image" className='u-featured' alt={item.value}  src={item.image} loading='lazy'/></SelectorIcon><Text>{ isSmallScreen && item.value.length > 10 ? item.value.slice(0, 10) + "..." : item.value}</Text></SelectorListItem>
                    })}
                </SelectorList>}
            </SelectorBox>
            <SelectorArrows>
                <FontAwesomeIcon icon={faArrowRightLong}/>
                <FontAwesomeIcon icon={faArrowLeftLong}/>
            </SelectorArrows>
            <SelectorBox>
            <SelectorSubTitle>Wallet {wallet.length}/5</SelectorSubTitle>
            {wallet &&  <SelectorList>
                    {wallet.map((item: any) => {
                        return  <SelectorListItem key={item.id} onClick={() => removeCrypto(item.value, item.image, item.id)} ><SelectorIcon><SelectorImg itemProp="image" className='u-featured' alt="" src={item.image} loading='lazy' /></SelectorIcon><Text>{ isSmallScreen && item.value.length > 10 ? item.value.slice(0, 10) + "..." : item.value}</Text></SelectorListItem>
                    })}
                </SelectorList>}
            </SelectorBox>
            </SelectorContainer>
            <SearchBoxContainer>
                <FontAwesomeIcon icon={faSearch} />
                <input value={searchText} onChange={(e) => setSearchText(e.target.value)} type="text" placeholder='Search cryptocurrency' aria-label='Enter cryptocurrency name to filter all crypto box' maxLength={20} />
            </SearchBoxContainer>
            <DownloadButton color={colors.textPrimary} style={{
                    cursor: wallet.length > 1
                        ? 'pointer'
                        : 'not-allowed',
                    fontSize: "1.6rem"
                }} onClick={ wallet.length > 1 ? submit : ()=>{}}>
                    <span>Analyse</span>
                </DownloadButton>
        </SectionContainer>
        {resultVisible && <SectionContainer ref={resultsRef} isVisible={isResultsVisible}>
            <SectionTitle>Results of analysis</SectionTitle>
            {!!resultCorr.length && <CorrList corrData={resultCorr} />}
        </SectionContainer>}
        <SectionContainer ref={interpretationRef} isVisible={isInterpretationVisible}>
        <SectionTitle id="interpretation">Interpretation</SectionTitle>
            <Description>Correlation is a statistical measure that describes the relationship between two or more variables. In the context of a crypto wallet, correlation can be used to understand how different cryptocurrencies or assets in the wallet are related to each other. The correlation indicator in a crypto wallet can provide valuable insights into the behavior of the assets in the wallet. By analyzing the correlation between different assets, investors can make informed decisions about how to allocate their investments to maximize returns and minimize risks.
            </Description>
            <Description>In general, a correlation coefficient can range from -1 to +1. A correlation coefficient of -1 indicates a perfect negative correlation, which means that two assets move in opposite directions. A correlation coefficient of +1 indicates a perfect positive correlation, which means that two assets move in the same direction. A correlation coefficient of 0 indicates no correlation, which means that there is no relationship between the two assets. If the correlation between two cryptocurrencies is positive, it means that they tend to move in the same direction. For example, if the correlation between Bitcoin and Ethereum is positive, it means that when the price of Bitcoin goes up, the price of Ethereum also tends to go up. On the other hand, if the correlation is negative, it means that they tend to move in opposite directions. For example, if the correlation between Bitcoin and Ripple is negative, it means that when the price of Bitcoin goes up, the price of Ripple tends to go down.
            </Description>
            <Description>Investors can use the correlation indicator in their crypto wallet to identify diversification opportunities. Diversification is the practice of investing in multiple assets to spread out risk and reduce the impact of market volatility. If two cryptocurrencies have a positive correlation, investing in both of them may not provide much diversification benefit. On the other hand, if two cryptocurrencies have a negative correlation, investing in both of them may provide significant diversification benefits. Additionally, investors can use the correlation indicator to identify potential trading opportunities. If two cryptocurrencies have a high positive correlation, it may be possible to profit from trading one asset based on the movement of the other asset. For example, if the correlation between Bitcoin and Litecoin is high, it may be possible to profit from trading Litecoin based on the movement of Bitcoin.
            </Description>
            <Description>Overall, the interpretation of the correlation indicator in a crypto wallet can provide valuable insights into the behavior of different cryptocurrencies and assets in the wallet. By understanding the correlation between assets, investors can make informed decisions about how to allocate their investments to maximize returns and minimize risks.
            </Description>
           
        </SectionContainer>
        <OtherPosts posts={posts}  setSliderIndex={setSliderIndex} sliderIndex={sliderIndex}/>
        <SectionContainer ref={technicalitiesRef} isVisible={isTechnicalitiesVisible}>
        <SectionTitle id="technicalities">How We Created Our Cryptocurrency Wallet Correlation Tool</SectionTitle>
        <Description>
        Our cryptocurrency wallet correlation tool was created using advanced statistical techniques to help you identify patterns and connections in your cryptocurrency transactions. Our team of data scientists and developers worked tirelessly to create a tool that is accurate, reliable, and easy to use.To develop the tool, we used a statistical method called Pearson correlation. This method helps us to measure the strength and direction of a linear relationship between two variables. In the case of our cryptocurrency wallet correlation tool, these variables are the different wallets that you use to store your cryptocurrencies. We use daily data based on last 90 days, so our analysis are always reliable.
        </Description>
        <Description>
        Pearson correlation is a widely used statistical method for measuring the relationship between two variables. In our case, the two variables are the different cryptocurrency wallets that you use. Pearson correlation measures the strength of the relationship between these wallets, ranging from -1 to 1. A correlation of -1 means that the wallets are perfectly negatively correlated, meaning that when one wallet goes up in value, the other goes down. A correlation of 1 means that the wallets are perfectly positively correlated, meaning that when one wallet goes up in value, the other also goes up. A correlation of 0 means that there is no relationship between the two wallets.
        </Description> 
        <Description>
        The use of Pearson correlation in our cryptocurrency wallet correlation tool enables you to identify relationships between different wallets that you may not have otherwise noticed. This can help you to make better investment decisions and to manage your cryptocurrency portfolio more effectively. By understanding the relationship between different wallets, you can diversify your portfolio and minimize risk.
        </Description> 
        <Description>
        Using the tool is simple. All you need to do is select your cryptocurrency in your portfolio, and our tool will do the rest. Our algorithm will analyze the data and create interpretation for all cryptocurrency pairs. This will help you to identify patterns in your transactions that you may not have been aware of before. Our cryptocurrency wallet correlation tool is constantly being updated and improved based on user feedback and the latest trends in the cryptocurrency industry. We are committed to providing you with the best possible tools and resources to help you manage your cryptocurrency portfolio. If you have any questions or feedback about our cryptocurrency wallet correlation tool, please don't hesitate to get in touch with us. We are always happy to hear from our users and to help in any way that we can.
        </Description> 
        <Resources>Resources</Resources>
        <LinksExternal>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://www.gemini.com/cryptopedia/asset-correlation-between-cryptocurrencies'} target="_blank" rel="noreferrer">https://www.gemini.com/cryptopedia/asset-correlation-between-cryptocurrencies</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://cointelegraph.com/news/truth-about-crypto-price-correlation-how-closely-does-eth-follow-btc'} target="_blank" rel="noreferrer">https://cointelegraph.com/news/truth-about-crypto-price-correlation-how-closely-does-eth-follow-btc</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://www.investopedia.com/news/are-bitcoin-price-and-equity-markets-returns-correlated/'} target="_blank" rel="noreferrer">https://www.investopedia.com/news/are-bitcoin-price-and-equity-markets-returns-correlated/</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://caia.org/blog/2022/10/08/crypto-logs-two-questions-correlation'} target="_blank" rel="noreferrer">https://caia.org/blog/2022/10/08/crypto-logs-two-questions-correlation</WhiteLink></li>
            <li><WhiteLink className="u-url" itemProp="mainEntityOfPage" href={'https://www.bloomberg.com/news/articles/2022-10-22/bitcoin-s-btc-changing-correlations-may-mean-it-ll-be-a-haven-again-bofa-says?leadSource=uverify%20wall'} target="_blank" rel="noreferrer">https://www.bloomberg.com/news/articles/2022-10-22/bitcoin-s-btc-changing-correlations-may-mean-it-ll-be-a-haven-again-bofa-says?leadSource=uverify%20wall</WhiteLink></li>
        </LinksExternal>
        <AuthorHeader>Author</AuthorHeader>
    <Author><span className="p-author h-card" itemProp="author" itemScope itemType="http://schema.org/Organization"><Link to={Links.ABOUT_US} itemProp="url" className="u-url p-name">Coin Data Shake</Link></span> - click on the author to see more information.</Author>
        </SectionContainer>
        </div>
        <Footer lastUpdated={lastUpdated}/>
        </div>
    </>;
}

export const LinksExternal = styled.ul`
    margin-top: 1rem;
    &>li {
        line-height: 150%;
        word-break: break-all;
        overflow-wrap: break-word;
    }
    @media (max-width: 960px) {
        margin-left: 2rem!important;
            & li {
                margin-bottom: 1rem;
                line-height: 130%!important;
            }
    } 
`;

export const AuthorHeader = styled.h3`
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const SearchBoxContainer = styled.div`
    width: 40%;
    position: relative;
    @media (max-width: 980px) {
        width: 100%;
    }
    & svg {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        color: black;
        font-size: 1.2rem;
    }

    & input {
        display: block;
        width: 100%;
        margin-top: 0.5rem;
        border: 2px solid black;
        border-radius: 8px;
        background-color: ${colors.white};
        color: ${colors.black};
        outline: none!important;
        line-height: 300%;
        padding-left: 3rem;
        font-size: 1.2rem;
        font-family: 'Ubuntu', sans-serif;
    }
`;

const SelectorSubTitle = styled.h3`
    text-align: center;
    line-height: 200%;
    border-bottom: 2px solid black;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    background-color: ${colors.white};
    z-index: 3;
`;

const Text = styled.span`
    vertical-align: 3px;
`;

export const SelectorIcon = styled.span`
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    transform: translateY(0.2rem);
`;

export const SelectorImg = styled.img`
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
`;

const SelectorContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SelectorArrows = styled.div`
    flex-basis: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3rem;
    @media (max-width: 980px) {
        flex-basis: 5%;
        font-size: 2rem;
    }
`;

const SelectorList = styled.ul`
    list-style: none;
`;

const SelectorListItem = styled.li`
    padding: 0.5rem 1rem;
    &:hover {
        cursor: pointer;
        background-color: ${colors.textPrimary}
    }
`;

const SelectorBox = styled.div`
    background-color: ${colors.white};
    position: relative;
    color: ${colors.black};
    flex-basis: 40%;
    @media (max-width: 980px) {
        flex-basis: 43%;
    }
    border: 2px solid black;
    border-radius: 10px;
    min-height: 300px;
    max-height: 300px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
        
    }
    scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
`;

export const Description = styled.p`
    font-size: 1.3rem;
    line-height: 130%;
    margin-bottom: 2rem;
`;

export const SectionContainer = styled.section< {
    isVisible?: boolean
} >`
    width: 60vw;
    margin: 4rem auto;
    @media (max-width: 980px) {
        width: 90vw;
    }
    opacity: ${props => props.isVisible
        ? '1'
        : '0'};
        transition: 0.5s;

`;

export const SectionTitle = styled.h2`
    margin-bottom: 2rem;
`;

export const Motto = styled.p`
    font-size: 1.2rem;
    @media (max-width: 800px) {
        font-size: 1.4rem;
    }

    color: ${colors.textPrimary};
    @media (max-width: 1300px) {
        flex-basis: 100%;
        margin-top: 1rem;
    }
`;

export default CryptoWalletCorr;