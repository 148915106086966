import {observer} from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import {bitcoinData} from "../../provider/bitcoinData";
import parse from 'html-react-parser';
import {makeParagraphs} from "../textFormatters/paragraphs";
import {useIntl} from "react-intl";

const DescriptionTab = observer(() => {
    const text = bitcoinData.description;
    let paragraphs = makeParagraphs(text);
    const {formatMessage} = useIntl();
    const emptyParagraph = "<p></p>";

    paragraphs = paragraphs === emptyParagraph
        ? formatMessage({id: 'descriptionTab.error'})
        : paragraphs;

    return <Description>{parse(paragraphs)}</Description>
});

const Description = styled.article `
    font-size: 1.4rem;
    padding-top: 1rem;
    line-height: 130%;

    & p {
        margin-bottom: 1rem;
        line-height: 150%;
    }
    & a {
        font-weight: bold;
    }
`;

export default DescriptionTab;