import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router';
import { Links } from '../../assets/links';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';

const handleKeyDown = (id: string, event: any) => {
    if (event.key === 'Enter') {
      event.target.click();
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
    }
    }
  };



const InnerNavigation = () => {
    const location = useLocation();
    const currentPath = location.pathname.includes(Links.COIN_INFO) ? Links.COIN_INFO : location.pathname;
    const isBlogPost = location.pathname.includes('blog/');
    const HeaderRef = useRef(null);
    const List = useRef(null);


    const Header = () => <LinksHeader>{{
        [Links.HOME]: "Home",
        [Links.CRYPTO_WALLET]: "Wallet correlation",
        [Links.COIN_INFO]: "Coin info",
        [Links.MOVING_AVERAGE]: "Moving average",
        [Links.BLOG]: "Blog",
        [Links.ABOUT_US]: "About us"
    }[currentPath]}</LinksHeader>;


    const createLinks = (ids: Array<string>, link?: string) => ids.map(id => <MenuListItem key={id}><Link href={`${link ?? ''}/#id`}className='button-link' to={id} spy={true} smooth={true} duration={500} tabIndex={0}  onKeyDown={(e) => handleKeyDown(id, e)}><FormattedMessage id={id}/></Link></MenuListItem>);

    const LinksList = () => ({
       [Links.COIN_INFO]: <MenuList>{createLinks(['coinDetails', 'marketData', 'forecast', 'resources'], Links.COIN_INFO)}</MenuList>,
       [Links.CRYPTO_WALLET]:  <MenuList>{createLinks(['description', 'createWallet', 'interpretation', 'technicalities'], Links.CRYPTO_WALLET)}</MenuList>,
       [Links.HOME]:  <MenuList>{createLinks(['homeForm', 'priceChange', 'priceChangeLowest','undervaluedDay', 'marketCap', 'toMax', 'homeBlog','homeTools'])}</MenuList>,
       [Links.MOVING_AVERAGE]: <MenuList>{createLinks(['maForm', 'description', 'howTo', 'interpretation', 'technicalities'],Links.MOVING_AVERAGE)}</MenuList>,
       [Links.BLOG]: <MenuList>{createLinks(['posts', 'toolsLinks', 'blogDescription'], Links.BLOG)}</MenuList>,
       [Links.ABOUT_US]: <MenuList>{createLinks(['company', 'goals', 'contentCreation', 'contact'], Links.ABOUT_US)}</MenuList>
    }[currentPath]);

    if (isBlogPost || ((HeaderRef.current as any)?.firstChild && !(HeaderRef.current as any)?.firstChild?.firstChild?.textContent)) {
        return null;
    }

    return <Details>
        <summary ref={HeaderRef}><Header /></summary>
        <div  ref={List}>
            <LinksList />

        </div>
    </Details>
};

export const Details = styled.details`
    &:hover+div, &[open]+div {
        width: 100%;
    }

    & summary {
        cursor: pointer;
        padding-bottom: 2rem;
    }

    & summary>* {
        display: inline-block;
        transform: translateY(4px);
        margin-left: 0.5rem;
    }

    @media (min-width: 960px) {
        &[open] summary ~ * {
            animation: sweep .5s ease-in-out;
          }
          
          @keyframes sweep {
            0%    {opacity: 0; transform: translateX(-10px)}
            100%  {opacity: 1; transform: translateX(0)}
          }
    }
  
`;

export const LinksHeader = styled.h2`
    font-size: 1.6rem;
    text-transform: uppercase;
    @media (orientation: portrait) {
        font-size: 1.3rem;
    }
`;


export const MenuList = styled.ul`
    list-style: none;
    padding: 0 3rem 2rem 3rem;
`;

export const MenuListItem = styled.li`
    margin-bottom: 1rem;
    font-size: 1.5rem;
    @media (max-width: 980px) {
        font-size: 1.8rem;
    }
    @media (orientation: portrait) {
        font-size: 1.2rem;
        & a {
            color: ${colors.black}!important;
        }
    }

    transition: .2s;
    & a {
        cursor: pointer;
        display: block;
    }

    & a:focus {
            outline: 5px solid ${colors.textPrimary};
            border-radius: 1px;
    }

    @media (min-width: 1000px) {
        &:hover {
            transform: translateX(1rem);
        }
    }
   
`;

export default InnerNavigation;