export const externalLinks = {
    "announcement_url": 'Announcement url: ',
    "bitcointalk_thread_identifier": "Bitcointalk thread identifier: ",
    "blockchain_site": "Blockchain site: ",
    "chat_url": "Chat url: ",
    "facebook_username": "Facebook username: ",
    "homepage": 'Homepage: ',
    "official_forum_url": "Official forum url: ",
    "github": "Github: ",
    "bitbucket": "Bitbucket: ",
    "subreddit_url": "Subreddit url: ",
    "telegram_channel_identifier": 'Telegram channel identifier: ',
    "twitter_screen_name": "Twitter screen name: "
}