import React, {useState, useEffect, useRef} from "react";
import DatalistInput from "react-datalist-input";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {DownloadButton} from "../HistoricalData";
import 'react-tooltip/dist/react-tooltip.css'
import {Tooltip} from 'react-tooltip'
import {useMediaQuery} from 'react-responsive';
import {colors} from "../../assets/colors";
import {FormattedMessage, useIntl} from "react-intl";
import { Links } from "../../assets/links";
import { ApiLink } from "../Footer";

export interface customItemCurrencyParams {
    id : string;
    value : string;
    description : string;
};

export interface customItemParams {
    id : string;
    value : string;
    image : string;
};

export const HomeForm = ({
    crypto,
    currency,
    cryptoError,
    currencyError,
    cryptoNames,
    currencyList,
    setCrypto,
    setCryptoError,
    setCurrency,
    setCurrencyError,
    scrollPosition,
    isCoinInfo
} : {
    crypto: string;
    currency: string;
    cryptoError: boolean;
    currencyError: boolean;
    cryptoNames: Array < any >;
    currencyList: Array < any >;
    setCrypto: (id : string) => void;
    setCryptoError: (err : boolean) => void;
    setCurrency: (id : string) => void;
    setCurrencyError: (err : boolean) => void;
    scrollPosition: number;
    isCoinInfo?: boolean;
}) => {

    const link = Links.COIN_INFO;
    const isFormCorrect = !cryptoError && crypto && !currencyError && currency;
    const sectionRef = useRef(null);
    const {formatMessage} = useIntl();

    const [isVisible,
        setIsVisible] = useState(false);
    const portraitQuery = "(orientation: portrait)";
    const isPortrait = useMediaQuery({query: portraitQuery});

    useEffect(() => {
        if (sectionRef.current) {
            const formPositionFromTop = (sectionRef.current as HTMLDivElement)?.offsetTop - 200;
            const isInScrollContext = scrollPosition >= formPositionFromTop
            if ((!isVisible && isInScrollContext)) {
                setIsVisible(true);
            }

        }

    }, [isVisible, sectionRef, scrollPosition]);

    const customItems = cryptoNames.map((item : customItemParams) => ({
        ...item,
        node: <CustomItem item={item}/>
    }));

    const customItemsCurrency = currencyList.map((item : customItemCurrencyParams) => ({
        ...item,
        node: <CustomItemsCurrency item={item}/>
    }));

    return <FormBox isVisible={isCoinInfo || isVisible} ref={sectionRef} id="homeForm" style={isCoinInfo ? {margin: '1rem auto', width: '70vw'} : {}}>
        {!isCoinInfo && <>
            <FormHeader>
            <FormattedMessage id="form.title"/>
        </FormHeader>
        <Description>
            <FormattedMessage id="form.firstParagraph"/>
            <br/>
            <FormattedMessage id="form.secondParagraph"/>
            
        </Description>
        <ListContainer>
            <Features>
                <li>Coin description and useful information</li>
                <li>Cryptocurrency daily data</li>
                <li>Week forecast based on statistical models</li>
                <li>Data download option in csv format (Excel)</li>
            </Features>
        </ListContainer>
        </>}
        {isCoinInfo ?  <Details open>
        <summary>Change crypto</summary>
        <Form>
            <SearchField
                placeholder={formatMessage({id: isPortrait ? 'cryptocurrency.placeholder.small': 'form.cryptoLabel'})}
                showLabel={false}
                label={formatMessage({id: 'form.cryptoLabel'})}
                onSelect={(item : {
                id: string
            }) => {
                setCrypto(item.id);
                setCryptoError(false)
            }}
                onChange={() => setCryptoError(true)}
                items={customItems}/>
            <SearchField
                placeholder={formatMessage({id: isPortrait ? 'currency.placeholder.small':  'form.currencyLabel'})}
                showLabel={false}
                label={formatMessage({id: 'form.currencyLabel'})}
                onSelect={(item : {
                id: string
            }) => {
                setCurrency(item.id);
                setCurrencyError(false)
            }}
                onChange={() => setCurrencyError(true)}
                items={customItemsCurrency}/>
            {!isCoinInfo && <DownloadButton color={colors.textPrimary}>
                <Link
                    to={link}
                    state={{ currency: currency, coin: crypto.replaceAll(' ', '-')}}
                    {...(isFormCorrect ? {} : { onClick: (e) => { e.preventDefault() } })}
                    id="pageLink"
                    data-tooltip-content={formatMessage({
                    id: isFormCorrect
                        ? 'form.correct'
                        : 'form.incorrect'
                })}
                    style={{
                    cursor: isFormCorrect
                        ? 'pointer'
                        : 'not-allowed',
                    fontSize: "1.7rem"
                }}>
                    <FormattedMessage id="form.buttonMessage"/>
                </Link>
                <Tooltip
                    anchorId="pageLink"
                    style={{
                    maxWidth: '60vw',
                    textAlign: 'center'
                }}/>
            </DownloadButton>}
            
        </Form>
</Details>   : <Form>
            <SearchField
                placeholder={formatMessage({id: isPortrait ? 'cryptocurrency.placeholder.small': 'form.cryptoLabel'})}
                showLabel={false}
                label={formatMessage({id: 'form.cryptoLabel'})}
                onSelect={(item : {
                id: string
            }) => {
                setCrypto(item.id);
                setCryptoError(false)
            }}
                onChange={() => setCryptoError(true)}
                items={customItems}/>
            <SearchField
                placeholder={formatMessage({id: isPortrait ? 'currency.placeholder.small':  'form.currencyLabel'})}
                showLabel={false}
                label={formatMessage({id: 'form.currencyLabel'})}
                onSelect={(item : {
                id: string
            }) => {
                setCurrency(item.id);
                setCurrencyError(false)
            }}
                onChange={() => setCurrencyError(true)}
                items={customItemsCurrency}/>
            <DownloadButton color={colors.textPrimary}>
                <Link
                    to={link}
                    state={{ currency: currency, coin: crypto.replaceAll(' ', '-')}}
                    {...(isFormCorrect ? {} : { onClick: (e) => { e.preventDefault() } })}
                    id="pageLink"
                    data-tooltip-content={formatMessage({
                    id: isFormCorrect
                        ? 'form.correct'
                        : 'form.incorrect'
                })}
                    style={{
                    cursor: isFormCorrect
                        ? 'pointer'
                        : 'not-allowed',
                    fontSize: "1.6rem"
                }}>
                    <FormattedMessage id="form.buttonMessage"/>
                </Link>
                <Tooltip
                    anchorId="pageLink"
                    style={{
                    maxWidth: '60vw',
                    textAlign: 'center'
                }}/>
            </DownloadButton>
        </Form>}
        
        {!isCoinInfo && <Description>
        Forecasts and daily market analysis are essential tools for any trader looking to make informed decisions in the financial markets. One popular method of generating market forecasts is through the use of ARIMA models. ARIMA stands for Autoregressive Integrated Moving Average, and it is a statistical method that uses time-series data to forecast future values. ARIMA models are commonly used in the financial industry because they can capture complex patterns in market data and provide reliable forecasts. When using ARIMA models to generate market forecasts, it is important to remember that these models are not infallible. While they are highly accurate in many cases, they can also be affected by unexpected events, such as sudden changes in market conditions or unexpected geopolitical events. As such, it is important to use ARIMA forecasts as just one tool in a trader's arsenal, and to always be prepared for the unexpected. By using ARIMA models in conjunction with other market analysis tools, traders can get a more complete picture of the market and make more informed trading decisions. Data on this website are provided by  <ApiLink href={Links.COINGECKO} target="_blank" rel="noreferrer">CoinGecko</ApiLink>.</Description>}
    </FormBox>
};

const Details = styled.details`
    & summary {
        cursor: pointer;
        font-size: 2rem;
        @media (min-width: 960px) {
            transform: translateX(1rem);
        }
    }

    @media (min-width: 960px) {
        &[open] summary ~ * {
            animation: sweep .5s ease-in-out;
          }
          
          @keyframes sweep {
            0%    {opacity: 0; transform: translateX(-10px)}
            100%  {opacity: 1; transform: translateX(0)}
          }
    }

`;

const Features = styled.ul`
    margin-left: 4rem;
    margin-top: 0;
`;

export const CustomItem = ({item} : {
    item: customItemParams
}) => (
    <ListRow>
        <span>{item.value}</span>
        <ListIcon src={item.image} alt={item.value} loading="lazy"/>
    </ListRow>
);

export const CustomItemsCurrency = ({item} : {
    item: customItemCurrencyParams
}) => (
    <ListRow>
        <span>{item.description}</span>
        <ListSign>{item.value}</ListSign>
    </ListRow>
);

const ListRow = styled.p `
    display: flex;
    justify-content: space-between;
`;

const iconSize = "1.6rem";
const rightGap = " margin-right: 1rem;";

const ListIcon = styled.img `
    width: ${iconSize};
    height: ${iconSize};
   ${rightGap}
`;

const ListSign = styled.span `
    ${rightGap}
`;

const Form = styled.form `
    margin: 2rem 0;
`;

export const SearchField = styled(DatalistInput)`
    margin-top: 1rem;

    & .react-datalist-input__textbox {
        width: 90%;
        
        @media (max-width: 1000px) {
            & {
                width: 100%;
            }
        }
        max-width: 1200px;
        line-height: 300%;
        padding-left: 1rem;
        font-size: 1.5rem;
        color: black;
        &::placeholder {
            color: #000!important;
            opacity: 1!important;
        }
    }

    & .react-datalist-input__textbox:focus {
        outline: ${colors.textPrimary} solid 3px;
    }


    & .react-datalist-input__listbox {
        width: 90%;
        @media (max-width: 1000px) {
            & {
                width: 100%;
            }
        }
        max-width: 1200px;
        background-color: ${colors.white};
        color: black;
        list-style: none;
        padding: 1rem;
        outline: ${colors.textPrimary} solid 3px;
        overflow-y: scroll;
        max-height: 15rem;
        &::-webkit-scrollbar {
            background: ${colors.transparent}; /* Chrome/Safari/Webkit */
            width: 0px;
        }
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
    }

    & .react-datalist-input__listbox-option {
        margin: 1rem 0;
        cursor: pointer;
        font-size: 1.5rem;
        
        & mark {
            background-color: ${colors.transparent};
        }
    }

    & .react-datalist-input__listbox-option:hover {
        margin: 1rem 0;
        cursor: pointer;
        color: ${colors.textPrimary}
    }
   
`;

const Description = styled.p `
    font-size: 1.4rem;
    padding: 1rem 0;
    line-height: 130%;
`;

const ListContainer = styled.div`
    font-size: 1.4rem;
    line-height: 130%;
`;

export const FormBox = styled.section < {
    isVisible?: boolean
} > `
    opacity: ${props => props.isVisible
    ? '1'
    : '0'};
    margin: 10rem auto;
    
    @media(max-width: 980px) {
        margin: 2rem auto;
    }

    max-width: 1200px;
    font-size: 1.2rem;
    padding: 1rem 5rem;
    @media (orientation: portrait) and (max-width: 900px) {
        padding: 1rem;
    }
    transition: 0.5s;
`;

const FormHeader = styled.h2 `
    margin-bottom: 1rem;
    letter-spacing: 0.5px;
    font-size: 2rem;
    @media(orientation: portrait) {
        text-align: center;
    }
`;